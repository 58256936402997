import { RefObject } from 'react';

export const calcValue = (
  currentWidth: number,
  totalWidth: number,
  audioRef: RefObject<HTMLAudioElement>,
) => {
  if (!audioRef.current) return 0;
  return (currentWidth / totalWidth) * audioRef.current.duration;
};

export const calcWidth = (client: number, cursor: number, max: number) => {
  const width = client - cursor;
  if (width >= max) return max;
  if (width <= 0) return 0;
  return width;
};

const getMinutes = (h: number, m: number) => {
  if (m > 9) return m;
  if (h) return `0${m}`;
  return `0${m}` || '0';
};

const getSeconds = (s: number) => {
  if (s > 9) return s;
  return `0${s}`;
};

export const formatAudioTime = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const resultM = getMinutes(h, m);
  const resultS = getSeconds(s);

  return [h, resultM, resultS].filter((a) => a).join(':');
};

export const stopAllAudio = () => {
  const audios = document.getElementsByTagName('audio');

  Array.from(audios).forEach((audio) => {
    audio.pause();
  });
};

import { createDraftSafeSelector, EntityId } from '@reduxjs/toolkit';
import type { GridSchemaSecure } from 'a4bd-meta';
import { prop } from 'ramda';

import { gridAdapter } from '~adapters';

import { stateSelector } from './state';

export const gridEntitySelector = gridAdapter.getSelectors<RootState>(prop('grid'));

export const gridSelector = createDraftSafeSelector(
  stateSelector,
  prop<GridSchemaSecure[]>('grid'),
);

export const changesCurrentGridSchemasEntitySelector = createDraftSafeSelector(
  gridSelector,
  prop<EntityId[]>('changes'),
);

export const currentGridSchemaByIdSelector = (id: number) => (state: RootState) =>
  gridEntitySelector.selectById(state, id);

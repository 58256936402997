import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  CreateEmployeeBodySecure,
  EmployeeExtSecure,
  GetManyEmployeesQuerySecure,
  GetManyEmployeesResponseSecure,
  UpdateEmployeeBodySecure,
} from 'a4bd-meta';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export const employeesApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createEmployee: builder.mutation<EmployeeExtSecure, CreateEmployeeBodySecure>({
      invalidatesTags: [{ id: 'List', type: 'Employees' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Employees),
      }),
    }),
    deleteEmployee: builder.mutation<void, number>({
      invalidatesTags: [{ id: 'List', type: 'Employees' }],
      query: (id) => ({
        method: 'DELETE',
        url: createUrl(ApiPath.Employees, id),
      }),
    }),
    getEmployeeById: builder.query<EmployeeExtSecure, RelationsParams & { id: number }>({
      query: ({ id, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Employees, id),
      }),
    }),
    getEmployeesList: builder.query<
      GetManyEmployeesResponseSecure,
      Partial<GetManyEmployeesQuerySecure>
    >({
      providesTags: () => [{ id: 'List', type: 'Employees' }],
      query: (params = {}) => ({
        method: 'GET',
        params: {
          limit: 30,
          order: '-createdAt',
          ...params,
        },
        url: createUrl(ApiPath.Employees),
      }),
    }),
    updateEmployee: builder.mutation<
      EmployeeExtSecure,
      Partial<UpdateEmployeeBodySecure> & { id: number }
    >({
      invalidatesTags: [{ id: 'List', type: 'Employees' }],
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Employees, id),
      }),
    }),
  }),
  reducerPath: 'employeesApi',
  tagTypes: ['Employees'],
});

export const {
  useCreateEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetEmployeeByIdQuery,
  useGetEmployeesListQuery,
  useUpdateEmployeeMutation,
} = employeesApi;

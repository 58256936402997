import Icon from '@ant-design/icons';
import { PostStatus, PostType } from 'a4bd-meta';
import { Col, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React from 'react';

import { Settings, User } from '~assets';
import { Badge, Button } from '~components';
import { BadgeColor } from '~constants';
import { usePost } from '~hooks';
import { formatDate, getStatusIcon, getStatusTitle, isDefined } from '~utils';

import styles from './styles.module.scss';

interface Props {
  createdAt: string;
  edit?: boolean;
  isSettingsOpen: boolean;
  status: PostStatus;
  toggleSettings: () => void;
  type: PostType;
  updatedAt: string;
}

const getCreateTitle = cond<[PostType], string>([
  [equals(PostType.News), always('Новая новость')],
  [equals(PostType.Article), always('Новая статья')],
  [equals(PostType.Longread), always('Новый лонгрид')],
  [equals(PostType.Card), always('Новые карточки')],
  [equals(PostType.Test), always('Новый тест')],
  [equals(PostType.Spec), always('Новый спецпроект')],
  [equals(PostType.Story), always('Новая история')],
  [equals(PostType.Thing), always('Новая штука')],
  [equals(PostType.Podcast), always('Новый подкаст')],
]);

const getEditTitle = cond<[PostType], string>([
  [equals(PostType.News), always('Редактирование новости')],
  [equals(PostType.Article), always('Редактирование статьи')],
  [equals(PostType.Longread), always('Редактирование лонгрида')],
  [equals(PostType.Card), always('Редактирование карточек')],
  [equals(PostType.Test), always('Редактирование теста')],
  [equals(PostType.Spec), always('Редактирование спецпроекта')],
  [equals(PostType.Story), always('Редактирование истории')],
  [equals(PostType.Thing), always('Редактирование штуки')],
  [equals(PostType.Podcast), always('Редактирование подкаста')],
]);

const getStatusColor = cond<[PostStatus], BadgeColor>([
  [equals(PostStatus.Published), always(BadgeColor.BlueLight)],
  [T, always(BadgeColor.GreyLight)],
]);

export const PostInfo: React.FC<Props> = (props) => {
  const {
    createdAt,
    edit = false,
    isSettingsOpen,
    status,
    toggleSettings,
    type,
    updatedAt,
  } = props;

  const { authors } = usePost();
  const [author] = authors;
  const title = edit ? getEditTitle(type) : getCreateTitle(type);

  return (
    <Col>
      <Col className={styles.wrapper}>
        <Row gutter={[20, 10]}>
          <Col>
            <Typography.Text className={styles.title}>{title}</Typography.Text>
          </Col>
          <Col>
            <Badge color={getStatusColor(status)} className={styles.status}>
              {getStatusIcon(status)}
              {getStatusTitle(status)}
            </Badge>
          </Col>
        </Row>
      </Col>
      <Col className={styles.bottomBar}>
        <Row gutter={[5, 5]} align="middle" justify="start" className={styles.textsWrapper}>
          <Col>
            <Row justify={{ md: 'end', xs: 'start' }}>
              <Space size={['middle', 'small']} wrap>
                {isDefined(author) ? (
                  <Space size="small">
                    <Icon component={User} />
                    <Typography.Text className={styles.author}>{author.name}</Typography.Text>
                  </Space>
                ) : null}
                <Space size="small">
                  <Typography.Text className={styles.label}>Создано:</Typography.Text>
                  <Typography.Text className={styles.date}>
                    {formatDate(createdAt, 'dd MMMM yyyy, HH:mm')}
                  </Typography.Text>
                </Space>
                <Space size="small">
                  <Typography.Text className={styles.label}>Изменено:</Typography.Text>
                  <Typography.Text className={styles.date}>
                    {formatDate(updatedAt, 'dd MMMM yyyy, HH:mm')}
                  </Typography.Text>
                </Space>
              </Space>
            </Row>
          </Col>
        </Row>
        <Row className={styles.settingsWrapper}>
          <Button
            className={classNames(styles.settings, isSettingsOpen && styles.settingsOpen)}
            icon={<Settings />}
            onClick={toggleSettings}
          >
            Все настройки
          </Button>
        </Row>
      </Col>
    </Col>
  );
};

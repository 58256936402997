import { Col, Row } from 'antd';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { TitlePage, Visible } from '~components';
import { InfoPagesTypes, TYPE_EDIT_SETTINGS } from '~constants';
import { MobilePageHeader, SettingsTabs } from '~containers';
import { useIsMobile } from '~hooks';
import { SettingsProvider } from '~providers';

import { SettingsFilter } from '../../containers/SettingsFilter';

export const SettingsPage: React.FC = () => {
  const isMobile = useIsMobile();

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') || InfoPagesTypes.InfoPages;

  const isSettingsEditPage = Object.values(TYPE_EDIT_SETTINGS).includes(type as InfoPagesTypes);

  return (
    <SettingsProvider>
      <Row gutter={[35, 35]}>
        {!isSettingsEditPage && (
          <>
            <Visible isVisible={isMobile}>
              <Col span={24}>
                <MobilePageHeader title="Настройки" placeholderText="Поиск" withSearch={false} />
              </Col>
            </Visible>

            <Col span={24}>
              <Row justify="space-between">
                <TitlePage title="Настройки" />
              </Row>
            </Col>

            <Col span={24}>
              <SettingsFilter />
            </Col>
          </>
        )}
        <Col span={24}>
          <SettingsTabs />
        </Col>
      </Row>
    </SettingsProvider>
  );
};

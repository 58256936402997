import { Block } from '../types/db/block/Block';
import { BlockType } from '../types/db/block/BlockType';
import { FileVideoMeta } from '../types/db/file/FileVideoMeta';

export function getFileVideoPlayer<T extends BlockType, D = Date>(
  block: Block<T, D>,
  fileId: number,
): string {
  const file = block.files?.find((item) => item.id === fileId);

  if (!file) {
    return '';
  }

  return (file.meta as FileVideoMeta)?.player || '';
}

export enum FileImageVariantType {
  DesktopCard = 'desktopCard',
  DesktopHead = 'desktopHead',
  DesktopSlider = 'desktopSlider',
  DesktopWideCard = 'desktopWideCard',
  Mobile = 'mobile',
  TabletCard = 'tabletCard',
  TabletHead = 'tabletHead',
  TabletWideCard = 'tabletWideCard',
}

import { PostType, SectionPreviewSecure } from 'a4bd-meta';
import { Button, Col, Form, Input, Row, Select, Space, Switch, Typography } from 'antd';
import { always, ifElse, isNil } from 'ramda';
import React, { FC } from 'react';

import { Modal } from '~components';
import { useGetGridsListQuery } from '~services';
import { getTypeTitle, isActualPostType } from '~utils';

import styles from './styles.module.scss';

interface Props {
  isOpen?: boolean;
  onClose(section?: Partial<SectionPreviewSecure>): void;
  section?: Partial<SectionPreviewSecure>;
}

const getModalTitle = ifElse(isNil, always('Добавление раздела'), always('Редактирование раздела'));
const getButtonTitle = ifElse(isNil, always('Добавить'), always('Применить'));

const postTypeOptions = Object.values(PostType)
  .filter(isActualPostType)
  .map((postType) => ({
    label: getTypeTitle(postType),
    value: postType,
  }));

export const SectionModal: FC<Props> = ({ isOpen = false, onClose, section }) => {
  const handleCancel = () => {
    onClose();
  };

  const { data: gridsResponse, isFetching } = useGetGridsListQuery({});
  const grids = gridsResponse?.results ?? [];

  const handleFinish = (values: Partial<SectionPreviewSecure>) => {
    onClose({ ...section, ...values });
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const InputColor = (props: any) => {
    const { value } = props;
    return (
      <Input
        {...props}
        maxLength={7}
        size="large"
        style={{ background: `linear-gradient(to right, #fff 50%, ${value} 0%)` }}
      />
    );
  };

  console.log('renderSectionModal...', { section });

  return (
    <Modal
      open={isOpen}
      title={getModalTitle(section?.id)}
      destroyOnClose
      onCancel={handleCancel}
      width={470}
      footer={null}
    >
      <Form
        layout="vertical"
        name="section"
        validateTrigger="onSubmit"
        onFinish={handleFinish}
        initialValues={
          section ?? { isShownInFooter: false, isShownInMenu: false, postTypes: [], subtitle: '' }
        }
      >
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Row gutter={[0, 15]}>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label="Раздел"
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                  requiredMark
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="alias"
                  label="Алиас"
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                  requiredMark
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="subtitle" label="Описание">
                  <Input.TextArea size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="postTypes" label="Типы публикаций" labelCol={{ span: 24 }}>
                  <Select
                    showSearch
                    mode="multiple"
                    size="large"
                    options={postTypeOptions}
                    maxTagCount="responsive"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="gridId"
                  label="Сетка"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                  requiredMark
                >
                  <Select
                    showSearch
                    loading={isFetching}
                    size="large"
                    options={grids}
                    maxTagCount="responsive"
                    fieldNames={{ label: 'title', value: 'id' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Space size="small" align="center">
                  <Form.Item
                    name="darkColor"
                    label="Основной цвет"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        message: 'Введите цвет в формате HEX',
                        pattern: /^#([0-9A-F]{3}){1,2}$/i,
                      },
                    ]}
                  >
                    <InputColor />
                  </Form.Item>
                  ;
                  <Form.Item
                    name="lightColor"
                    label="Дополнительный цвет"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        message: 'Введите цвет в формате HEX',
                        pattern: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
                      },
                    ]}
                  >
                    <InputColor />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space size="middle" align="center">
                  <Typography.Text className={styles.title}>В главном меню</Typography.Text>
                  <Form.Item name="isShownInMenu" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space size="middle" align="center">
                  <Typography.Text className={styles.title}>В футере</Typography.Text>
                  <Form.Item name="isShownInFooter" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[15, 15]} justify="space-between">
              <Col span={12}>
                <Button type="text" size="large" onClick={handleCancel} block>
                  Отменить
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" htmlType="submit" size="large" block>
                  {getButtonTitle(section?.id)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

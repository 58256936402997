import { PostPreviewSecure, PostStatus, PostType } from 'a4bd-meta';
import { Col, Input, Row, Spin } from 'antd';
import classNames from 'classnames';
import { equals, includes, isNil, pipe, prop, reject } from 'ramda';
import React, { ChangeEventHandler, useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { useDebounce } from 'use-debounce';

import { Button, Modal, Sort, SortField, SortValue } from '~components';
import { SortDirections } from '~constants';
import { useGetListPostsQuery } from '~services';
import { sortToString } from '~utils';

import { PostCard } from '../PostCard';
import styles from './styles.module.scss';

type Props = InstanceProps<PostPreviewSecure<PostType>[], false> & {
  maxPostCount: number;
  modalTitle?: string;
  sections?: number[];
  types?: PostType[];
};

const sortFields: SortField[] = [{ label: 'По дате публикации', value: 'publishedAt' }];

type TypeButtonProps = {
  label: string;
  type: PostType | undefined;
};

const typeButtons: TypeButtonProps[] = [
  { label: 'Все', type: undefined },
  { label: 'Статьи', type: PostType.Article },
  { label: 'Истории', type: PostType.Story },
  { label: 'Подкасты', type: PostType.Podcast },
  { label: 'Штуки', type: PostType.Thing },
];

const { Search } = Input;
const Container: React.FC<Props> = (props) => {
  const {
    isOpen,
    maxPostCount,
    onReject,
    onResolve,
    sections = [],
    types = [],
    modalTitle = 'поиск публикаций',
  } = props;
  const [selected, setSelected] = useState<PostPreviewSecure<PostType>[]>([]);
  const [term, setTerm] = useState<string>();
  const [sort, setSort] = useState<SortValue>({
    publishedAt: SortDirections.Desc,
  });
  const [selectedTypes, setSelectedTypes] = useState(types);

  const filterBySectionId = reject(isNil, sections);

  const [debouncedTerm] = useDebounce(term, 1000);

  const { data, isLoading } = useGetListPostsQuery({
    order: sortToString(sort),
    sections: filterBySectionId.length !== 0 ? filterBySectionId : undefined,
    status: PostStatus.Published,
    term: debouncedTerm,
    type: selectedTypes,
  });

  const onCancel = () => {
    onReject(false);
  };

  const onTermChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event?.currentTarget.value);
  };

  const onPostClick = (post: PostPreviewSecure<PostType>) => {
    if (includes(post.id, selected.map(prop('id')))) {
      setSelected(selected.filter(pipe(prop('id'), equals(post.id))));
    } else if (selected.length < maxPostCount) {
      setSelected([...selected, post]);
    }
  };

  const onAdd = () => {
    onResolve(selected);
  };

  const handleClickType = (type?: PostType) => () => {
    if (type) {
      setSelectedTypes([type]);
    } else {
      setSelectedTypes(types);
    }
  };

  const isSelected = (post: PostPreviewSecure<PostType>) =>
    includes(post.id, selected.map(prop('id')));

  const canSelectMore = selected.length < maxPostCount;

  return (
    <Modal
      title={modalTitle}
      open={isOpen}
      onCancel={onCancel}
      width={1120}
      footer={
        <div className={styles.footer}>
          <Button onClick={onCancel} block size="large" className={styles.cancelButton}>
            Отменить
          </Button>
          <Button
            onClick={onAdd}
            disabled={selected.length === 0}
            block
            size="large"
            type="primary"
            className={styles.addButton}
          >
            Добавить
          </Button>
        </div>
      }
    >
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row gutter={[20, 0]} align="middle">
            <Col span={24}>
              <Search
                className={styles.search}
                bordered={false}
                size="large"
                placeholder="Поиск"
                onChange={onTermChange}
                value={term}
                allowClear
                enterButton={<div className={styles.enterButton}>Найти</div>}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24} className={styles.filtersWrapper}>
          <Row justify="space-between">
            <Col span={12} className={styles.buttonsWrapper}>
              {typeButtons.map(({ label, type }) => {
                const isActive = selectedTypes[0] === type;
                const className = classNames(styles.typeButton, isActive && styles.active);
                return (
                  <Button
                    className={className}
                    key={label}
                    type="text"
                    onClick={handleClickType(type)}
                  >
                    {label}
                  </Button>
                );
              })}
            </Col>
            <Col>
              <Sort fields={sortFields} value={sort} onChange={setSort} />
            </Col>
          </Row>
        </Col>
        <Col span={24} className={styles.postsWrapper}>
          <Row gutter={[20, 20]} justify="center">
            {isLoading && (
              <Col span={24}>
                <Spin />
              </Col>
            )}
            {data?.results?.map((post) => (
              <Col span={8}>
                <PostCard
                  post={post}
                  shortInfo
                  onClick={onPostClick}
                  className={classNames(
                    styles.post,
                    isSelected(post) && styles.selected,
                    !isSelected(post) && !canSelectMore && styles.disabled,
                  )}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export const searchPostsModal = create(Container);

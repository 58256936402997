import { BlockType } from '../block/BlockType';
import { PostType } from './PostType';
import { PostTypeBlockMap } from './PostTypeBlockMap';

// ToDo Cleanup
export const postTypeBlocksMap: {
  [T in PostType]: [
    PostTypeBlockMap[T][], // fixed default
    PostTypeBlockMap[T][], // default
    PostTypeBlockMap[T][], // additional
  ];
} = {
  [PostType.Article]: [
    [BlockType.H1, BlockType.Subtitle, BlockType.Preview, BlockType.Cover],
    [BlockType.Lead, BlockType.Text],
    [
      BlockType.Text,
      BlockType.H2,
      BlockType.H3,
      BlockType.List,
      BlockType.Expert,
      BlockType.Image,
      BlockType.Gallery,
      BlockType.CallToAction,
      // ToDo Дождаться поддержки сервиса видео
      // BlockType.Video,
      BlockType.Widget,
      BlockType.Incut,
      BlockType.Link,
    ],
  ],
  [PostType.Card]: [
    [BlockType.H1, BlockType.Subtitle, BlockType.SeoTitle, BlockType.Preview, BlockType.Cover],
    [BlockType.Intro],
    [BlockType.Card],
  ],
  [PostType.Longread]: [
    [BlockType.H1, BlockType.Subtitle, BlockType.SeoTitle, BlockType.Preview, BlockType.Cover],
    [BlockType.Intro, BlockType.Text],
    [],
  ],
  [PostType.News]: [[BlockType.H1, BlockType.Text, BlockType.NewsSource], [], []],
  [PostType.Spec]: [
    [BlockType.H1, BlockType.Subtitle, BlockType.Preview, BlockType.Cover, BlockType.Link],
    [],
    [],
  ],
  [PostType.Test]: [
    [BlockType.H1, BlockType.Subtitle, BlockType.Preview, BlockType.Cover],
    [BlockType.TestQuestion, BlockType.TestResults],
    [BlockType.TestQuestion, BlockType.TestResults],
  ],
  [PostType.Podcast]: [
    [BlockType.H1, BlockType.Preview, BlockType.Cover, BlockType.Podcast],
    [],
    [],
  ],
  [PostType.Custom]: [
    [BlockType.H1],
    [BlockType.Subtitle, BlockType.Text],
    [
      BlockType.Text,
      BlockType.H2,
      BlockType.H3,
      BlockType.List,
      BlockType.Expert,
      BlockType.Image,
      BlockType.Gallery,
      BlockType.Widget,
      BlockType.Incut,
      BlockType.Link,
    ],
  ],
  [PostType.Thing]: [
    [BlockType.H1, BlockType.Subtitle, BlockType.Preview, BlockType.Cover, BlockType.Link],
    [],
    [],
  ],
  [PostType.Story]: [
    [BlockType.H1, BlockType.Subtitle, BlockType.Preview, BlockType.Cover, BlockType.Link],
    [],
    [],
  ],
};

import { Divider, Form, Select } from 'antd';
import { Store, StoreValue } from 'antd/lib/form/interface';
import { identity, isNil, pipe, reject, slice, unapply } from 'ramda';
import React, { FC, useState } from 'react';

import {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
} from './images';
import styles from './styles.module.scss';

const normalizeValueToArray = pipe<
  [StoreValue, StoreValue, Store],
  StoreValue[],
  StoreValue[],
  StoreValue[]
>(unapply(identity), slice(0, 1), reject(isNil));

const { Option } = Select;

const priorityOptions = [
  { id: '0', image: image1, name: 'Слайдер — 1' },
  { id: '1', image: image2, name: 'Слайдер — 2' },
  { id: '2', image: image3, name: 'Слайдер — 3' },
  { id: '3', image: image4, name: 'Топ — 1' },
  { id: '4', image: image5, name: 'Главная — 1' },
  { id: '5', image: image6, name: 'Главная — 2' },
  { id: '6', image: image7, name: 'Главная — 3' },
  { id: '7', image: image8, name: 'Главная — 4' },
  { id: '8', image: image9, name: 'Главная — 5' },
  { id: '9', image: image10, name: 'Главная — 6' },
  { id: '10', image: image11, name: 'Главная — 7' },
  { id: '11', image: image12, name: 'Главная — 8' },
  { id: '12', image: image13, name: 'Главная — 9' },
  { id: '13', image: undefined, name: 'Лента' },
];

const sliderOptions = [
  { id: '0', image: image1, name: 'Слайдер — 1' },
  { id: '1', image: image2, name: 'Слайдер — 2' },
  { id: '2', image: image3, name: 'Слайдер — 3' },
];

const topOptions = [{ id: '3', image: image4, name: 'Топ — 1' }];

const mainOptions = [
  { id: '4', image: image5, name: 'Главная — 1' },
  { id: '5', image: image6, name: 'Главная — 2' },
  { id: '6', image: image7, name: 'Главная — 3' },
  { id: '7', image: image8, name: 'Главная — 4' },
  { id: '8', image: image9, name: 'Главная — 5' },
  { id: '9', image: image10, name: 'Главная — 6' },
  { id: '10', image: image11, name: 'Главная — 7' },
  { id: '11', image: image12, name: 'Главная — 8' },
  { id: '12', image: image13, name: 'Главная — 9' },
];

const restOptions = [{ id: '13', image: undefined, name: 'Лента' }];

export const PrioritySelect: FC = () => {
  const [hoveredOption, setHoveredOption] = useState<number | undefined>(undefined);

  const dropdownRender = (menu: any) => {
    const isOptionSelected = typeof hoveredOption !== 'undefined';
    const image = isOptionSelected ? priorityOptions[hoveredOption].image : undefined;
    const isImageExsist = typeof image !== 'undefined';

    return (
      <>
        {isOptionSelected && isImageExsist && (
          <div className={styles.imageWrapper}>
            <img src={image} className={styles.image} alt="" />
          </div>
        )}
        {menu}
      </>
    );
  };

  return (
    <Form.Item
      name="priority"
      label="Приоритет"
      labelCol={{ span: 24 }}
      normalize={normalizeValueToArray}
    >
      <Select
        id="prioritySelect"
        virtual={false}
        dropdownRender={dropdownRender}
        size="large"
        placeholder="Выбрать"
        maxTagCount="responsive"
      >
        {sliderOptions.map(({ id, name }, i) => {
          const onMouseOver = () => setHoveredOption(i);
          return (
            <Option key={id} value={id} onMouseOver={onMouseOver}>
              {name}
            </Option>
          );
        })}

        <Option disabled key="divider_1" value="divider_1" className={styles.optionDivider}>
          <Divider className={styles.divider} />
        </Option>

        {topOptions.map(({ id, name }, i) => {
          const onMouseOver = () => setHoveredOption(i + sliderOptions.length);
          return (
            <Option key={id} value={id} onMouseOver={onMouseOver}>
              {name}
            </Option>
          );
        })}

        <Option disabled key="divider_2" value="divider_2" className={styles.optionDivider}>
          <Divider className={styles.divider} />
        </Option>

        {mainOptions.map(({ id, name }, i) => {
          const onMouseOver = () => setHoveredOption(i + sliderOptions.length + topOptions.length);

          return (
            <Option key={id} value={id} onMouseOver={onMouseOver}>
              {name}
            </Option>
          );
        })}

        <Option disabled key="divider_3" value="divider_3" className={styles.optionDivider}>
          <Divider className={styles.divider} />
        </Option>

        {restOptions.map(({ id, name }, i) => {
          const onMouseOver = () =>
            setHoveredOption(i + sliderOptions.length + topOptions.length + mainOptions.length);

          return (
            <Option key={id} value={id} onMouseOver={onMouseOver}>
              {name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

import { PostStatus, PostType, SectionPreviewSecure, SectionStatus } from 'a4bd-meta';
import { isEmpty } from 'ramda';
import React from 'react';

export const getActualSectionsIds = (sections: SectionPreviewSecure<string>[] | []) =>
  sections.filter(({ status }) => status === 'published').map((section) => section.id);

// получаем список опций с учетом подсекций в соответствии с требованиями Select ANTD
export const getSectionsOptions = (
  sections: SectionPreviewSecure<string>[],
  currentType: PostType,
) => {
  const result: (
    | SectionPreviewSecure<string>
    | {
        label: JSX.Element;
        options: SectionPreviewSecure<string>[];
        title: string;
      }
  )[] = [];
  const filteredOptions = sections.filter(({ postTypes }) => postTypes.includes(currentType));
  filteredOptions.forEach((section) => {
    if (section.status !== SectionStatus.published) return;
    result.push(section);
    if (!isEmpty(section.children)) {
      const subsections = {
        label: <span>{section.title}</span>,
        title: section.title,
      };

      const options = [] as SectionPreviewSecure<string>[];
      section.children.forEach((child) => {
        if (child.status !== SectionStatus.published) return;
        options.push(child as unknown as SectionPreviewSecure<string>);
      });

      if (isEmpty(options)) return;
      result.push({ ...subsections, options });
    }
  });
  return result;
};

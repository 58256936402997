import Icon from '@ant-design/icons';
import { Menu as AntdMenu } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import type { SelectInfo } from 'rc-menu/lib/interface';
import React from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { AllPosts, Catalogs, Main, Settings } from '~assets';
import { RoutesPath } from '~constants';

import styles from './styles.module.scss';

const items: ItemType[] = [
  {
    icon: <Icon component={AllPosts} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: RoutesPath.Posts,
    label: 'Все публикации',
  },
  {
    icon: <div className={styles.noIcon} />,
    key: RoutesPath.Articles,
    label: 'Статьи',
  },
  {
    icon: <div className={styles.noIcon} />,
    key: RoutesPath.Stories,
    label: 'Истории',
  },
  {
    icon: <div className={styles.noIcon} />,
    key: RoutesPath.Podcasts,
    label: 'Подкасты',
  },
  {
    icon: <div className={styles.noIcon} />,
    key: RoutesPath.Things,
    label: 'Штуки',
  },
  {
    icon: <Icon component={Catalogs} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: RoutesPath.Catalogs,
    label: 'Справочники',
  },
  {
    icon: <div className={styles.noIcon} />,
    key: RoutesPath.Employees,
    label: 'Сотрудники',
  },
  {
    icon: <Icon component={Main} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: generatePath(RoutesPath.GridSchema, { gridId: 1, sectionId: 1 }),
    label: 'Главная',
  },
  {
    icon: <Icon component={Settings} className={styles.icon} style={{ fontSize: '24px' }} />,
    key: RoutesPath.Settings,
    label: 'Настройки',
  },
  {
    icon: <div className={styles.noIcon} />,
    key: RoutesPath.Sections,
    label: 'Разделы',
  },
  {
    icon: <div className={styles.noIcon} />,
    key: RoutesPath.Grids,
    label: 'Сетки',
  },
  {
    icon: <div className={styles.noIcon} />,
    key: RoutesPath.GridSchemas,
    label: 'Схемы сеток',
  },
  // {
  //   children: [
  //     {
  //       key: RoutesPath.Employees,
  //       label: 'Пользователи',
  //     },
  //     {
  //       key: RoutesPath.Authors,
  //       label: 'Авторы',
  //     },
  //   ],
  //   icon: <Icon component={User} className={styles.icon} style={{ fontSize: '24px' }} />,
  //   key: 'users',
  //   label: 'Справочники',
  // },
  // {
  //   icon: <Icon component={Tag} className={styles.icon} style={{ fontSize: '24px' }} />,
  //   key: RoutesPath.Tags,
  //   label: 'Теги',
  // },
];

export const Menu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onMenuClick = (event: SelectInfo) => {
    navigate(event.key);
  };

  const onSubMenuClick = (openedSubMenus: string[]) => {
    if (openedSubMenus.includes('users')) {
      navigate('/employees');
    }
  };

  const selectedRoute = `${location.pathname}`;

  return (
    <AntdMenu
      selectedKeys={[selectedRoute]}
      mode="inline"
      className={styles.menu}
      onSelect={onMenuClick}
      onOpenChange={onSubMenuClick}
      items={items}
    />
  );
};

import { PostType } from 'a4bd-meta';
import { Col, List, Row } from 'antd';
import { inc } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { InfiniteScroll } from '~components';
import { BASE_LIMIT } from '~constants';
import { postsEntitySelector } from '~selectors';
import { useGetListPostsQuery } from '~services';
import { isDefined } from '~utils';

import { PostCard } from '../PostCard';
import { PostCardInline } from '../PostCardInline';

interface Props {
  filter?: Record<string, any>;
  isCardType?: boolean;
  order?: string;
}

const defaultTypes = [PostType.Article, PostType.Story, PostType.Podcast, PostType.Thing];

export const PostsList: React.FC<Props> = ({ filter = {}, order, isCardType = true }) => {
  const [page, setPage] = useState(0);
  const { data, isError, isFetching } = useGetListPostsQuery(
    {
      limit: BASE_LIMIT,
      offset: page * BASE_LIMIT,
      order,
      ...filter,
      type: filter?.type || defaultTypes,
    },
    { pollingInterval: 60000, refetchOnMountOrArgChange: true, refetchOnReconnect: true },
  );
  const posts = useSelector(postsEntitySelector.selectAll);

  const fetchData = async () => {
    setPage(inc);
  };

  useEffect(() => {
    setPage(0);
  }, [order, filter]);

  return (
    <InfiniteScroll
      fetchData={fetchData}
      hasMore={!isError && isDefined(data) && data?.count > posts?.length}
      isFetching={isFetching}
    >
      {isCardType ? (
        <Row gutter={[25, 25]}>
          {posts?.map((post) => (
            <Col lg={12} xl={8} key={post.id}>
              <PostCard post={post} />
            </Col>
          ))}
        </Row>
      ) : (
        <List
          itemLayout="vertical"
          size="large"
          grid={{
            column: 1,
            gutter: 20,
          }}
        >
          {posts?.map((post) => (
            <PostCardInline post={post} />
          ))}
        </List>
      )}
    </InfiniteScroll>
  );
};

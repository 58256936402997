import { PostType, SectionSecure } from 'a4bd-meta';
import { equals } from 'ramda';
import React from 'react';

import { useGetListPostsQuery } from '~services';
import { isNotMain } from '~utils';

import { InfoPageCard } from './InfoPageCard';
import styles from './styles.module.scss';

export const SettingsTabInfoPages: React.FunctionComponent = React.memo(() => {
  const { data: customPostsResults } = useGetListPostsQuery({
    type: [PostType.Custom],
  });

  const posts = customPostsResults?.results ?? [];

  return (
    <div className={styles.grid}>
      {posts.map(({ id, sections }) => {
        const [section] = sections.filter(isNotMain);
        return <InfoPageCard key={id} postId={id} section={section as SectionSecure} />;
      })}
    </div>
  );
}, equals);

import { FileImageVariantType } from 'a4bd-meta';
import React from 'react';

import { Desktop as DesktopIcon, Mobile as MobileIcon, Tablet as TabletIcon } from '~assets';

import { CropTypes } from './types';

export const Default: CropTypes = {
  id: 0,
  ratio: 3.247,
  title: 'дефолт',
  type: FileImageVariantType.DesktopHead,
};

export const Desktop: CropTypes[] = [
  { id: 0, ratio: 3.247, title: 'шапка', type: FileImageVariantType.DesktopHead },
  { id: 1, ratio: 2.414, title: 'слайдер', type: FileImageVariantType.DesktopSlider },
  {
    id: 2,
    ratio: 1.588,
    title: 'широкая',
    type: FileImageVariantType.DesktopWideCard,
  },
  { id: 3, ratio: 0.7576, title: 'узкая', type: FileImageVariantType.DesktopCard },
];

export const Tablet: CropTypes[] = [
  { id: 4, ratio: 2.0823, title: 'шапка', type: FileImageVariantType.TabletHead },
  { id: 5, ratio: 1.49, title: 'слайдер/широкая', type: FileImageVariantType.TabletWideCard },
  { id: 6, ratio: 0.79, title: 'узкая', type: FileImageVariantType.TabletCard },
];

export const Mobile: CropTypes[] = [
  { id: 7, ratio: 0.8375, title: 'мобайл', type: FileImageVariantType.Mobile },
];

export const cropData = [
  { icon: <DesktopIcon />, props: Desktop, title: 'десктоп' },
  { icon: <TabletIcon />, props: Tablet, title: 'таблет' },
  {
    icon: <MobileIcon />,
    props: Mobile,
    title: 'мобайл',
  },
];

import { EmployeeRole, ImageProxyResize } from 'a4bd-meta';
import { Col, Form, Input, Row, Select } from 'antd';
import React, { FC } from 'react';

import { Blocked } from '~assets';
import { Button, Confirm, Modal, Visible } from '~components';
import { DropType } from '~constants';
import { getUserRoleTitle, noop } from '~utils';

import { DropZone } from '../DropZone';
import styles from './styles.module.scss';
import { EmployeeModalProps } from './types';
import { useEmployeeModal } from './useEmployeeModal';

export const EmployeeModal: FC<EmployeeModalProps> = ({ id, isOpen = false, onClose = noop }) => {
  const {
    blockText,
    confirmData,
    handleModalOpen,
    hasPermission,
    imageProxyHost,
    initialValues,
    isBlocked,
    isMe,
    modalTitle,
    onCancel,
    onFinish,
    submitButtonText,
  } = useEmployeeModal({
    id,
    onClose,
  });

  return (
    <>
      <Modal
        title={modalTitle}
        open={isOpen}
        destroyOnClose
        onCancel={onCancel}
        width={613}
        footer={null}
      >
        <Form
          layout="vertical"
          name="employee"
          validateTrigger="submit"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row gutter={[0, 15]}>
            <Col md={24} xs={24}>
              <div className={styles.dropzoneWrapper}>
                <Form.Item name="avatar">
                  <DropZone
                    onlyIcon
                    type={DropType.Image}
                    className={isBlocked ? styles.avaBlocked : undefined}
                    imageOptions={{
                      domain: imageProxyHost,
                      height: 120,
                      resize: ImageProxyResize.Fill,
                      width: 120,
                    }}
                  />
                </Form.Item>
                {isBlocked && (
                  <div className={styles.blocked}>
                    <Blocked />
                  </div>
                )}
              </div>
            </Col>
            <Col md={24} xs={24}>
              <Row gutter={[15, 15]}>
                <Col span={12}>
                  <div className={styles.linkWrapper}>
                    <div className={styles.linkTitle}>Фамилия</div>
                    <div className={styles.itemWrapper}>
                      <Form.Item name="surname" className={styles.item}>
                        <Input
                          size="large"
                          placeholder="Введите фамилию"
                          className={styles.input}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={styles.linkWrapper}>
                    <div className={styles.linkTitle}>Имя</div>
                    <div className={styles.itemWrapper}>
                      <Form.Item name="name" className={styles.item}>
                        <Input size="large" placeholder="Введите имя" className={styles.input} />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={24} hidden={!hasPermission}>
                  <div className={styles.linkWrapper}>
                    <div className={styles.linkTitle}>Роль</div>
                    <div className={styles.itemWrapper}>
                      <Form.Item name="role" className={styles.item}>
                        <Select
                          size="large"
                          className={styles.select}
                          options={[
                            {
                              label: getUserRoleTitle(EmployeeRole.chief),
                              value: EmployeeRole.chief,
                            },
                            {
                              label: getUserRoleTitle(EmployeeRole.editor),
                              value: EmployeeRole.editor,
                            },
                            {
                              label: getUserRoleTitle(EmployeeRole.corrector),
                              value: EmployeeRole.corrector,
                            },
                            {
                              label: getUserRoleTitle(EmployeeRole.intern),
                              value: EmployeeRole.intern,
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={24} className={styles.buttonsBlock}>
              <Row justify="space-between">
                <Visible isVisible={!isMe}>
                  <Col>
                    <Button type="text" size="large" onClick={handleModalOpen}>
                      {blockText}
                    </Button>
                  </Col>
                </Visible>
                <Col>
                  <Row justify={{ md: 'start', xs: 'center' }} gutter={10}>
                    <Col>
                      <Button onClick={onCancel} size="large">
                        Отмена
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" htmlType="submit" size="large">
                        {submitButtonText}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Confirm {...confirmData} />
    </>
  );
};

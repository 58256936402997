import { GridSchemaSecure } from 'a4bd-meta';
import { Button, Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Edit } from '~assets';
import { formatDate } from '~utils';

import styles from './styles.module.scss';

interface Props {
  item: GridSchemaSecure;
  onEdit(gridSchema: GridSchemaSecure): void;
}

export const GridSchemaCard: React.FC<Props> = ({ item, onEdit }) => {
  const dispatch = useDispatch();
  const {
    createdAt,
    grid: { title: gridTitle },
    id,
    layout,
    title,
  } = item;

  const onClickEdit = () => {
    onEdit(item);
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Row className={styles.wrapper} align="middle">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Row gutter={[10, 0]}>
                <Col>
                  <Typography.Text className={styles.title}>{title || layout}</Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Button icon={<Edit />} type="text" onClick={onClickEdit} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text className={styles.label}>ID: </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.text}>{id}</Typography.Text>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Typography.Text className={styles.label}>Сетка: </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.text}>{gridTitle}</Typography.Text>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Typography.Text className={styles.label}>Дата создания:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.text}>
                    {formatDate(createdAt, 'dd MMMM yyyy')}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

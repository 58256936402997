import { PostStatus, PostType } from 'a4bd-meta';
import { Checkbox, Col, Form, FormInstance, Input, message, Modal, Row } from 'antd';
import { formatISO, isValid } from 'date-fns';
import { parseISO } from 'date-fns/fp';
import { stringify } from 'query-string';
import { __, always, applySpec, evolve, ifElse, includes, isNil, map, prop } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { ArrowLeft } from '~assets';
import { Button, Confirm, Visible } from '~components';
import { PublicRoutesPath } from '~constants';
import { usePost } from '~hooks';
import { ConfigContext } from '~providers';
import {
  useCreatePostPreviewTokenMutation,
  useDeletePostMutation,
  usePublishPostMutation,
  useUnpublishPostMutation,
  useUpdatePostMutation,
} from '~services';
import { isSuccessResult } from '~utils';

import styles from './styles.module.scss';

const isPostPublished = includes(__, [PostStatus.Published, PostStatus.Postponed]);

export const SettingsControls: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isPinned, setPinned] = useState(false);
  const post = usePost();
  const { hasUnpublishedChanges, id: postId, status, type } = post;
  const { previewUrl } = useContext(ConfigContext);

  const publishedTime = Form.useWatch('publishedAt', form);

  const [createPostPreviewToken, { isLoading: isCreatingPostPreviewToken }] =
    useCreatePostPreviewTokenMutation();
  const [publishPost, { isLoading: isPublishing }] = usePublishPostMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const canPreview = type !== PostType.News && type !== PostType.Spec;
  const isPublished = isPostPublished(status);

  const getInitialValues = evolve({
    isVisibleForCrawlers: map(prop('isVisibleForCrawlers')),
    isVisibleOnSite: map(prop('isVisibleOnSite')),
  });

  // ToDo Убрать, когда с бэка будут приходить параметры
  // @ts-ignore
  const initialValues = getInitialValues(post);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handlePreview = async () => {
    const result = await createPostPreviewToken({ expiresIn: '1h', id: postId });
    if (isSuccessResult(result)) {
      const { token } = result.data;
      window.open(
        `${previewUrl}${generatePath(PublicRoutesPath.PostPreview, { postId })}?${stringify({
          token,
        })}`,
        '_blank',
      );
    } else {
      message.error('Ошибка получения токена');
    }
  };

  const handlePublish = async () => {
    publishPost({
      id: post?.id,
      publishedAt: formatISO(isValid(publishedTime) ? publishedTime : new Date()),
    });
  };

  const onCommit = handlePublish;

  useEffect(() => {
    const el = document.querySelector(`.${styles.myElement}`);
    const observer = new IntersectionObserver(
      ([e]) => {
        const pinned = e.intersectionRatio < 1;
        setPinned(pinned);
        e.target.classList.toggle(`${styles.isPinned}`, pinned);
      },
      { threshold: [1] },
    );

    if (el) observer.observe(el);
  }, []);

  const goBack = () => navigate(-1);

  return (
    <>
      <Col span={24} className={styles.myElement}>
        <Row gutter={[0, 25]}>
          <Col span={24}>
            <Row gutter={[10, 10]} justify="space-between">
              <Col>
                <Row gutter={[10, 10]}>
                  <Visible isVisible={isPinned}>
                    <Col>
                      <Button icon={<ArrowLeft />} size="large" type="ghost" onClick={goBack}>
                        Выйти
                      </Button>
                    </Col>
                  </Visible>
                  <Form
                    form={form}
                    layout="inline"
                    initialValues={initialValues}
                    style={{ marginRight: '-15px' }}
                  >
                    <Visible isVisible={false}>
                      <Form.Item
                        name="isVisibleOnSite"
                        labelCol={{ span: 24 }}
                        className={styles.checkboxItem}
                      >
                        <Checkbox>Видимость на сайте</Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="isVisibleForCrawlers"
                        labelCol={{ span: 24 }}
                        className={styles.checkboxItem}
                      >
                        <Checkbox>Видимость в поисковых системах</Checkbox>
                      </Form.Item>
                    </Visible>
                  </Form>
                </Row>
              </Col>
              <Col>
                <Row gutter={[10, 10]} justify={{ md: 'end', xs: 'start' }}>
                  <Visible isVisible={canPreview}>
                    <Col>
                      <Button
                        size="large"
                        type="default"
                        loading={isCreatingPostPreviewToken}
                        onClick={handlePreview}
                      >
                        Предпросмотр
                      </Button>
                    </Col>
                  </Visible>
                  <Col>
                    <Button size="large" type="primary" loading={isPublishing} onClick={onCommit}>
                      Применить
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Confirm
        open={isModalOpen}
        onCancel={handleModalClose}
        cancelText="Отменить"
        title="Опубликовать?"
        content="Убедитесь, что заполнены все необходимые блоки."
        okText="Опубликовать"
        onOk={async () => {
          handleModalClose();
          await handlePublish();
        }}
      />
    </>
  );
};

import { BlockContentPodcastLinkItem, BlockType, PodcastLinkType } from 'a4bd-meta';
import { Col, Form, Input, Row, Space } from 'antd';
import { always, cond, equals, prop } from 'ramda';
import React, { FC, useMemo, useState } from 'react';

import CastboxIcon from '~assets/podcasts/castbox.svg';
import GoogleIcon from '~assets/podcasts/google.svg';
import SoundCloudIcon from '~assets/podcasts/soundcloud.svg';
import YaMusicIcon from '~assets/podcasts/ya.svg';
import { AddPodcast, Button, Visible } from '~components';
import { DropType } from '~constants';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const getBlockIcon = cond([
  [equals(PodcastLinkType.Google), always(<GoogleIcon />)],
  [equals(PodcastLinkType.YandexMusic), always(<YaMusicIcon />)],
  [equals(PodcastLinkType.Castbox), always(<CastboxIcon />)],
  [equals(PodcastLinkType.SoundCloud), always(<SoundCloudIcon />)],
]);

const podcastServices = [
  {
    icon: '',
    title: 'Apple Подкасты',
    type: PodcastLinkType.ApplePodcasts,
    url: '',
  },
  {
    icon: '',
    title: 'Яндекс.Музыка',
    type: PodcastLinkType.YandexMusic,
    url: '',
  },
  {
    icon: '',
    title: 'Overcast',
    type: PodcastLinkType.Overcast,
    url: '',
  },
  {
    icon: '',
    title: 'Castbox',
    type: PodcastLinkType.Castbox,
    url: '',
  },
];

export const PodcastBlock: FC<BlockProps<BlockType.Podcast>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [isWithPlayer, setWithPlayer] = useState(true);

  const [form] = Form.useForm();
  const { content, files } = block;

  const initialLinks = content.podcastLinks.length > 0 ? content.podcastLinks : podcastServices;

  const [currentServices, setCurrentServices] =
    useState<BlockContentPodcastLinkItem[]>(initialLinks);

  const fullfillPodcastLink = (blocks: BlockContentPodcastLinkItem[]) => {
    return blocks.map((block) => {
      return {
        icon: block.icon || '',
        title: block.title,
        type: block.type,
        url: block.url || '',
      };
    });
  };

  const formatValues: FormBlockProps<BlockType.Podcast>['formatValues'] = (content) => {
    return {
      content: {
        ...content,
        podcastLinks: fullfillPodcastLink(content.podcastLinks),
      },
      fileIds: [content.fileId].filter(isNotNil),
    };
  };

  const initialValues = useMemo(() => {
    return {
      ...content,
    };
  }, [content]);

  const availableBlocks = () => {
    return podcastServices.filter((service) => {
      return !currentServices.find((currentService) => currentService.type === service.type);
    });
  };

  const handleAddBlock = (type: PodcastLinkType) => {
    const block = podcastServices.find((service) => service.type === type);

    const blocks = [...currentServices, block];
    setCurrentServices(blocks as BlockContentPodcastLinkItem[]);
  };

  const handleWithPlayer = () => setWithPlayer(true);
  const handleWithoutPlayer = () => setWithPlayer(false);

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={initialValues}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Подкаст</Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row className={styles.controls}>
            <Button
              onClick={handleWithPlayer}
              type="ghost"
              className={isWithPlayer ? styles.buttonSelected : styles.button}
            >
              С плеером
            </Button>
            <Button
              onClick={handleWithoutPlayer}
              type="ghost"
              className={!isWithPlayer ? styles.buttonSelected : styles.button}
            >
              Без плеера
            </Button>
          </Row>
        </Col>

        <Col span={24}>
          <Visible isVisible={isWithPlayer}>
            <Form.Item
              name="fileId"
              getValueFromEvent={prop('id')}
              getValueProps={() => ({
                value: getFileById(content.fileId, files),
              })}
            >
              <DropZone
                blockType={block.type}
                type={DropType.Audio}
                imageOptions={{ height: 214, width: 500 }}
              />
            </Form.Item>
          </Visible>

          <Space direction="vertical" className={styles.sideServicesWrapper}>
            {currentServices.map(({ title, type, url }, i) => {
              return (
                <div key={i} className={styles.sideService}>
                  <Form.Item name={['podcastLinks', i, 'icon']} hidden className={styles.item}>
                    <div className={styles.iconWrapper}>{getBlockIcon(type)}</div>
                  </Form.Item>
                  <Form.Item name={['podcastLinks', i, 'type']} hidden initialValue={type}>
                    <Input value={type} />
                  </Form.Item>
                  <Form.Item name={['podcastLinks', i, 'title']} hidden initialValue={title}>
                    <Input value={title} />
                  </Form.Item>
                  <div className={styles.linkWrapper}>
                    <div className={styles.linkTitle}>{title}</div>
                    <div className={styles.itemWrapper}>
                      <Form.Item name={['podcastLinks', i, 'url']} className={styles.item}>
                        <Input
                          value={url}
                          bordered={false}
                          className={styles.input}
                          placeholder="Добавьте ссылку"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              );
            })}
          </Space>
        </Col>
      </Row>
      <AddPodcast availableBlocks={availableBlocks()} handleAddBlock={handleAddBlock} />
    </FormBlock>
  );
};

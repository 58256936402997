import { BlockChildMap } from './BlockChildMap';
import { BlockType } from './BlockType';

export const blockTypeChildMap: {
  [T in BlockType]: [
    BlockChildMap<T>[], // fixed default
    BlockChildMap<T>[], // default
    BlockChildMap<T>[], // additional
  ];
} = {
  [BlockType.Card]: [
    [BlockType.H2],
    [BlockType.Text],
    [
      BlockType.Text,
      BlockType.Image,
      BlockType.Gallery,
      BlockType.Video,
      BlockType.Gif,
      BlockType.Widget,
      BlockType.List,
    ],
  ],
  [BlockType.Expert]: [[], [BlockType.Text], [BlockType.H3, BlockType.Text, BlockType.List]],
  [BlockType.CallToAction]: [[], [], []],
  [BlockType.Columns]: [[], [], []],
  [BlockType.Cover]: [[], [], []],
  [BlockType.Gallery]: [[], [], []],
  [BlockType.Gif]: [[], [], []],
  [BlockType.H1]: [[], [], []],
  [BlockType.H2]: [[], [], []],
  [BlockType.H3]: [[], [], []],
  [BlockType.Image]: [[], [], []],
  [BlockType.Incut]: [[], [], []],
  [BlockType.Intro]: [[], [], []],
  [BlockType.Line]: [[], [], []],
  [BlockType.List]: [[], [], []],
  [BlockType.MoreOnTopic]: [[], [], []],
  [BlockType.NewsSource]: [[], [], []],
  [BlockType.Preview]: [[], [], []],
  [BlockType.Quiz]: [[], [], []],
  [BlockType.SeoTitle]: [[], [], []],
  [BlockType.Link]: [[], [], []],
  [BlockType.Subtitle]: [[], [], []],
  [BlockType.TestQuestion]: [[], [], []],
  [BlockType.TestResults]: [[], [], []],
  [BlockType.Text]: [[], [], []],
  [BlockType.Video]: [[], [], []],
  [BlockType.Widget]: [[], [], []],
  [BlockType.Podcast]: [[], [], []],
  [BlockType.Lead]: [[], [], []],
};

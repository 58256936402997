import { Http } from '@status/codes';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { always, cond, equals } from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Eye, EyeDisable, Logo } from '~assets';
import { RoutesPath } from '~constants';
import { isAuthenticatedSelector } from '~selectors';
import { useLoginUserMutation } from '~services';
import { isSuccessResult } from '~utils';

import styles from './styles.module.scss';

const layout = {
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};

const getErrorMessage = cond<[number], string>([
  [equals(Http.Unauthorized), always('Неверный логин или пароль!')],
]);

const AuthPage: FC = () => {
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginUserMutation();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (isAuthenticated) navigate(RoutesPath.Posts);
  }, [navigate, isAuthenticated]);

  const onFinish = async (values: any) => {
    setErrorMessage(null);

    const result = await login(values);

    if (!isSuccessResult(result) && result.error) {
      const { data, status } = result.error as any;

      setErrorMessage(getErrorMessage(status) || data?.message);
    }
  };

  const iconRender = (visible: boolean) =>
    visible ? (
      <span style={{ cursor: 'pointer', height: '24px', marginTop: '-5px' }}>
        <Eye />
      </span>
    ) : (
      <span style={{ cursor: 'pointer', height: '24px' }}>
        <EyeDisable />
      </span>
    );

  return (
    <div className={styles.wrapper}>
      <Form {...layout} layout="vertical" name="auth" onFinish={onFinish} className={styles.form}>
        <Row gutter={[0, 15]} justify="center">
          <Col span={24} className={styles.logoWrapper}>
            <Logo className={styles.logo} />
          </Col>
          <Col span={24} className={styles.title}>
            Добро пожаловать!
          </Col>
          <Col span={24} className={styles.description}>
            Для входа в систему введите логин и пароль
          </Col>
          <Col span={24} className={styles.formWrapper}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Form.Item
                  name="username"
                  label="Логин"
                  rules={[
                    {
                      message: 'Введите логин',
                      required: true,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Введите свой логин" className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="password"
                  label="Пароль"
                  rules={[
                    {
                      message: 'Введите пароль',
                      required: true,
                    },
                  ]}
                  style={{ width: '100%' }}
                >
                  <Input.Password
                    size="large"
                    placeholder="Введите свой пароль"
                    style={{ height: '40px' }}
                    iconRender={iconRender}
                    className={styles.input}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item {...tailLayout} style={{ marginTop: '15px', width: '100%' }}>
              <Button type="primary" htmlType="submit" size="large" block loading={isLoading}>
                Войти
              </Button>
            </Form.Item>
          </Col>
          <Col span={24} className={styles.error}>
            <Typography.Text type="danger" strong>
              {errorMessage}
            </Typography.Text>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AuthPage;

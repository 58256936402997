import { Popover } from 'antd';
import { EditorState } from 'draft-js';
import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';

import { LinkForm } from '../LinkForm';
import { TooltipForm } from '../TooltipForm';

interface Props {
  children: ReactNode;
  contentType: 'link' | 'tooltip';
  editorState: EditorState;
  setEditorState: Dispatch<SetStateAction<EditorState>>;
}

export const PopoverComp: FC<Props> = ({ children, contentType, editorState, setEditorState }) => {
  let content;

  const [isOpen, setIsOpen] = useState(false);

  switch (contentType) {
    case 'link':
      content = (
        <LinkForm onSubmit={setEditorState} editorState={editorState} setParentOpen={setIsOpen} />
      );
      break;
    case 'tooltip':
      content = (
        <TooltipForm
          onSubmit={setEditorState}
          editorState={editorState}
          setParentOpen={setIsOpen}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Popover
      open={isOpen}
      placement="bottom"
      destroyTooltipOnHide
      content={content}
      mouseLeaveDelay={0}
      trigger="click"
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      {children}
    </Popover>
  );
};

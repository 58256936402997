import { Tag } from 'draft-convert';
import { RawDraftEntity } from 'draft-js';

export function entityToHTML(entity: RawDraftEntity, text: string): Tag {
  if (entity.type === 'LINK') {
    const href = encodeURI(decodeURIComponent(entity.data.url)).replace(/'/g, '%27');

    return {
      end: '</a>',
      start: `<a href="${href}" target="_blank" rel="noopener noreferrer">`,
    };
  }

  if (entity.type === 'TOOLTIP') {
    const { body } = entity.data;

    return {
      end: '</tooltip-mark>',
      start: `<tooltip-mark data-body="${body}">`,
    };
  }

  return text;
}

import { Block, BlockType, postTypeBlocksMap } from 'a4bd-meta';
import { Col, Row } from 'antd';
import { always, ascend, complement, cond, equals, prop, sort, T } from 'ramda';
import React, { FC, Fragment, memo } from 'react';

import { Visible } from '~components';
import {
  CardBlock,
  ColumnsBlock,
  CoverBlock,
  CTABlock,
  EmptyBlock,
  ExpertBlock,
  GalleryBlock,
  H2Block,
  H3Block,
  ImageBlock,
  IncutBlock,
  LeadBlock,
  LineBlock,
  LinkBlock,
  ListBlock,
  MoreOnTopicBlock,
  NewsSourceBlock,
  PodcastBlock,
  QuizBlock,
  SeoTitleBlock,
  SubtitleBlock,
  TestQuestionBlock,
  TestResultBlock,
  TextBlock,
  TitleBlock,
  VideoBlock,
  WidgetBlock,
} from '~postBlocks';
import { PostProps } from '~types';
import { isBlock } from '~utils';

import { AddBlock } from '../AddBlock';

// ToDo Реализовать недостающие блоки
export const getBlockComponent = cond([
  [equals(BlockType.H1), always(TitleBlock)],
  [equals(BlockType.H2), always(H2Block)],
  [equals(BlockType.H3), always(H3Block)],
  [equals(BlockType.Subtitle), always(SubtitleBlock)],
  [equals(BlockType.SeoTitle), always(SeoTitleBlock)],
  [equals(BlockType.Intro), always(TextBlock)],
  [equals(BlockType.Text), always(TextBlock)],
  [equals(BlockType.CallToAction), always(CTABlock)],
  [equals(BlockType.NewsSource), always(NewsSourceBlock)],
  [equals(BlockType.Cover), always(CoverBlock)],
  [equals(BlockType.Gif), always(ImageBlock)],
  [equals(BlockType.Image), always(ImageBlock)],
  [equals(BlockType.Video), always(VideoBlock)],
  [equals(BlockType.Widget), always(WidgetBlock)],
  [equals(BlockType.Link), always(LinkBlock)],
  [equals(BlockType.Lead), always(LeadBlock)],
  [equals(BlockType.Quiz), always(QuizBlock)],
  [equals(BlockType.Line), always(LineBlock)],
  [equals(BlockType.MoreOnTopic), always(MoreOnTopicBlock)],
  [equals(BlockType.List), always(ListBlock)],
  [equals(BlockType.Columns), always(ColumnsBlock)],
  [equals(BlockType.Card), always(CardBlock)],
  [equals(BlockType.Expert), always(ExpertBlock)],
  [equals(BlockType.Podcast), always(PodcastBlock)],
  // Не показываем блок в интерфейсе
  [equals(BlockType.Preview), always(null)],
  [equals(BlockType.Incut), always(IncutBlock)],
  [equals(BlockType.Gallery), always(GalleryBlock)],
  [equals(BlockType.TestQuestion), always(TestQuestionBlock)],
  [equals(BlockType.TestResults), always(TestResultBlock)],
  [T, always(EmptyBlock)],
]);

const sortBlocks = sort<Block<BlockType, string>>(ascend(prop('sortOrder')));
type Props = Pick<PostProps['post'], 'type' | 'blocks' | 'sections'>;

export const PostBlocks: FC<Props> = memo(({ blocks, sections, type }) => {
  const [fixedDefaultBlocks, , availableToAddBlocks] = postTypeBlocksMap[type];

  const hasTestResultBlock = blocks.filter(isBlock(BlockType.TestResults)).length > 0;

  const filterAvailableBlocks = cond([
    // Убираем блок результатов, если уже есть один
    // https://itprojectnm.atlassian.net/browse/PNS-800
    // ToDo Изменить структуру блока так, чтобы результат был неизменной его частью
    [always(hasTestResultBlock), complement(equals(BlockType.TestResults))],
    [T, T],
  ]);

  return (
    <Row gutter={[0, 20]}>
      {sortBlocks(blocks)?.map((block, blockIndex) => {
        const BlockComponent = getBlockComponent(block.type);

        // ToDo Эту логику нужно перенести в FormBlock, чтобы избежать ненужный проброс пропса canDelete Block ->
        // @ts-ignore
        const blockIsFixed = fixedDefaultBlocks.find((value: BlockType) => block.type === value);

        const { sortOrder } = block;

        return BlockComponent ? (
          <Fragment key={block.id}>
            <Col span={24}>
              <BlockComponent
                block={block}
                canDelete={!blockIsFixed}
                blockIndex={blockIndex}
                sections={sections}
              />
            </Col>
            <Visible isVisible={sortOrder >= -1}>
              <Col span={24}>
                <AddBlock
                  availableBlocks={(availableToAddBlocks as BlockType[]).filter(
                    filterAvailableBlocks,
                  )}
                  sortOrder={block.sortOrder}
                />
              </Col>
            </Visible>
          </Fragment>
        ) : null;
      })}
    </Row>
  );
}, equals);

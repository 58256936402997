import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, MenuProps, Row } from 'antd';
import { isNil } from 'ramda';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Burger, Search as SearchIcon } from '~assets';
import { GlobalContext } from '~providers';
import { isDefined } from '~utils';

import styles from './styles.module.scss';

interface Props {
  menuDropdown?: MenuProps;

  onCreate?(isOpen: boolean): void;

  onSearch?(value: string): void;

  placeholderText?: string;
  term?: string;
  title: string;
  titlePostfix?: ReactNode;
  withSearch?: boolean;
}

const { Search } = Input;

// ToDo Логика повторяется с PageHeader, требуется рефактор
export const MobilePageHeader: React.FC<Props> = (props) => {
  const {
    menuDropdown,
    onCreate,
    onSearch,
    placeholderText = 'Найти',
    term,
    title,
    titlePostfix,
    withSearch = true,
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>();

  const { setIsMenuShown } = useContext(GlobalContext);

  const onClick = useCallback(() => {
    if (isDefined(onCreate)) {
      onCreate(true);
    }
  }, [onCreate]);

  const onMenuClick = () => {
    setIsMenuShown(true);
  };

  const handleChangeSearchTerm: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handlePressEnter: KeyboardEventHandler<HTMLInputElement> = useCallback(() => {
    if (isDefined(onSearch) && isDefined(searchTerm)) {
      onSearch(searchTerm);
    }
  }, [searchTerm, onSearch]);

  useEffect(() => {
    if (isNil(term)) {
      setSearchTerm(undefined);
    }
  }, [term]);

  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Row gutter={[21, 21]} align="middle">
              <Col>
                <Burger onClick={onMenuClick} />
              </Col>
              <Col>
                <div className={styles.titleWrapper}>
                  {title}
                  {titlePostfix}
                </div>
              </Col>
            </Row>
          </Col>
          {onCreate && (
            <Dropdown
              menu={menuDropdown}
              placement="bottomRight"
              trigger={menuDropdown ? ['click'] : []}
            >
              <Button
                onClick={menuDropdown ? undefined : onClick}
                type="primary"
                className={styles.createButton}
              >
                <PlusOutlined />
              </Button>
            </Dropdown>
          )}
        </Row>
      </Col>
      {withSearch && (
        <Col span={24}>
          <Search
            className={styles.search}
            bordered={false}
            size="large"
            placeholder={placeholderText}
            prefix={<SearchIcon width={24} height={24} className={styles.prefix} />}
            onChange={handleChangeSearchTerm}
            onPressEnter={handlePressEnter}
            onSearch={onSearch}
            value={searchTerm}
            allowClear
            enterButton={<div className={styles.enterButton}>Найти</div>}
          />
        </Col>
      )}
    </Row>
  );
};

export enum SettingSocialNetworkAlias {
  Dzen = 'dzen',
  Facebook = 'facebook',
  Ok = 'odnoklassniki',
  Telegram = 'telegram',
  TikTok = 'tiktok',
  Twitter = 'twitter',
  Vk = 'vk',
  WhatsApp = 'whatsapp',
  YouTube = 'youtube',
}

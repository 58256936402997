import { Setting, UpdateSettingBodySecure } from 'a4bd-meta';
import { Form, FormInstance } from 'antd';
import { pick } from 'ramda';
import React, { createContext, FC, PropsWithChildren, useMemo } from 'react';

import { InfoPagesTypes } from '~constants';
import { useGetSettingsQuery } from '~services';
import { isNotNil } from '~utils';

export type SettingForms = InfoPagesTypes.Socials;
type Context = {
  forms: {
    [key in SettingForms]: {
      form: FormInstance<UpdateSettingBodySecure>;
      initialValues: Partial<Setting>;
      isError: boolean;
      isFetching: boolean;
    };
  };
  isReadyToSave: boolean;
};

export const SettingsContext = createContext<Context>({} as Context);

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [socialsForm] = Form.useForm<UpdateSettingBodySecure>();

  const {
    data: settings,
    isError: isErrorSettings,
    isFetching: isFetchingSettings,
  } = useGetSettingsQuery({
    relations: ['certificate'],
  });

  const value: Context = useMemo(
    () => ({
      forms: {
        [InfoPagesTypes.Socials]: {
          form: socialsForm,
          initialValues: pick(['socialNetworkLinks', 'socialNetworkShare'], settings ?? {}),
          isError: isErrorSettings,
          isFetching: isFetchingSettings,
        },
      },
      isReadyToSave: isNotNil(settings),
    }),
    [socialsForm, settings, isErrorSettings, isFetchingSettings],
  );
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

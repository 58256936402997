import { Col, Form, Input, Row } from 'antd';
import { EditorState } from 'draft-js';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import styles from '../../../../containers/Blocks/SeoTitleBlock/styles.module.scss';
import { getLink } from '../../helpers';
import { getLinkString } from '../../helpers/getLink';

type LinkFormProps = {
  editorState: EditorState;
  onSubmit: Dispatch<SetStateAction<EditorState>>;
  setParentOpen: Dispatch<SetStateAction<boolean>>;
};

export const LinkForm: FC<LinkFormProps> = ({ editorState, onSubmit, setParentOpen }) => {
  const [form] = Form.useForm();
  const { getFieldsValue } = form;

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();
    if (formValues.url) {
      onSubmit(getLink(editorState, formValues.url));
      setParentOpen(false);
    }
  }, [getFieldsValue, editorState]);

  const initialValues = {
    url: getLinkString(editorState),
  };

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') handleFinish();
  }, []);

  return (
    <Form
      name="link"
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
      onKeyPress={handleKeyPress}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Form.Item name="url" className={styles.inputRow}>
            <Input bordered={false} placeholder="Введите ссылку" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

import { EmployeeExtSecure, getFileURL, ImageProxyResize } from 'a4bd-meta';
import { Button, Card, Col, Image, Row } from 'antd';
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Blocked, Edit } from '~assets';
import DefaultAvatar from '~assets/png/defaultAvatar.png';
import { useHasPermission, useIsMe } from '~hooks';
import { ConfigContext } from '~providers';
import { getUserRoleTitle } from '~utils';

import styles from './styles.module.scss';

interface Props {
  item: EmployeeExtSecure;

  onEdit?(id: number): void;
}

const bodyStyle: React.CSSProperties = {
  height: '100%',
  padding: 0,
};

export const EmployeeCard: React.FC<Props> = (props) => {
  const { item, onEdit } = props;
  const { avatar, id, name, role, status } = item;
  const isBlocked = status === 'blocked';
  const navigate = useNavigate();
  const { imageProxyHost } = useContext(ConfigContext);

  const hasPermission = useHasPermission();
  const isMe = useIsMe(id);

  const onCardClick = useCallback(() => {
    if (!isBlocked && (hasPermission || isMe)) {
      // navigate(
      //   generatePath(RoutesPath.EmployeeEdit, {
      //     id,
      //   }),
      // );
    }
  }, [navigate, id]);

  const onEditClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    if (hasPermission || isMe) {
      if (onEdit) onEdit(id);
    }
  };

  return (
    <Col xs={24} md={12}>
      <Card
        hoverable={!isBlocked && (hasPermission || isMe)}
        className={styles.card}
        bodyStyle={bodyStyle}
        onClick={onCardClick}
      >
        {isBlocked && <div className={styles.disable} />}

        <Row gutter={[15, 5]} className={styles.cardWrapper}>
          <Col className={styles.leftSideWrapper}>
            <Col>
              <Image
                src={
                  avatar
                    ? getFileURL(avatar, {
                        domain: imageProxyHost,
                        height: 60,
                        resize: ImageProxyResize.Fill,
                        width: 60,
                      })
                    : DefaultAvatar
                }
                preview={false}
                className={styles.imageWrapper}
              />
              {isBlocked && (
                <div className={styles.blocked}>
                  <Blocked />
                </div>
              )}
            </Col>
            <Col className={styles.nameAndRole}>
              <Row>
                <p className={styles.name}>{name}</p>
              </Row>
              <Row>
                <p className={styles.role}>{getUserRoleTitle(role)}</p>
              </Row>
            </Col>
          </Col>
          {(hasPermission || isMe) && (
            <Col className={styles.buttonWrapper}>
              <Button onClick={onEditClick} className={styles.button} icon={<Edit />} type="text" />
            </Col>
          )}
        </Row>
      </Card>
    </Col>
  );
};

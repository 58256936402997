import { BlockType } from 'a4bd-meta';
import { Col, Form, Input, Row } from 'antd';
import cn from 'classnames';
import React, { FC, useCallback, useState } from 'react';

import { BlockProps } from '~types';
import { getText } from '~utils';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

export const TitleBlock: FC<BlockProps<BlockType.H1>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content } = block;

  const { text } = content;

  const [count, setCount] = useState(getText(text).length);

  const onValuesChange = useCallback(({ text: changedText }: { text: string }) => {
    setCount(getText(changedText).length);
  }, []);

  return (
    <FormBlock
      form={form}
      count={count}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      maxCount={100}
      onValuesChange={onValuesChange}
      initialValues={content}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>Заголовок</div>
        </Col>
        <Col span={24}>
          <Form.Item name="text">
            <TextArea
              autoSize
              rows={1}
              className={cn(styles.input, styles.h1Block)}
              bordered={false}
              placeholder="Добавьте заголовок"
            />
          </Form.Item>
        </Col>
      </Row>
    </FormBlock>
  );
};

export enum ApiPath {
  Auth = 'auth',
  Authors = 'authors',
  Blocks = 'blocks',
  Callback = 'callback',
  CreateUpload = 'create-upload',
  Employees = 'employees',
  Feeds = 'feeds',
  Files = 'files',
  Grid = 'grid',
  GridSchemas = 'grid-schemas',
  Grids = 'grids',
  Login = 'login',
  Logout = 'logout',
  Me = 'me',
  Posts = 'posts',
  PreviewToken = 'preview-token',
  Publish = 'publish',
  Sections = 'sections',
  Settings = 'settings',
  Sso = 'sso',
  Tags = 'tags',
  Unpublish = 'unpublish',
}

export enum PublicApiPath {
  Revalidate = 'api/revalidate',
}

import { createSlice } from '@reduxjs/toolkit';

import { blocksAdapter } from '~adapters';
import { blocksApi } from '~services';

interface State {
  count: number;
  lastRequestId?: string;
  postId?: number;
}

export const blocksSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(blocksApi.endpoints.createBlock.matchFulfilled, (state) => {
        // Мутация может привести к неожиданным ошибкам, поскольку блок возвращается без relations
        // blocksAdapter.upsertOne(state, action.payload);
        state.count += 1;
      })
      .addMatcher(blocksApi.endpoints.updateBlock.matchFulfilled, (state, action) => {
        // Обновляем только корневые блоки
        if (blocksAdapter.getSelectors().selectById(state, action.meta.arg.originalArgs.id)) {
          blocksAdapter.upsertOne(state, action.payload);
        }
      })
      .addMatcher(blocksApi.endpoints.getPostBlocks.matchPending, (state, action) => {
        if (action.meta.arg.originalArgs.postId !== state.postId) {
          blocksAdapter.removeAll(state);
        }
      })
      .addMatcher(blocksApi.endpoints.getPostBlocks.matchFulfilled, (state, action) => {
        blocksAdapter.upsertMany(state, action.payload.results);
        state.postId = action.meta.arg.originalArgs.postId;
      })
      .addMatcher(blocksApi.endpoints.deleteBlock.matchFulfilled, (state, action) => {
        blocksAdapter.removeOne(state, action.meta.arg.originalArgs);
      });
  },
  initialState: blocksAdapter.getInitialState<State>({
    count: 0,
  }),
  name: 'blocks',
  reducers: {},
});

export const blocksReducer = blocksSlice.reducer;

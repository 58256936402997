import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BlockSecure,
  BlockType,
  CreateBlockBodySecure,
  GetManyBlocksResponseSecure,
  UpdateBlockBodySecure,
} from 'a4bd-meta';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export type BlocksFilterParams = PaginationParams &
  SortingParams &
  RelationsParams &
  Partial<
    Pick<BlockSecure<BlockType>, 'createdById' | 'type' | 'updatedAt' | 'updatedById' | 'postId'>
  >;

export const blocksApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createBlock: builder.mutation<BlockSecure<BlockType>, Partial<CreateBlockBodySecure>>({
      invalidatesTags: [{ id: 'List', type: 'Blocks' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Blocks),
      }),
    }),
    deleteBlock: builder.mutation<void, number>({
      invalidatesTags: [{ id: 'List', type: 'Blocks' }],
      query: (id) => ({
        method: 'DELETE',
        url: createUrl(ApiPath.Blocks, id),
      }),
    }),
    getPostBlocks: builder.query<GetManyBlocksResponseSecure, BlocksFilterParams>({
      providesTags: (result) => [
        ...(result?.results?.map((block) => ({ id: block.id, type: 'Blocks' as const })) || []),
        { id: 'List', type: 'Blocks' },
      ],
      query: (params = {}) => ({
        method: 'GET',
        params: {
          limit: 1000,
          order: '+sortOrder,+blocks.sortOrder',
          ...params,
        },
        url: createUrl(ApiPath.Blocks),
      }),
    }),
    updateBlock: builder.mutation<
      BlockSecure<BlockType>,
      Partial<UpdateBlockBodySecure> & { id: number }
    >({
      invalidatesTags: (block) => [
        { id: block?.id, type: 'Blocks' },
        { id: block?.parentId, type: 'Blocks' },
      ],
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Blocks, id),
      }),
    }),
  }),
  reducerPath: 'blocksApi',
  tagTypes: ['Blocks'],
});

export const {
  useCreateBlockMutation,
  useDeleteBlockMutation,
  useGetPostBlocksQuery,
  useUpdateBlockMutation,
} = blocksApi;

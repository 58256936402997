import { Row } from 'antd';
import React from 'react';

import { Button } from '~components';

import styles from './styles.module.scss';

type EmptyBlockProps = {
  onAddPost: () => void;
  slideName: string;
};

export const EmptyBlock: React.FC<EmptyBlockProps> = ({ onAddPost, slideName }) => {
  return (
    <Row gutter={[30, 30]} className={styles.emptyBlock}>
      <div className={styles.title}>{slideName}</div>
      <Button className={styles.button} onClick={onAddPost}>
        Добавить публикацию
      </Button>
    </Row>
  );
};

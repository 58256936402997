import { GridPagination, GridSecure } from 'a4bd-meta';
import { Button, Col, Form, Input, Row, Select, Space, Switch, Typography } from 'antd';
import { always, ifElse, isNil } from 'ramda';
import React, { FC } from 'react';

import { Modal } from '~components';

import styles from './styles.module.scss';

interface Props {
  grid?: Partial<GridSecure>;
  isOpen?: boolean;
  onClose(grid?: Partial<GridSecure>): void;
}

const getModalTitle = ifElse(isNil, always('Добавление сетки'), always('Редактирование сетки'));
const getButtonTitle = ifElse(isNil, always('Добавить'), always('Применить'));

const paginationOptions = [
  {
    label: 'Отсутствует',
    value: GridPagination.None,
  },
  {
    label: 'Внешняя',
    value: GridPagination.Outer,
  },
  {
    label: 'Внутренняя',
    value: GridPagination.Inner,
  },
];

export const GridModal: FC<Props> = ({ grid, isOpen = false, onClose }) => {
  const handleCancel = () => {
    onClose();
  };

  const handleFinish = (values: Partial<GridSecure>) => {
    onClose({ ...grid, ...values });
  };

  console.log('renderGridModal...', { grid });

  return (
    <Modal
      open={isOpen}
      title={getModalTitle(grid?.id)}
      destroyOnClose
      onCancel={handleCancel}
      width={470}
      footer={null}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
    >
      <Form
        layout="vertical"
        name="grid"
        validateTrigger="onSubmit"
        onFinish={handleFinish}
        initialValues={grid ?? { pagination: GridPagination.Outer }}
      >
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Row gutter={[0, 15]}>
              <Col span={24}>
                <Form.Item name="title" label="Название">
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="alias"
                  label="Алиас"
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                  requiredMark
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="pagination" label="Пагинация" labelCol={{ span: 24 }}>
                  <Select size="large" options={paginationOptions} maxTagCount="responsive" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Space size="middle" align="center">
                  <Form.Item name="isDefault" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                  <Typography.Text className={styles.title}>По умолчанию</Typography.Text>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[15, 15]} justify="space-between">
              <Col span={12}>
                <Button type="text" size="large" onClick={handleCancel} block>
                  Отменить
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" htmlType="submit" size="large" block>
                  {getButtonTitle(grid?.id)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

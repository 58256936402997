import { BlockType, FileSecure, FileType, ImageProxyResize } from 'a4bd-meta';
import { Col, Form, Input, Row, Space } from 'antd';
import { prop } from 'ramda';
import React, { FC, useState } from 'react';

import { Close, Plus } from '~assets';
import { Button } from '~components';
import { DropType } from '~constants';
import { useIsMobile } from '~hooks';
import { BlockContent, BlockProps } from '~types';
import { getFileById, isDefined, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

type Gallery = BlockType.Gallery;

export const GalleryBlock: FC<BlockProps<Gallery>> = ({ block, blockIndex, canDelete = false }) => {
  const [form] = Form.useForm<BlockContent<Gallery>>();
  const { content, files } = block;

  const isMobile = useIsMobile();

  const currentImages =
    Form.useWatch<BlockContent<Gallery>['images']>('images', form) ?? content.images;

  const isTheOnly = currentImages.length <= 1;

  const formatValues: FormBlockProps<Gallery>['formatValues'] = (content) => ({
    content,
    fileIds: content.images.map(prop('fileId')).filter(isNotNil),
  });

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[30, 30]}>
        {/* Хедер */}
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Галерея</Col>
          </Row>
        </Col>
        <Col className={styles.formListWrapper}>
          <Form.List name="images">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }, index) => (
                  <Space direction="vertical" className={styles.width285} key={`images-${name}`}>
                    <div className={styles.controlsWrapper}>
                      <div>{name + 1}</div>
                      <Button
                        icon={<Close />}
                        size="small"
                        type="ghost"
                        disabled={isTheOnly}
                        className={styles.removeImageButton}
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    </div>
                    <Form.Item
                      name={[index, 'fileId']}
                      getValueFromEvent={prop('id')}
                      getValueProps={() => ({
                        value: getFileById(currentImages[index]?.fileId, files),
                      })}
                    >
                      <DropZone
                        blockType={block.type}
                        type={DropType.Image}
                        imageOptions={{
                          height: 250,
                          resize: ImageProxyResize.Fit,
                          width: isMobile ? 315 : 285,
                        }}
                      />
                    </Form.Item>

                    <div className={styles.captionWrapper}>
                      <div className={styles.captionTitle}>Подпись к фотографии</div>
                      <div className={styles.caption}>
                        <Form.Item name={[index, 'caption']} className={styles.captionItem}>
                          <Input
                            bordered={false}
                            className={styles.input}
                            placeholder="Добавьте подпись"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Space>
                ))}
                <div className={styles.addNewBlockWrapper}>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                  <div
                    className={styles.addNewBlock}
                    onClick={() => {
                      add({ caption: '', fileId: null }, currentImages.length + 1);
                    }}
                  >
                    <Button className={styles.addNewBlockButton} icon={<Plus />} />
                  </div>
                </div>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </FormBlock>
  );
};

import { isString } from './functional';

export function isDefined<T>(argument: T): argument is Defined<T> & NotNull<T> {
  return argument !== undefined && argument !== null;
}

export const mapQueryIdsToIdArray = (entityIds: string | string[] | number[] = []) =>
  (Array.isArray(entityIds) ? entityIds : [entityIds]).map((entityId) =>
    isString(entityId) ? parseInt(entityId, 10) : entityId,
  );

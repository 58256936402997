import { BlockContentPodcastLinkItem, PodcastLinkType } from 'a4bd-meta';
import { Divider, Tooltip } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, not } from 'ramda';
import { FC, useEffect, useRef, useState } from 'react';

import { Plus } from '~assets';
import CastboxIcon from '~assets/podcasts/castbox.svg';
import GoogleIcon from '~assets/podcasts/google.svg';
import SoundCloudIcon from '~assets/podcasts/soundcloud.svg';
import YaMusicIcon from '~assets/podcasts/ya.svg';

import { Button } from '../Button';
import styles from './styles.module.scss';

const getBlockIcon = cond([
  [equals(PodcastLinkType.Google), always(<GoogleIcon />)],
  [equals(PodcastLinkType.YandexMusic), always(<YaMusicIcon />)],
  [equals(PodcastLinkType.Castbox), always(<CastboxIcon />)],
  [equals(PodcastLinkType.SoundCloud), always(<SoundCloudIcon />)],
]);

interface AddPodcastProps {
  availableBlocks: BlockContentPodcastLinkItem[];
  handleAddBlock: (type: PodcastLinkType) => void;
}

export const AddPodcast: FC<AddPodcastProps> = ({ availableBlocks, handleAddBlock }) => {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = () => setActive(not);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  if (availableBlocks.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Divider orientation="center" className={styles.divider} />
      <div className={styles.buttonWrapper}>
        <Button
          icon={<Plus />}
          onClick={handleClick}
          className={classNames(styles.button, active && styles.hidden)}
        />
      </div>
      {active && (
        <div ref={ref} className={styles.list}>
          {availableBlocks.map(({ title, type }, i) => (
            <Tooltip title={title} key={i}>
              <Button
                className={styles.button}
                icon={getBlockIcon(type)}
                onClick={() => {
                  handleAddBlock(type);
                  handleClick();
                }}
              />
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
};

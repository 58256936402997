import { createAction, createSlice, EntityId } from '@reduxjs/toolkit';
import { Update } from '@reduxjs/toolkit/src/entities/models';
import type { SectionPreviewSecure } from 'a4bd-meta';
import { prop, uniq } from 'ramda';

import { sectionsAdapter } from '~adapters';
import { sectionsApi } from '~services';

interface State {
  changes: EntityId[];
}

export const updateManySections =
  createAction<Array<Update<SectionPreviewSecure>>>('updateManySections');

export const sectionsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(updateManySections, (state, action) => {
        sectionsAdapter.updateMany(state, action.payload);
        state.changes = uniq([...state.changes, ...action.payload.map(prop('id'))]);
      })
      .addMatcher(sectionsApi.endpoints.getSectionsList.matchPending, (state, action) => {
        if (action.meta.arg.originalArgs.offset === 0) {
          sectionsAdapter.removeAll(state);
        }
      })
      .addMatcher(sectionsApi.endpoints.getSectionsList.matchFulfilled, (state, action) => {
        sectionsAdapter.upsertMany(state, action.payload.results);
        state.changes = [];
      })
      .addMatcher(sectionsApi.endpoints.updateSections.matchFulfilled, (state) => {
        state.changes = [];
      });
  },
  initialState: sectionsAdapter.getInitialState<State>({
    changes: [],
  }),
  name: 'sections',
  reducers: {},
});

export const sectionsReducer = sectionsSlice.reducer;

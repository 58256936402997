import { createAction, createSlice, EntityId } from '@reduxjs/toolkit';
import { Update } from '@reduxjs/toolkit/src/entities/models';
import { GridSchemaSecure } from 'a4bd-meta';
import { prop, uniq } from 'ramda';

import { gridAdapter } from '../adapters';
import { gridSchemasApi, sectionsApi } from '../services';

interface State {
  changes: EntityId[];
}

export const updateManyCurrentGridSchemas = createAction<Array<Update<GridSchemaSecure>>>(
  'updateManyCurrentGridSchemas',
);

export const gridSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(updateManyCurrentGridSchemas, (state, action) => {
        gridAdapter.updateMany(state, action.payload);
        state.changes = uniq([...state.changes, ...action.payload.map(prop('id'))]);
      })
      .addMatcher(sectionsApi.endpoints.getSectionGrid.matchFulfilled, (state, action) => {
        gridAdapter.setAll(state, action.payload.results);
        state.changes = [];
      })
      .addMatcher(gridSchemasApi.endpoints.updateGridSchemas.matchFulfilled, (state) => {
        state.changes = [];
      });
  },
  initialState: gridAdapter.getInitialState<State>({
    changes: [],
  }),
  name: 'grid',
  reducers: {},
});

export const gridReducer = gridSlice.reducer;

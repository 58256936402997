import { PostType, SectionPreviewSecure } from 'a4bd-meta';
import { Button, Col, Form, Input, Row, Select, Space, Switch, Typography } from 'antd';
import React, { FC } from 'react';

import { Delete } from '~assets';
import { useGetGridsListQuery } from '~services';
import { getTypeTitle, isActualPostType } from '~utils';

import styles from './styles.module.scss';

type SectionFormProps = {
  form?: any;
  isSubsection?: boolean;
  onChange?: (values: Partial<SectionPreviewSecure>) => void;
  onDelete?: () => void;
  onFinish?: (values: Partial<SectionPreviewSecure>) => void;
  section?: any;
};

const InputColor = (props: any) => {
  const { value } = props;
  return (
    <Input
      {...props}
      maxLength={7}
      size="large"
      style={{ background: `linear-gradient(to right, #fff 50%, ${value} 0%)` }}
    />
  );
};

export const SectionForm: FC<SectionFormProps> = ({
  form,
  isSubsection,
  onChange,
  onDelete,
  onFinish,
  section,
}) => {
  const { data: gridsResponse, isFetching } = useGetGridsListQuery({});
  const grids = gridsResponse?.results ?? [];

  const postTypeOptions = Object.values(PostType)
    .filter(isActualPostType)
    .map((postType) => ({
      label: getTypeTitle(postType),
      value: postType,
    }));

  return (
    <div className={styles.formWrapper}>
      <Form
        layout="vertical"
        name="section"
        form={form}
        validateTrigger="onSubmit"
        onValuesChange={onChange}
        onFinish={onFinish}
        initialValues={
          section ?? {
            isShownInFooter: false,
            isShownInMenu: false,
            postTypes: [],
            subtitle: '',
          }
        }
      >
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Row gutter={[0, 15]}>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label="Раздел"
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                  requiredMark
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="alias"
                  label="Алиас"
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                  requiredMark
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="subtitle"
                  label="Описание"
                  requiredMark
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea size="large" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="gridId"
                  label="Сетка"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                  requiredMark
                >
                  <Select
                    showSearch
                    loading={isFetching}
                    size="large"
                    options={grids}
                    maxTagCount="responsive"
                    fieldNames={{ label: 'title', value: 'id' }}
                  />
                </Form.Item>
              </Col>
              {!isSubsection && (
                <Col span={24}>
                  <Form.Item name="postTypes" label="Типы публикаций" labelCol={{ span: 24 }}>
                    <Select
                      showSearch
                      mode="multiple"
                      size="large"
                      options={postTypeOptions}
                      maxTagCount="responsive"
                    />
                  </Form.Item>
                </Col>
              )}
              {!isSubsection && (
                <Col span={24}>
                  <Row gutter={[15, 15]}>
                    <Col span={12}>
                      <Form.Item
                        name="darkColor"
                        label="Основной цвет"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            message: 'Введите цвет в формате HEX',
                            pattern: /^#([0-9A-F]{3}){1,2}$/i,
                          },
                        ]}
                      >
                        <InputColor />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="lightColor"
                        label="Дополнительный цвет"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            message: 'Введите цвет в формате HEX',
                            pattern: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
                          },
                        ]}
                      >
                        <InputColor />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={12}>
                <Space size="middle" align="center">
                  <Typography.Text className={styles.title}>В главном меню</Typography.Text>
                  <Form.Item name="isShownInMenu" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space size="middle" align="center">
                  <Typography.Text className={styles.title}>В футере</Typography.Text>
                  <Form.Item name="isShownInFooter" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Space>
              </Col>
              {isSubsection && (
                <Col span={24}>
                  <Button onClick={onDelete} className={styles.deleteButton}>
                    <Delete />
                    Удалить
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

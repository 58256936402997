import { Modal as AntdModal, ModalProps, Row } from 'antd';
import React from 'react';

import { Button } from '../Button';
import style from './styles.module.scss';

interface Props extends ModalProps {
  content?: React.ReactNode;
  icon?: React.ReactNode;
  okDanger?: boolean;
  title?: React.ReactNode;
}

export const Confirm: React.FC<Props> = (props) => {
  const {
    cancelText,
    children,
    content,
    icon = null,
    okDanger = false,
    okText,
    onCancel,
    onOk,
    title = 'Удалить блок?',
    ...modalProps
  } = props;

  return (
    <AntdModal {...modalProps} className={style.confirm} footer={null} onCancel={onCancel}>
      <div className={style.wrapper}>
        <Row justify="center" className={style.titleBlock}>
          {icon}
          <p className={style.title}>{title}</p>
        </Row>
        <Row className={style.contentBlock}>{content}</Row>
      </div>
      <div className={style.footer}>
        <Button onClick={onCancel}>{cancelText}</Button>
        <Button type="primary" className={okDanger ? style.danger : undefined} onClick={onOk}>
          {okText}
        </Button>
      </div>
    </AntdModal>
  );
};

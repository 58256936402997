import { BlockType, IncutType } from 'a4bd-meta';
import { Checkbox, Col, Form, Row } from 'antd';
import React, { FC } from 'react';

import { RichEditor } from '~components';
import { BlockProps } from '~types';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

export const IncutBlock: FC<BlockProps<BlockType.Incut>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content } = block;

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      canDelete={canDelete}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Врезка</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[30, 30]}>
            <Col span={24} className={styles.editorWrapper}>
              <Form.Item name="content">
                <RichEditor
                  withColor={false}
                  defaultData={content.content}
                  fontSize={20}
                  lineHeight={24}
                  placeholder="Добавьте врезку текста"
                  inline
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row className={styles.subtitle} justify="space-between">
            <Col>Дополнительно</Col>
          </Row>
          <Form.Item
            name="type"
            noStyle
            getValueFromEvent={({ target: { checked } }) =>
              checked ? IncutType.WithQuote : IncutType.WithoutQuote
            }
            getValueProps={(value) => ({ checked: value === IncutType.WithQuote })}
            valuePropName="checked"
          >
            <Checkbox>Кавычки</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </FormBlock>
  );
};

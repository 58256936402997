export enum GridLayout {
  /** @deprecated */
  ComingSoon = 'comingSoon',
  /** @deprecated */
  ComingSoonRubric = 'comingSoonRubric',
  FullPost = 'fullPost',
  /** @deprecated */
  MainA = 'mainA',
  /** @deprecated */
  MainB = 'mainB',
  MainNews = 'mainNews',
  MainPosts = 'mainPosts',
  MainRubrics = 'mainRubrics',
  MainSlider = 'mainSlider',
  MainTop = 'mainTop',
  QuizesSectionPosts = 'quizesSectionPosts',
  SectionPosts = 'sectionPosts',
  SectionPostsWithDescription = 'sectionPostsWithDescription',
  SectionPostsWithoutGradient = 'sectionPostsWithoutGradient',
  TestsSectionPosts = 'testsSectionPosts',
}

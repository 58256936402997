import { useEffect, useState } from 'react';

const getIsTablet = () => window.innerWidth <= 1280;

export const useIsTablet = (): boolean => {
  const [isTablet, setIsTablet] = useState(getIsTablet());

  useEffect(() => {
    const onResize = () => {
      setIsTablet(getIsTablet());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isTablet;
};

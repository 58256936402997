import React from 'react';

interface SvgFilterProps {
  darkColor: string;
  lightColor: string;
}

export const SvgFilter: React.FC<SvgFilterProps> = ({ darkColor, lightColor }) => {
  if (!darkColor || !lightColor) return <div />;
  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          b: parseInt(result[3], 16) / 255,
          g: parseInt(result[2], 16) / 255,
          r: parseInt(result[1], 16) / 255,
        }
      : null;
  };

  const lightColorDec = hexToRgb(lightColor);
  const darkColorDec = hexToRgb(darkColor);

  return (
    <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
      <filter id="cover_filter">
        <feColorMatrix
          type="matrix"
          result="grayscale"
          values="1 0 0 0 0
                    1 0 0 0 0
                    1 0 0 0 0
                    0 0 0 1 0"
        />

        <feComponentTransfer colorInterpolationFilters="sRGB" result="duotone_magenta_gold">
          <feFuncR type="table" tableValues={`${darkColorDec?.r} ${lightColorDec?.r}`} />
          <feFuncG type="table" tableValues={`${darkColorDec?.g} ${lightColorDec?.g}`} />
          <feFuncB type="table" tableValues={`${darkColorDec?.b} ${lightColorDec?.b}`} />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
      </filter>
    </svg>
  );
};

import { BlockType, ImageFormat, ImageProxyResize, PostType } from 'a4bd-meta';
import { Col, Form, Input, Row, Select } from 'antd';
import { always, cond, equals, prop, T } from 'ramda';
import React, { FC } from 'react';

import ContentWidthImagePreview from '~assets/png/contentWidthImagePreview.png';
import FullscreenImagePreview from '~assets/png/fullScreenImagePreview.png';
import { DropType } from '~constants';
import { useIsMobile, usePost } from '~hooks';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { Option } = Select;

const getBlockCanFormat = cond([
  [equals(BlockType.Image), always(true)],
  [equals(BlockType.Gif), always(false)],
  [T, always(false)],
]);

const getBlockNeedFormat = cond([
  [equals(PostType.Card), always(false)],
  [T, always(true)],
]);

const getBlockDropType = cond([
  [equals(BlockType.Image), always(DropType.Image)],
  [equals(BlockType.Gif), always(DropType.Gif)],
  [T, always(DropType.Image)],
]);

const getBlockTitle = cond([
  [equals(BlockType.Image), always('Фотография')],
  [equals(BlockType.Gif), always('GIF')],
  [T, always(null)],
]);

const getImageFormatOptionsByTitle = cond([
  [equals(PostType.Longread), always([ImageFormat.Fullscreen, ImageFormat.ContentWidth])],
  [equals(PostType.Card), always([ImageFormat.ContentWidth])],
  [T, always([ImageFormat.ContentWidth])],
]);

const getImageFormatTitle = cond([
  [equals(ImageFormat.Fullscreen), always('Фуллскрин')],
  [equals(ImageFormat.ContentWidth), always('По ширине 750px')],
  [T, always(null)],
]);

const getPopoverContent = cond([
  [equals(ImageFormat.Fullscreen), always(<img src={FullscreenImagePreview} alt="" />)],
  [equals(ImageFormat.ContentWidth), always(<img src={ContentWidthImagePreview} alt="" />)],
  [T, always(null)],
]);

export const ImageBlock: FC<BlockProps<BlockType.Image>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { type: postType } = usePost();
  const { content, files, type } = block;

  const isMobile = useIsMobile();

  const formatValues: FormBlockProps<BlockType.Image>['formatValues'] = (content) => ({
    content,
    fileIds: [content.fileId].filter(isNotNil),
  });

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>{getBlockTitle(type)}</div>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="fileId"
            getValueFromEvent={prop('id')}
            getValueProps={() => ({
              value: getFileById(content.fileId, files),
            })}
          >
            <DropZone
              blockType={block.type}
              type={getBlockDropType(type)}
              imageOptions={{
                height: isMobile ? 177 : 350,
                resize: ImageProxyResize.Fill,
                width: isMobile ? 315 : 500,
              }}
            />
          </Form.Item>
        </Col>
        <div className={styles.captionWrapper}>
          <div className={styles.captionTitle}>Подпись к фотографии</div>
          <div className={styles.caption}>
            <Form.Item name="caption" className={styles.captionItem}>
              <Input bordered={false} className={styles.input} placeholder="Добавьте подпись" />
            </Form.Item>
          </div>
        </div>
      </Row>
    </FormBlock>
  );
};

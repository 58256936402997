import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { gridSchemasAdapter } from '~adapters';

import { stateSelector } from './state';

export const gridSchemasEntitySelector = gridSchemasAdapter.getSelectors<RootState>(
  ({ gridSchemas }) => gridSchemas,
);

export const gridSchemasSelector = createDraftSafeSelector(
  stateSelector,
  ({ gridSchemas }) => gridSchemas,
);

export const changesGridSchemasEntitySelector = createDraftSafeSelector(
  gridSchemasSelector,
  ({ changes }) => changes,
);

export const gridSchemaByIdSelector = (id: number) => (state: RootState) =>
  gridSchemasEntitySelector.selectById(state, id);

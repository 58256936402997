import { createApi } from '@reduxjs/toolkit/query/react';
import type { GetManyFeedsQuerySecure, GetManyFeedsResponseSecure } from 'a4bd-meta';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export const feedsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    getFeedsList: builder.query<GetManyFeedsResponseSecure, Partial<GetManyFeedsQuerySecure>>({
      providesTags: () => [{ id: 'List', type: 'Feeds' }],
      query: (params = {}) => ({
        method: 'GET',
        params: {
          order: '-updatedAt',
          ...params,
        },
        url: createUrl(ApiPath.Feeds),
      }),
    }),
  }),
  reducerPath: 'feedsApi',
  tagTypes: ['Feeds'],
});

export const { useGetFeedsListQuery } = feedsApi;

import { DownloadOutlined } from '@ant-design/icons';
import { Col, Progress, Row, Typography } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { AddAudio, AddImage, UploadVideo } from '~assets';
import { Button, Visible } from '~components';
import { DropType } from '~constants';

import styles from './styles.module.scss';

interface Props {
  isError: boolean;
  isProcessing: boolean;
  isSmall: boolean;
  isUploading: boolean;
  onlyIcon?: boolean;
  type: DropType;
  uploadProgress: number;
}

const getPreviewText = cond<[DropType], string>([
  [equals(DropType.Image), always('Перетащите изображение сюда')],
  [equals(DropType.Photo), always('Перетащите фото сюда')],
  [equals(DropType.Gif), always('Перетащите гифку сюда')],
  [equals(DropType.Video), always('Перетащите видеофайл сюда')],
  [equals(DropType.Documents), always('Перетащите документ сюда')],
  [equals(DropType.Audio), always('Перетащите аудиофайл сюда')],
]);

const getErrorText = cond<[DropType], string>([
  [equals(DropType.Image), always('Ошибка загрузки изображения')],
  [equals(DropType.Photo), always('Ошибка загрузки фото')],
  [equals(DropType.Gif), always('Ошибка загрузки гифки')],
  [equals(DropType.Video), always('Ошибка загрузки видео')],
  [equals(DropType.Documents), always('Ошибка загрузки документа')],
  [equals(DropType.Audio), always('Ошибка загрузки аудиофайла')],
]);

const getPreviewIcon = cond<[DropType], JSX.Element>([
  [equals(DropType.Video), always(<UploadVideo />)],
  [equals(DropType.Documents), always(<DownloadOutlined />)],
  [equals(DropType.Audio), always(<AddAudio />)],
  [T, always(<AddImage />)],
]);

export const Preview: React.FC<Props> = ({
  isError,
  isProcessing,
  isSmall,
  isUploading,
  onlyIcon,
  type,
  uploadProgress,
}) => {
  const PreviewIcon = useCallback(() => {
    if (isProcessing || isUploading) {
      return (
        <Progress
          percent={isUploading ? uploadProgress : 100}
          type="circle"
          width={isSmall ? 40 : 64}
        />
      );
    }
    return getPreviewIcon(type);
  }, [isProcessing, isUploading, isSmall, type, uploadProgress]);

  const previewText = useMemo(() => {
    if (isError) {
      return getErrorText(type);
    }
    if (isProcessing) {
      return 'Обработка';
    }
    if (isUploading) {
      return 'Загрузка';
    }
    return getPreviewText(type);
  }, [isError, isProcessing, isUploading, type]);

  const isTextVisible = !isError && !isProcessing && !isUploading && !onlyIcon;

  return (
    <Row className={classNames(styles.previewWrapper)}>
      <Col
        style={{
          height: isSmall ? '40px' : '64px',
          margin: '0 auto',
          width: isSmall ? '40px' : '64px',
        }}
      >
        <PreviewIcon />
      </Col>
      <Visible isVisible={!!previewText && !onlyIcon}>
        <Col span={24} className={styles.previewTextWrapper}>
          <Typography.Text className={styles.previewLabel}>{previewText}</Typography.Text>
        </Col>
      </Visible>

      <Visible isVisible={isTextVisible}>
        <Col span={24}>
          <Row justify="space-around">
            <Typography.Text className={styles.previewSubLabel}>
              или нажмите на кнопку
            </Typography.Text>
          </Row>
        </Col>
      </Visible>

      <Visible isVisible={isTextVisible}>
        <Col span={24} className={styles.previewButtonWrapper}>
          <Row justify="space-around">
            <Button className={styles.addButton}>Добавить файл</Button>
          </Row>
        </Col>
      </Visible>
    </Row>
  );
};

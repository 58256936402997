import { Button, Modal as AntdModal } from 'antd';
import classNames from 'classnames';
import React from 'react';
import Cropper from 'react-cropper';

import { Close, Redo, SizeUp, TurnLeft, TurnRight, Undo } from '~assets';

import styles from './styles.module.scss';
import { FooterProps, ImageEditorProps } from './types';
import { useImageEditor } from './useImageEditor';

const Footer: React.FC<FooterProps> = ({ onCrop, onReset }) => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerControls}>
        <Button className={styles.footerButtonLeft} onClick={onReset}>
          <Close />
        </Button>
        <Button className={styles.footerButtonLeft} style={{ display: 'none' }}>
          <Undo />
        </Button>
        <Button className={styles.footerButtonLeft} style={{ display: 'none' }}>
          <Redo />
        </Button>
      </div>
      <Button className={styles.footerButtonRight} onClick={onCrop}>
        Применить
      </Button>
    </div>
  );
};

export const ImageEditor: React.FC<ImageEditorProps> = (props) => {
  const {
    cropData,
    cropperRef,
    currentCropData,
    isFullscreen,
    isReadyDisabled,
    onClose,
    onCrop,
    onCropperReady,
    onFinish,
    onReset,
    onRotateLeft,
    onRotateRight,
    onSizeUp,
    setCurrentCropData,
    src,
  } = useImageEditor(props);
  return (
    <AntdModal
      open
      closable={false}
      footer={<Footer onReset={onReset} onCrop={onCrop} />}
      className={isFullscreen ? styles.isFullscreen : styles.isNormal}
    >
      <div className={styles.header}>
        <div className={styles.title}>Кадрирование изображения</div>
        <div className={styles.controlsWrapper}>
          <Button disabled={isReadyDisabled} className={styles.readyButton} onClick={onFinish}>
            Готово
          </Button>
          <Button className={styles.closeButton} onClick={onSizeUp} style={{ display: 'none' }}>
            <SizeUp />
          </Button>
          <Button className={styles.closeButton} onClick={onClose}>
            <Close />
          </Button>
        </div>
      </div>
      <div className={styles.cropDataWrapper}>
        {cropData.map(({ icon, props, title }) => {
          return (
            <div className={styles.cropData}>
              <div className={styles.cropDataHeader}>
                <div>{icon}</div>
                <div className={styles.cropDataTitle}>{title}</div>
              </div>

              <div className={styles.cropDataPropsWrapper}>
                <div className={styles.cropDataPropsHugger} />
                <div className={styles.cropDataProps}>
                  {/* eslint-disable-next-line react/destructuring-assignment */}
                  {props.map((cropType) => {
                    const { id, ratio, title } = cropType;
                    const isActive = currentCropData.id === id;
                    const handleClick = () => setCurrentCropData(cropType);

                    return (
                      <button
                        type="button"
                        key={title}
                        className={classNames(
                          styles.cropDataPropsDisplay,
                          isActive && styles.cropDataPropsDisplayActive,
                        )}
                        onClick={handleClick}
                      >
                        <div
                          className={styles.cropDataPropsRatioImage}
                          style={{ aspectRatio: ratio }}
                        />
                        <div className={styles.cropDataPropsRatioTitle}>{title}</div>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.cropperWrapper}>
        <Cropper
          src={src}
          style={{ height: '100%', width: '100%' }}
          guides={false}
          ref={cropperRef}
          zoomable={false}
          ready={onCropperReady}
        />

        <div className={styles.rotateButtons}>
          <Button className={styles.rotateButton} onClick={onRotateLeft}>
            <TurnLeft />
          </Button>
          <Button className={styles.rotateButton} onClick={onRotateRight}>
            <TurnRight />
          </Button>
        </div>
      </div>
    </AntdModal>
  );
};

export enum PostType {
  // ToDo Cleanup
  Article = 'article',
  /** @deprecated */
  Card = 'card',
  Custom = 'custom',
  /** @deprecated */
  Longread = 'longread',
  /** @deprecated */
  News = 'news',
  Podcast = 'podcast',
  /** @deprecated */
  Spec = 'spec',
  Story = 'story',
  /** @deprecated */
  Test = 'test',
  Thing = 'thing',
}

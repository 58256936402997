import { GridSchemaSecure, PostType, Section, SectionPreviewSecure } from 'a4bd-meta';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import { always, ifElse, isNil } from 'ramda';
import React, { FC } from 'react';

import { Modal } from '~components';
import { useGetGridsListQuery } from '~services';
import { getActualSectionsIds, getTypeTitle, isActualPostType } from '~utils';

import styles from './styles.module.scss';

interface Props {
  gridSchema?: Partial<GridSchemaSecure>;
  isOpen?: boolean;

  onClose(gridSchema?: Partial<GridSchemaSecure>): void;

  sections: SectionPreviewSecure<string>[] | [];
}

const getModalTitle = ifElse(
  isNil,
  always('Добавление схемы сетки'),
  always('Редактирование схемы сетки'),
);
const getButtonTitle = ifElse(isNil, always('Добавить'), always('Применить'));

const postTypeOptions = Object.values(PostType)
  .filter(isActualPostType)
  .map((postType) => ({
    id: postType,
    title: getTypeTitle(postType),
  }));

const flags: Record<
  keyof Pick<
    GridSchemaSecure,
    | 'isHidden'
    | 'canHide'
    | 'canChangePosts'
    | 'canChangeOrder'
    | 'canPaginate'
    | 'isFilterByCurrentSection'
  >,
  string
> = {
  canChangeOrder: 'Перемещать на сетке',
  canChangePosts: 'Заменяемые посты',
  canHide: 'Скрывать на сетке',
  canPaginate: 'Пагинируемая',
  isFilterByCurrentSection: 'Фильтр по текущему разделу',
  isHidden: 'Скрыта',
};

export const GridSchemaModal: FC<Props> = ({ gridSchema, isOpen = false, onClose, sections }) => {
  const handleCancel = () => {
    onClose();
  };

  const { data: gridsResponse, isFetching } = useGetGridsListQuery({});
  const grids = gridsResponse?.results ?? [];

  const handleFinish = (values: Partial<GridSchemaSecure>) => {
    onClose({ ...gridSchema, ...values });
  };

  const sectionsOptions = sections.map(({ id, title }) => {
    const activeSectionsIds = getActualSectionsIds(sections);
    const isActive = activeSectionsIds.find((activeSectionId) => activeSectionId === id);

    return {
      disabled: !isActive,
      id,
      title,
    };
  });

  const getSectionsIds = (sections?: Section[]) => {
    if (sections) return sections.map((section) => section.id);
    return [];
  };

  const initialValues = {
    ...gridSchema,
    filterBySectionsIds: getSectionsIds(gridSchema?.filterBySections),
  };

  return (
    <Modal
      open={isOpen}
      title={getModalTitle(gridSchema?.id)}
      destroyOnClose
      onCancel={handleCancel}
      width={470}
      footer={null}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
    >
      <Form
        layout="vertical"
        name="gridSchema"
        validateTrigger="onSubmit"
        onFinish={handleFinish}
        initialValues={initialValues ?? {}}
      >
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Row gutter={[0, 15]}>
              <Col span={24}>
                <Form.Item name="title" label="Название">
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="layout"
                  label="Алиас"
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                  requiredMark
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="gridId"
                  label="Сетка"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      message: 'Обязательно для заполнения',
                      required: true,
                    },
                  ]}
                  requiredMark
                >
                  <Select
                    showSearch
                    loading={isFetching}
                    size="large"
                    options={grids}
                    maxTagCount="responsive"
                    fieldNames={{ label: 'title', value: 'id' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="filterBySectionsIds"
                  label="Отображаемые секции"
                  labelCol={{ span: 24 }}
                >
                  <Select
                    showSearch
                    size="large"
                    options={sectionsOptions}
                    mode="multiple"
                    maxTagCount="responsive"
                    fieldNames={{ label: 'title', value: 'id' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="filterByPostTypes" label="Типы публикаций" labelCol={{ span: 24 }}>
                  <Select
                    showSearch
                    mode="multiple"
                    size="large"
                    options={postTypeOptions}
                    maxTagCount="responsive"
                    fieldNames={{ label: 'title', value: 'id' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="maxPostCount" label="Максимальное количество публикаций">
                  <InputNumber size="large" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              {Object.entries(flags).map(([flag, description], i) => (
                <Col key={i} span={24}>
                  <Space size="middle" align="center">
                    <Form.Item name={flag} valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Typography.Text className={styles.title}>{description}</Typography.Text>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[15, 15]} justify="space-between">
              <Col span={12}>
                <Button type="text" size="large" onClick={handleCancel} block>
                  Отменить
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" htmlType="submit" size="large" block>
                  {getButtonTitle(gridSchema?.id)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

import { SectionPreviewSecure, SectionStatus } from 'a4bd-meta';
import { Form } from 'antd';
import { omit } from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { RoutesPath } from '~constants';
import { useNotification } from '~hooks';
import {
  useCreateSectionMutation,
  useGetSectionQuery,
  useLazyDeleteSectionQuery,
  useUpdateSectionMutation,
} from '~services';
import { updateManySections } from '~slices';

const EmptySubsection = {
  alias: '',
  canChangeStatus: true,
  id: null,
  // Пользователь может создать множество секций без введения данных.
  // Чтобы их нормально итерировать нужен ID, который получить с бэка
  // без данных нельзя
  isShownInFooter: false,
  isShownInMenu: false,
  postTypes: [],
  status: SectionStatus.draft,
  subtitle: '',
  title: '',
} as unknown as SectionPreviewSecure;

const getRandomId = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const useEditPage = () => {
  const { id = '' } = useParams();
  const sectionId = parseInt(id, 10);
  const [form] = Form.useForm();
  const [createSection] = useCreateSectionMutation();
  const [deleteSection] = useLazyDeleteSectionQuery();
  const [updateSection] = useUpdateSectionMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notification = useNotification();
  const [localSubsections, setLocalSubsections] = useState<SectionPreviewSecure[]>([]);

  const {
    data: section,
    error: errorPost,
    isLoading: isSectionLoading,
  } = useGetSectionQuery({ id: sectionId, params: { relations: ['children'] } });

  const isLoading = isSectionLoading;

  useEffect(() => {
    if (section?.children) {
      setLocalSubsections(section?.children as unknown as SectionPreviewSecure[]);
    }
  }, [section?.children]);

  const handleDelete = async () => {
    await deleteSection(sectionId);
    navigate(generatePath(RoutesPath.Sections));
  };

  const handleFinish = async (section: Partial<SectionPreviewSecure>) => {
    localSubsections.map(async (subsection) => {
      if (subsection) {
        const { id, ...data } = subsection;

        if (id && id < 1000000) {
          await updateSection({
            changes: {
              ...omit(['createdAt', 'updatedAt', 'createdById', 'updatedById'], data),
              parentId: sectionId,
            },
            id,
          });
        } else {
          const sectionData = { ...data, parentId: sectionId };
          // @ts-ignore
          delete sectionData.id;
          await createSection(sectionData);
        }
      }
      notification({
        message: 'Сохранено успешно',
        placement: 'top',
      });
      return null;
    });

    await updateSection({ changes: section, id: sectionId });
  };
  const handleCommit = () => {
    form.submit();
  };

  const handleSubsectionAdd = () => {
    const newSubsections = [...localSubsections];
    newSubsections.push({ ...EmptySubsection, id: getRandomId(1000000, 2000000) });
    // @ts-ignore
    setLocalSubsections(newSubsections);
  };

  const handleSubsectionChange = (values: SectionPreviewSecure, subsectionNum: number) => {
    const subsectionToUpdate = localSubsections.find(
      (subsection) => subsection.id === subsectionNum,
    );

    const updatedSubsection = {
      ...subsectionToUpdate,
      ...values,
    };
    const newSubsections = [...localSubsections].map((subsection) => {
      if (subsection.id === subsectionNum) {
        return { ...updatedSubsection };
      }
      return subsection;
    });
    setLocalSubsections(newSubsections);
  };

  const handleSubsectionDelete = async (subsectionNum: number) => {
    let newSubsections = [...localSubsections];
    newSubsections = newSubsections.filter(({ id }) => id !== subsectionNum);
    setLocalSubsections(newSubsections);
    if (subsectionNum < 1000000) {
      await deleteSection(subsectionNum);
    }
    notification({
      message: 'Подсекция удалена',
      placement: 'top',
    });
  };

  const isSubsectionsEmpty = localSubsections.length === 0;

  const goBack = () => navigate(-1);

  return {
    errorPost,
    form,
    goBack,
    handleCommit,
    handleDelete,
    handleFinish,
    handleSubsectionAdd,
    handleSubsectionChange,

    handleSubsectionDelete,

    isLoading,
    isSubsectionsEmpty,
    localSubsections,
    section,
  };
};

import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  CreatePostBodySecure,
  CreatePreviewTokenBodySecure,
  CreatePreviewTokenResponseSecure,
  GetManyPostsQuerySecure,
  GetManyPostsResponseSecure,
  PostSecure,
  PostType,
  PublishPostBodySecure,
  UpdatePostBodySecure,
} from 'a4bd-meta';

import { ApiPath, PublicApiPath } from '~constants';
import { createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export type PostsFilterParams = PaginationParams &
  SortingParams &
  Partial<
    Pick<
      PostSecure<PostType>,
      'createdAt' | 'createdById' | 'publishedAt' | 'status' | 'updatedAt' | 'updatedById'
    >
  > & {
    order?: string;
    sections?: number[];
    type?: PostType[];
  };

export type RevalidateParams = {
  postId: number;
  publicOriginUrl: string;
  secret: string;
};

export const postsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createPost: builder.mutation<PostSecure<PostType>, CreatePostBodySecure>({
      invalidatesTags: [{ id: 'List', type: 'Posts' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Posts),
      }),
    }),
    createPostPreviewToken: builder.mutation<
      CreatePreviewTokenResponseSecure,
      CreatePreviewTokenBodySecure & { id: number }
    >({
      query: ({ id, ...data }) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Posts, id, ApiPath.PreviewToken),
      }),
    }),
    deletePost: builder.mutation<void, number>({
      invalidatesTags: [{ id: 'List', type: 'Posts' }],
      query: (id) => ({
        method: 'DELETE',
        url: createUrl(ApiPath.Posts, id),
      }),
    }),
    dropPostsNextCache: builder.mutation<void, RevalidateParams>({
      query: ({ postId, publicOriginUrl, secret }) => ({
        method: 'GET',
        params: {
          postId,
          secret,
        },
        url: `${publicOriginUrl}/${PublicApiPath.Revalidate}`,
        withAuth: false,
        withCredentials: false,
      }),
    }),
    getListPosts: builder.query<GetManyPostsResponseSecure, Partial<GetManyPostsQuerySecure>>({
      providesTags: (result) => [
        ...(result?.results?.map((post) => ({ id: post.id, type: 'Posts' as const })) || []),
        { id: 'List', type: 'Posts' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          order: '-createdAt',
          ...params,
        },
        url: createUrl(ApiPath.Posts),
      }),
    }),
    getPostById: builder.query<PostSecure<PostType>, RelationsParams & { id: number }>({
      providesTags: (post) => [{ id: post?.id, type: 'Posts' }],
      query: ({ id, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Posts, id),
      }),
    }),
    publishPost: builder.mutation<PostSecure<PostType>, PublishPostBodySecure & { id: number }>({
      invalidatesTags: (post) => [{ id: post?.id, type: 'Posts' }],
      query: ({ id, ...data }) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Posts, id, ApiPath.Publish),
      }),
    }),
    unpublishPost: builder.mutation<PostSecure<PostType>, number>({
      invalidatesTags: (post) => [{ id: post?.id, type: 'Posts' }],
      query: (id) => ({
        method: 'POST',
        url: createUrl(ApiPath.Posts, id, ApiPath.Unpublish),
      }),
    }),
    updatePost: builder.mutation<
      PostSecure<PostType>,
      UpdatePostBodySecure & { id: PostSecure<PostType>['id'] }
    >({
      invalidatesTags: (post) => [{ id: post?.id, type: 'Posts' }],
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Posts, id),
      }),
    }),
  }),
  reducerPath: 'postsApi',
  tagTypes: ['Posts'],
});

export const {
  useCreatePostMutation,
  useCreatePostPreviewTokenMutation,
  useDeletePostMutation,
  useDropPostsNextCacheMutation,
  useGetListPostsQuery,
  useGetPostByIdQuery,
  usePrefetch: usePrefetchPost,
  usePublishPostMutation,
  useUnpublishPostMutation,
  useUpdatePostMutation,
} = postsApi;

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  CreateUploadBodySecure,
  CreateUploadResponseSecure,
  FileImageMeta,
  FileSecure,
} from 'a4bd-meta';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export interface UploadProps {
  onUploadProgress?(progressEvent: ProgressEvent): void;

  url: string;
}

export interface UploadFileProps extends UploadProps {
  contentType: string;
  data: ArrayBuffer;
}

export interface UpdateFileProps {
  id: number;
  meta: FileImageMeta;
}

export interface UploadVideoProps extends UploadProps {
  data: FormData;
}

export const filesApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createUploadFile: builder.mutation<CreateUploadResponseSecure, CreateUploadBodySecure>({
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Files, ApiPath.CreateUpload),
      }),
    }),
    deleteFile: builder.mutation<any, number>({
      query: (id) => ({
        data: {},
        method: 'DELETE',
        url: createUrl(ApiPath.Files, id),
      }),
    }),
    getFileById: builder.mutation<FileSecure, number>({
      query: (id) => ({
        method: 'GET',
        url: createUrl(ApiPath.Files, id),
      }),
    }),
    updateFileById: builder.mutation<FileSecure, UpdateFileProps>({
      query: ({ id, meta }) => {
        return {
          data: { meta },
          method: 'PATCH',
          url: createUrl(ApiPath.Files, id),
        };
      },
    }),
    uploadFile: builder.mutation<unknown, UploadFileProps>({
      query: ({ contentType, data, onUploadProgress, url }) => ({
        contentType,
        data,
        method: 'PUT',
        onUploadProgress,
        uploadFile: true,
        url,
        withAuth: false,
        withCredentials: false,
      }),
    }),
    uploadVideo: builder.mutation<unknown, UploadVideoProps>({
      query: ({ data, onUploadProgress, url }) => ({
        contentType: 'multipart/form-data',
        data,
        method: 'POST',
        onUploadProgress,
        uploadFile: true,
        url,
        withAuth: false,
        withCredentials: false,
      }),
    }),
  }),
  reducerPath: 'filesApi',
});

export const {
  useCreateUploadFileMutation,
  useDeleteFileMutation,
  useGetFileByIdMutation,
  useUpdateFileByIdMutation,
  useUploadFileMutation,
  useUploadVideoMutation,
} = filesApi;

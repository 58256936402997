import { Form, Input } from 'antd';
import React, { useState } from 'react';

import styles from './index.module.scss';

interface PrefixProps {
  backgroundColor: string;
}

const Prefix = ({ backgroundColor }: PrefixProps) => {
  return (
    <div className={styles.prefix}>
      <div
        className={styles.prefixColor}
        style={{
          backgroundColor,
        }}
      />
    </div>
  );
};

interface ColorInputProps {
  formItemName: string;
  value: string;
}

export const ColorInput = ({ formItemName, value }: ColorInputProps) => {
  console.log('ColorInputProps', value);
  return (
    <div className={styles.colorInput}>
      <Form.Item name={formItemName} className={styles.captionItem}>
        <Input maxLength={7} bordered={false} prefix={<Prefix backgroundColor={value} />} />
      </Form.Item>
    </div>
  );
};

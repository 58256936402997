import { PostStatus, PostType } from 'a4bd-meta';
import { Col, Form, FormInstance, message, Modal, Row } from 'antd';
import { formatISO, isValid } from 'date-fns';
import { stringify } from 'query-string';
import { __, applySpec, equals, includes, prop } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { ArrowLeft } from '~assets';
import { Button, Confirm, Visible } from '~components';
import { PublicRoutesPath } from '~constants';
import { useIsMobile, usePost } from '~hooks';
import { ConfigContext } from '~providers';
import {
  useCreatePostPreviewTokenMutation,
  useDeletePostMutation,
  usePublishPostMutation,
  useUnpublishPostMutation,
  useUpdatePostMutation,
} from '~services';
import { isArticle, isSuccessResult } from '~utils';

import styles from './styles.module.scss';

const getUpdateValues = applySpec({
  authorIds: prop('authors'),
  feedIds: prop('feeds'),
  isAffiliateArticle: prop('isAffiliateArticle'),
  isAuthorVisible: prop('isAuthorVisible'),
  isModificationDateVisible: prop('isModificationDateVisible'),
  sectionIds: prop('sections'),
  tagIds: prop('tags'),
  type: prop('type'),
});

const isPostPublished = includes(__, [PostStatus.Published, PostStatus.Postponed]);
const isPostPostponed = equals(PostStatus.Postponed);

type PostControlsProps = {
  form: FormInstance<any>;
};

export const PostControls: FC<PostControlsProps> = ({ form }) => {
  const [isPinned, setPinned] = useState(false);
  const post = usePost();
  const { hasUnpublishedChanges, id: postId, status, type } = post;
  const navigate = useNavigate();
  const { previewUrl } = useContext(ConfigContext);

  const formValues = Form.useWatch([], form);
  const publishedTime = Form.useWatch('publishedAt', form);

  const [updatePost] = useUpdatePostMutation();
  const [deletePost] = useDeletePostMutation();
  const [createPostPreviewToken, { isLoading: isCreatingPostPreviewToken }] =
    useCreatePostPreviewTokenMutation();
  const [publishPost, { isLoading: isPublishing }] = usePublishPostMutation();
  const [unpublishPost] = useUnpublishPostMutation();

  const [value] = useDebounce(formValues, 500);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const canPreview = isArticle(type);
  const isPublished = isPostPublished(status);
  const isPostponed = isPostPostponed(status);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handlePublishClick = () => {
    form
      .validateFields()
      .then((results) => {
        console.log('results', results);
        handleModalOpen();
      })
      .catch((results) => {
        console.log('results', results);

        // Todo Докинуть скролл топ
      });
  };

  const handlePreview = async () => {
    const result = await createPostPreviewToken({ expiresIn: '1h', id: postId });
    if (isSuccessResult(result)) {
      const { token } = result.data;
      window.open(
        `${previewUrl}${generatePath(PublicRoutesPath.PostPreview, { postId })}?${stringify({
          token,
        })}`,
        '_blank',
      );
    } else {
      message.error('Ошибка получения токена');
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      cancelText: 'Отмена',
      content: 'Отменить данное действие будет невозможно.',
      okText: 'Удалить',
      onOk: async () => {
        await deletePost(postId);
        navigate(-1);
      },
      title: 'Удалить материал?',
    });
  };

  const handlePublish = async () => {
    publishPost({
      id: post?.id,
      publishedAt: formatISO(isValid(publishedTime) ? publishedTime : new Date()),
    });
  };

  const handleUnpublishPost = async () => unpublishPost(postId);

  const onCommit = handlePublish;

  useEffect(() => {
    const el = document.querySelector(`.${styles.myElement}`);
    const observer = new IntersectionObserver(
      ([e]) => {
        const pinned = e.intersectionRatio < 1;
        setPinned(pinned);
        e.target.classList.toggle(`${styles.isPinned}`, pinned);
      },
      { threshold: [1] },
    );

    if (el) observer.observe(el);
  }, []);

  const goBack = () => navigate(-1);

  const isMobile = useIsMobile();

  return (
    <>
      <Col span={24} className={styles.myElement}>
        <Row gutter={[0, 25]}>
          <Col span={24}>
            <Row gutter={[10, 10]} justify="space-between">
              <Col>
                <Row gutter={[10, 10]}>
                  <Visible isVisible={isPinned}>
                    <Col>
                      <Button
                        icon={<ArrowLeft />}
                        size="large"
                        type="ghost"
                        onClick={goBack}
                        className={isMobile ? styles.backButton : undefined}
                      >
                        {isMobile ? '' : 'Выйти'}
                      </Button>
                    </Col>
                  </Visible>

                  <Visible isVisible={isPublished}>
                    <Col>
                      <Button
                        size="large"
                        type="primary"
                        onClick={handleUnpublishPost}
                        className={styles.publishButton}
                      >
                        Снять с публикации
                      </Button>
                    </Col>
                  </Visible>

                  <Visible isVisible={!isPublished}>
                    <Col>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={isPublishing}
                        className={styles.publishButton}
                        onClick={handlePublishClick}
                      >
                        Опубликовать
                      </Button>
                    </Col>
                  </Visible>

                  <Col>
                    <Button size="large" onClick={handleDelete}>
                      Удалить
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={[10, 10]} justify={{ md: 'end', xs: 'start' }}>
                  <Visible isVisible={canPreview}>
                    <Col>
                      <Button
                        size="large"
                        type="default"
                        loading={isCreatingPostPreviewToken}
                        onClick={handlePreview}
                      >
                        Предпросмотр
                      </Button>
                    </Col>
                  </Visible>
                  <Visible isVisible={isPublished}>
                    <Col>
                      <Button
                        size="large"
                        type="primary"
                        disabled={!hasUnpublishedChanges || isPostponed}
                        loading={isPublishing}
                        onClick={onCommit}
                      >
                        Применить
                      </Button>
                    </Col>
                  </Visible>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Confirm
        open={isModalOpen}
        onCancel={handleModalClose}
        cancelText="Отменить"
        title="Опубликовать?"
        content="Убедитесь, что заполнены все необходимые блоки."
        okText="Опубликовать"
        onOk={async () => {
          handleModalClose();
          await handlePublish();
        }}
      />
    </>
  );
};

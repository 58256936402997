import { createAction, createSlice, EntityId } from '@reduxjs/toolkit';
import { Update } from '@reduxjs/toolkit/src/entities/models';
import { GridSecure } from 'a4bd-meta';
import { prop, uniq } from 'ramda';

import { gridsAdapter } from '~adapters';
import { gridsApi, gridSchemasApi } from '~services';

interface State {
  changes: EntityId[];
}

export const updateManyGrids = createAction<Array<Update<GridSecure>>>('updateManyGrids');

export const gridsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(updateManyGrids, (state, action) => {
        gridsAdapter.updateMany(state, action.payload);
        state.changes = uniq([...state.changes, ...action.payload.map(prop('id'))]);
      })
      .addMatcher(gridsApi.endpoints.getGridsList.matchPending, (state, action) => {
        if (action.meta.arg.originalArgs.offset === 0) {
          gridsAdapter.removeAll(state);
        }
      })
      .addMatcher(gridsApi.endpoints.getGridsList.matchFulfilled, (state, action) => {
        gridsAdapter.upsertMany(state, action.payload.results);
        state.changes = [];
      })
      .addMatcher(gridsApi.endpoints.getGrid.matchFulfilled, (state, action) => {
        gridsAdapter.upsertOne(state, action.payload);
      })
      .addMatcher(gridSchemasApi.endpoints.updateGridSchemas.matchFulfilled, (state) => {
        state.changes = [];
      });
  },
  initialState: gridsAdapter.getInitialState<State>({
    changes: [],
  }),
  name: 'grids',
  reducers: {},
});

export const gridsReducer = gridsSlice.reducer;

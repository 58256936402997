export enum SectionAlias {
  About = 'about',
  Advertisers = 'advertisers',
  Authors = 'authors',
  ComingSoon = 'comingSoon',
  Contacts = 'contacts',
  Context = 'context',
  Default = 'default',
  Juridical = 'juridical',
  Legacy = 'legacy',
  Main = 'main',
  News = 'news',
  People = 'people',
  Podcasts = 'podcasts',
  Quizes = 'quizes',
  Stories = 'stories',
  Tests = 'tests',
  Things = 'things',
  Trends = 'trends',
}

import { DragEndEvent } from '@dnd-kit/core';
import { GridLayout } from 'a4bd-meta';
import { Row } from 'antd';
import { always, cond, dec, inc, propEq, T } from 'ramda';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gridEntitySelector } from '~selectors';
import { updateManyCurrentGridSchemas } from '~slices';
import { getSortValuesFunc } from '~utils';

import { MainSlider, MainTop } from './components';
import { EmptyGridSchema } from './components/EmptyGridSchema';

const propLayoutEq = (layout: GridLayout) => propEq('layout', layout);

const renderComponent = (Component: React.FC) =>
  function (props: any) {
    return <Component {...props} />;
  };

const renderBlockByLayout = cond([
  [propLayoutEq(GridLayout.MainTop), renderComponent(MainTop)],
  [propLayoutEq(GridLayout.MainSlider), renderComponent(MainSlider)],
  [propLayoutEq(GridLayout.MainPosts), always(null)],
  [propLayoutEq(GridLayout.MainRubrics), always(null)],
  [propLayoutEq(GridLayout.ComingSoon), always(null)],
  [T, renderComponent(EmptyGridSchema)],
]);

export const GridSchemaBlocks = () => {
  const dispatch = useDispatch();

  const gridSchemas = useSelector(gridEntitySelector.selectAll);

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const sort = getSortValuesFunc(gridSchemas);
      const changes = sort(event);

      if (changes.length > 0) {
        dispatch(updateManyCurrentGridSchemas(changes));
      }
    },
    [dispatch, gridSchemas],
  );

  console.log('renderGridSchemaBlocks...', { gridSchemas });

  return (
    <Row justify="center" gutter={[32, 32]} style={{ width: '100%' }}>
      {gridSchemas?.map((gridSchema, index) =>
        renderBlockByLayout({
          ...gridSchema,
          canSortDown: gridSchema.canChangeOrder && gridSchemas.length !== inc(index),
          canSortUp: gridSchema.canChangeOrder && gridSchemas[dec(index)]?.canChangeOrder,
          onChangeOrder: onDragEnd,
        }),
      )}
    </Row>
  );
};

import { BlockType } from 'a4bd-meta';
import { Col, Form, Input, Row } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { BlockProps } from '~types';
import { getText } from '~utils';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

export const SeoTitleBlock: FC<BlockProps<BlockType.SeoTitle>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();

  const { content } = block;
  const { text } = content;

  const [count, setCount] = useState(getText(text).length);

  const onValuesChange = useCallback(({ text: changedText }: { text: string }) => {
    setCount(getText(changedText).length);
  }, []);

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [],
  );

  return (
    <FormBlock
      form={form}
      count={count}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      maxCount={100}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>SEO Title</div>
        </Col>
        <Col span={24}>
          <Form.Item name="text" className={styles.inputRow}>
            <TextArea
              autoSize
              rows={1}
              className={styles.input}
              bordered={false}
              placeholder="Добавьте Seo-title"
            />
          </Form.Item>
        </Col>
      </Row>
    </FormBlock>
  );
};

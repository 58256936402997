import { createDraftSafeSelector, EntityId } from '@reduxjs/toolkit';
import type { Post, PostType } from 'a4bd-meta';
import { prop } from 'ramda';

import { postsAdapter } from '~adapters';

import { stateSelector } from './state';

export const postsEntitySelector = postsAdapter.getSelectors<RootState>(prop('posts'));

export const postsSelector = createDraftSafeSelector(
  stateSelector,
  prop<Post<PostType>[]>('posts'),
);

export const countPostsSelector = createDraftSafeSelector(postsSelector, prop<EntityId[]>('count'));

import { createApi } from '@reduxjs/toolkit/query/react';
import { Setting, SettingQuerySecure, UpdateSettingBodySecure } from 'a4bd-meta';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export const settingsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getSettings: build.query<Setting, SettingQuerySecure>({
      providesTags: [{ type: 'Settings' }],
      query: (params) => ({
        method: 'GET',
        params,
        url: createUrl(ApiPath.Settings),
      }),
    }),
    updateSettings: build.mutation<Setting, UpdateSettingBodySecure>({
      invalidatesTags: [{ type: 'Settings' }],
      query: (data) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Settings),
      }),
    }),
  }),
  reducerPath: 'settingsApi',
  tagTypes: ['Settings'],
});

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;

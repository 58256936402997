import { BlockType, VideoBlockType, VideoFormat } from 'a4bd-meta';
import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import { prop } from 'ramda';
import React, { FC } from 'react';

import { DropType } from '~constants';
import { useIsMobile, usePost } from '~hooks';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

export const VideoBlock: FC<BlockProps<BlockType.Video>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { type: postType } = usePost();
  const { content, files } = block;

  const isMobile = useIsMobile();

  const formatValues: FormBlockProps<BlockType.Video>['formatValues'] = (content) => ({
    content,
    fileIds: [content.fileId].filter(isNotNil),
  });

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={content}
      formatValues={formatValues}
      canDelete={canDelete}
    >
      <Row justify="center" gutter={[20, 20]}>
        <Col span={24}>
          <div className={styles.title}>Видео</div>
          <Form.Item name="type" hidden initialValue={VideoBlockType.Iframe}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="fileId"
            getValueFromEvent={prop('id')}
            getValueProps={() => ({
              value: getFileById(content.fileId, files),
            })}
          >
            <DropZone
              blockType={block.type}
              type={DropType.Video}
              imageOptions={isMobile ? { height: 177, width: 315 } : undefined}
            />
          </Form.Item>
        </Col>
        <div className={styles.captionWrapper}>
          <div className={styles.subtitle}>Подпись к видео</div>
          <div className={styles.caption}>
            <Form.Item name="caption" className={styles.captionItem}>
              <Input bordered={false} className={styles.input} placeholder="Добавьте подпись" />
            </Form.Item>
          </div>
        </div>
        <div className={styles.settingsWrapper}>
          <div className={styles.subtitle}>Выбрать формат видео</div>
          <Row gutter={[25, 25]}>
            <Col span={16}>
              <Form.Item name="format">
                <Radio.Group>
                  <Radio value={VideoFormat.Square}>Квадратное</Radio>
                  <Radio value={VideoFormat.Narrow}>Узкое</Radio>
                  <Radio value={VideoFormat.Wide}>Широкое</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.settingsWrapper}>
          <div className={styles.subtitle}>Дополнительно</div>
          <Row>
            <Form.Item name="cycle" initialValue={false} valuePropName="checked">
              <Checkbox>Зацикленное</Checkbox>
            </Form.Item>
            <Form.Item name="autoplay" initialValue={false} valuePropName="checked">
              <Checkbox>Автовоспроизведение</Checkbox>
            </Form.Item>
            <Form.Item name="mute" initialValue={false} valuePropName="checked">
              <Checkbox>Отключить звук</Checkbox>
            </Form.Item>
          </Row>
        </div>
      </Row>
    </FormBlock>
  );
};

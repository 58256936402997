import { Button } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import { Plus } from '~assets';

import styles from './styles.module.scss';

interface Props {
  onAdd: () => void;
}

export const AddSubsection: FC<Props> = ({ onAdd }) => {
  return (
    <div className={styles.wrapper}>
      <Button className={styles.addSubsectionBlock} onClick={onAdd}>
        <div className={classNames(styles.button)}>
          <Plus />
        </div>
        Добавить подрубрику
      </Button>
    </div>
  );
};

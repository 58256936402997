import { PostPreviewSecure, PostStatus, PostType, SectionAlias } from 'a4bd-meta';
import { format, parseISO } from 'date-fns/fp';
import {
  __,
  always,
  compose,
  cond,
  defaultTo,
  equals,
  head,
  identity,
  ifElse,
  includes,
  not,
  pipe,
  propEq,
  replace,
  T,
} from 'ramda';

import { isString } from './functional';

// ToDo Cleanup
export const isNews = equals(PostType.News);
export const isCards = equals(PostType.Card);
export const isCustom = equals(PostType.Custom);
export const isLongread = equals(PostType.Longread);
export const isSpec = equals(PostType.Spec);
export const isTest = equals(PostType.Test);

export const isArticle = equals(PostType.Article);
export const isStory = equals(PostType.Story);
export const isPodcast = equals(PostType.Podcast);
export const isThing = equals(PostType.Thing);

export const getStatusTitle = cond([
  [equals(PostStatus.Draft), always('Черновик')],
  [equals(PostStatus.Published), always('Опубликован')],
  [equals(PostStatus.Unpublished), always('Снят с публикации')],
  [equals(PostStatus.Archived), always('Архив')],
  [equals(PostStatus.Postponed), always('Ожидает публикации')],
  [T, always('-')],
]);

export const getTypeTitle = cond([
  [isNews, always('Новость')],
  [isLongread, always('Лонгрид')],
  [isSpec, always('Спецпроект')],
  [isCards, always('Карточки')],
  [isTest, always('Тест')],
  [isCustom, always('Инфостраница')],
  [isArticle, always('Статья')],
  [isStory, always('История')],
  [isPodcast, always('Подкаст')],
  [isThing, always('Штука')],
]);

export const isActualPostType = includes(__, [
  PostType.Article,
  PostType.Story,
  PostType.Thing,
  PostType.Podcast,
  PostType.Custom,
]);

export const getText = ifElse(
  isString,
  identity,
  (raw?: IRaw) => head(raw?.blocks || [])?.text || '',
);

export const isPost =
  <T extends PostType>(postType: T) =>
  (post: PostPreviewSecure<PostType>): post is PostPreviewSecure<T> =>
    post.type === postType;

export const getPublishedTime = pipe(parseISO, format('HH:mm'));

export const deleteMarkup = pipe(defaultTo(''), replace(/<[^>]*>?/gm, ''));

export const isNotMain = compose(not, propEq('alias', SectionAlias.Main));

import { createApi } from '@reduxjs/toolkit/query/react';
import z from 'zod';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

const transformNumber = (value: number) => (Number.isNaN(value) ? undefined : value);

export const configSchema = z.object({
  apiUrl: z.string(),
  env: z.string().default('development'),
  imageProxyHost: z.string().default(''),
  previewUrl: z.string(),
  publicOriginUrl: z.string(),
  publicRevalidationToken: z.string().optional(),
  sentryDSN: z.string().optional(),
  sso: z.object({
    clientId: z.string(),
    url: z.string(),
  }),
  upload: z
    .object({
      maxSizeAudio: z.number().transform(transformNumber).default(200),
      maxSizeDocuments: z.number().transform(transformNumber).default(20),
      maxSizeImage: z.number().transform(transformNumber).default(30),
      maxSizeVideo: z.number().transform(transformNumber).default(200),
      timeFileCheck: z.number().transform(transformNumber).default(2),
    })
    .optional()
    .default({}),
});

export type Config = z.infer<typeof configSchema>;

export const configApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    getConfig: builder.query<Config, void>({
      query: () => ({
        method: 'GET',
        url: 'config.json',
        withAuth: false,
      }),
      transformResponse: (response) => configSchema.parse(response),
    }),
  }),
  reducerPath: 'configApi',
});

export const { useGetConfigQuery } = configApi;

import { EmployeeRole } from 'a4bd-meta';
import { Col } from 'antd';
import { not } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Badge, Tabs, TitlePage } from '~components';
import { BadgeColor } from '~constants';
import { EmployeeModal, EmployeesList, MobilePageHeader, PageHeader } from '~containers';
import { useHasPermission } from '~hooks';
import { countEmployeesSelector } from '~selectors';
import { getUserRoleTitle, noop } from '~utils';

import styles from './styles.module.scss';

export const EmployeesPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<{ name?: string; role?: EmployeeRole }>();
  const count = useSelector(countEmployeesSelector);

  const hasPermission = useHasPermission();

  const [isCreationModalOpen, setIsCreationModalOpen] = useState<boolean>(false);
  const [editableEmployee, setEditableEmployee] = useState<number>();

  const toggleModal = () => {
    setIsCreationModalOpen(not);
    setEditableEmployee(undefined);
  };

  const openEditModal = (id: number) => {
    toggleModal();
    setEditableEmployee(id);
  };

  const getTabItems = () => {
    const tabs: { name: string; value: string }[] = [
      {
        name: 'Все',
        value: '',
      },
    ];
    Object.keys(EmployeeRole).forEach((roleKey: keyof typeof EmployeeRole) => {
      tabs.push({
        name: getUserRoleTitle(EmployeeRole[roleKey]),
        value: roleKey,
      });
    });
    return tabs;
  };

  const onSearchName = (name: string) => setSearchTerm({ ...searchTerm, name });
  const onSearchRole = (role: EmployeeRole) => setSearchTerm({ ...searchTerm, role });

  return (
    <div className={styles.pageContentWrapper}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader
          title="Все сотрудники"
          titlePostfix={
            <Badge color={BadgeColor.BlueLight} size="small">
              {count ?? 0}
            </Badge>
          }
          placeholderText="Поиск"
          onCreate={hasPermission ? setIsCreationModalOpen : noop}
          onSearch={onSearchName}
        />
      </Col>
      <Col span={24} className={styles.pageHeader}>
        <PageHeader placeholderText="Поиск по имени и фамилии" onSearch={onSearchName} />
      </Col>
      <Col span={24}>
        <TitlePage
          title="Все сотрудники"
          titlePostfix={
            <Badge color={BadgeColor.BlueLight} size="large">
              {count ?? 0}
            </Badge>
          }
        />
      </Col>
      <div className={styles.tabsWrapper}>
        <Tabs items={getTabItems()} onSelect={onSearchRole} defaultValue={EmployeeRole.chief} />
      </div>
      <EmployeesList onEdit={openEditModal} searchTerm={searchTerm} />
      {editableEmployee && (
        <EmployeeModal onClose={toggleModal} isOpen={isCreationModalOpen} id={editableEmployee} />
      )}
    </div>
  );
};

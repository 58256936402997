import { BlockCompositionType, BlockType, ImageProxyResize } from 'a4bd-meta';
import { Col, Form, Input, Radio, Row } from 'antd';
import { always, cond, equals, prop, T } from 'ramda';
import React, { FC, useEffect, useMemo, useRef } from 'react';

import { HorizontalBanner, VerticalBanner } from '~assets';
import { ColorInput } from '~components';
import { DropType } from '~constants';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

export const CTABlock: FC<BlockProps<BlockType.CallToAction>> = ({
  block,
  blockIndex,
  canDelete = false,
  sections,
}) => {
  const [form] = Form.useForm();
  const { content, files, type } = block;

  const alias = useRef<string | undefined>(sections && sections[0] && sections[0].alias);

  const getColor = (contentColor: string, sectionColor?: string) => {
    if (contentColor) return contentColor;
    if (sectionColor) return sectionColor;
    return '';
  };

  const initialValues = useMemo(
    () => ({
      ...content,
      backgroundColor: getColor(
        content.backgroundColor,
        sections && sections[0] && sections[0].darkColor,
      ),
      buttonColor: getColor(content.buttonColor, sections && sections[0] && sections[0].lightColor),
    }),
    [],
  );

  const formatValues: FormBlockProps<BlockType.CallToAction>['formatValues'] = (content) => ({
    content,
    fileIds: [content.fileId].filter(isNotNil),
  });

  const getBlockDropType = cond([
    [equals(BlockType.Image), always(DropType.Image)],
    [T, always(DropType.Image)],
  ]);

  const buttonColor = form.getFieldValue('buttonColor') || initialValues.buttonColor;
  const backgroundColor = form.getFieldValue('backgroundColor') || initialValues.backgroundColor;

  useEffect(() => {
    if (sections && sections[0] && sections[0]) {
      if (sections[0].alias !== alias.current) {
        console.log('useEffect');
        alias.current = sections[0].alias;

        const buttonColor =
          (sections && sections[0] && sections[0].lightColor) || initialValues.buttonColor;
        const backgroundColor =
          (sections && sections[0] && sections[0].darkColor) || initialValues.backgroundColor;

        form.setFieldsValue({
          backgroundColor,
          buttonColor,
        });
      }
    }
  }, [sections]);

  console.log('getFileById(content.fileId, files)', content.fileId, files);

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={initialValues}
      canDelete={canDelete}
      formatValues={formatValues}
    >
      <Row justify="center" gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>Баннер-ссылка</Col>
          </Row>
        </Col>

        <Row justify="center" gutter={[30, 20]} style={{ width: '100%' }}>
          <Col span={24}>
            <Row gutter={[40, 20]}>
              <Col md={7} xs={24}>
                <Form.Item
                  name="fileId"
                  rules={[{ message: 'Загрузите изображение', required: true }]}
                  getValueFromEvent={prop('id')}
                  getValueProps={() => ({
                    value: getFileById(content.fileId, files),
                  })}
                >
                  <DropZone
                    blockType={block.type}
                    small
                    type={getBlockDropType(type)}
                    imageOptions={{
                      height: 212,
                      resize: ImageProxyResize.Fill,
                      width: 200,
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={17} xs={24}>
                <Row gutter={[0, 20]}>
                  <div className={styles.captionWrapper}>
                    <div className={styles.subtitle}>Заголовок</div>
                    <div className={styles.caption}>
                      <Form.Item name="title" className={styles.captionItem}>
                        <Input
                          bordered={false}
                          className={styles.input}
                          placeholder="ДОБАВЬТЕ ЗАГОЛОВОК"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className={styles.captionWrapper}>
                    <div className={styles.subtitle}>Подзаголовок</div>
                    <div className={styles.caption}>
                      <Form.Item name="subTitle" className={styles.captionItem}>
                        <Input
                          bordered={false}
                          className={styles.input}
                          placeholder="Добавьте подзаголовок"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col md={24} xs={24}>
            <div className={styles.captionWrapper}>
              <div className={styles.subtitle}>Ссылка</div>
              <div className={styles.caption}>
                <Form.Item name="link" className={styles.captionItem}>
                  <Input bordered={false} className={styles.input} placeholder="Добавьте ссылку" />
                </Form.Item>
              </div>
            </div>
          </Col>

          <Col md={24}>
            <Row gutter={[55, 20]}>
              <Col md={10} xs={24}>
                <Row gutter={[20, 20]}>
                  <Col span={12}>
                    <div className={styles.captionWrapper}>
                      <div className={styles.subtitle}>Цвет фона</div>
                      <div className={styles.caption}>
                        <ColorInput formItemName="backgroundColor" value={backgroundColor} />
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.captionWrapper}>
                      <div className={styles.subtitle}>Цвет кнопки</div>
                      <div className={styles.caption}>
                        <ColorInput formItemName="buttonColor" value={buttonColor} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={10} xs={24}>
                <div className={styles.captionWrapper}>
                  <div className={styles.subtitle}>Вид баннера в мобильной версии</div>
                  <div className={styles.checkboxesWrapper}>
                    <Row gutter={[20, 20]}>
                      <Form.Item name="compositionType">
                        <Radio.Group className={styles.radioGroup}>
                          <Radio value={BlockCompositionType.Vertical} className={styles.checkbox}>
                            <div className={styles.checkboxWrapper}>
                              <VerticalBanner />
                            </div>
                          </Radio>
                          <Radio
                            value={BlockCompositionType.Horizontal}
                            className={styles.checkbox}
                          >
                            <div className={styles.checkboxWrapper}>
                              <HorizontalBanner />
                            </div>
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </FormBlock>
  );
};

import pb, { GravityType, ResizeType } from '@bitpatty/imgproxy-url-builder';

import {
  FileImageMeta,
  FileType,
  ImageProxyOptions,
  ImageProxyResize,
  StorageProviderType,
} from '../types';
import { File } from '../types/db/file/File';
import { FileImageVariantType } from '../types/db/file/FileImageVariantType';
import { isFileSupportsImageProxy } from './isFileSupportsImageProxy';

const urlRegex = /(https?:\/\/[^\s]+)/;

const FileImageVariantDefaultResize = {
  [FileImageVariantType.DesktopHead]: {
    width: 1380,
  },
  [FileImageVariantType.DesktopSlider]: {
    width: 1026,
  },
  [FileImageVariantType.DesktopWideCard]: {
    width: 675,
  },
  [FileImageVariantType.DesktopCard]: {
    width: 322,
  },
  [FileImageVariantType.TabletHead]: {
    width: 708,
  },
  [FileImageVariantType.TabletWideCard]: {
    width: 462,
  },
  [FileImageVariantType.TabletCard]: {
    width: 215,
  },
  [FileImageVariantType.Mobile]: {
    width: 335,
  },
};

export function getImageURL<D extends Date>(
  file: File<D, FileType.Image>,
  type: FileImageVariantType,
  options: ImageProxyOptions,
): string {
  if (!isFileSupportsImageProxy(file)) {
    return file.url;
  }

  const { domain, gravity, height, quality, resize, width } = options;
  const result = pb();

  const imageVariant = (file.meta as FileImageMeta)?.variants?.find(
    (variant) => variant.type === type,
  );

  if (gravity) {
    result.gravity({ type: gravity as unknown as GravityType });
  }

  if (quality) {
    result.quality(quality);
  }

  if (imageVariant) {
    result.crop({
      gravity: {
        offset: {
          x: imageVariant.dimensions.x,
          y: imageVariant.dimensions.y,
        },
        type: GravityType.NORTHWEST,
      },
      height: imageVariant.dimensions.height,
      width: imageVariant.dimensions.width,
    });
  } else {
    result.resize({
      type: (resize || ImageProxyResize.Auto) as unknown as ResizeType,
      ...FileImageVariantDefaultResize[type],
    });
  }

  if (width || height) {
    result.resize({
      height,
      type: (resize || ImageProxyResize.Auto) as unknown as ResizeType,
      width,
    });
  }

  const fileUrl = new URL(file.url);

  const parts = fileUrl.pathname.split(':');

  let url = result
    .build({
      baseUrl: domain,
      path: [file.provider === StorageProviderType.S3 ? 's3:' : '', parts[1] || parts[0]]
        .filter(Boolean)
        .join('/'),
      plain: true,
    })
    .replace('/-/', '/unsafe/');

  if (!url.match(urlRegex)) {
    url = `https://${url}`;
  } else if (url.startsWith('http:')) {
    url = url.replace('http:', 'https:');
  }

  return url;
}

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { BlockType, ImageProxyResize, TestAnswerType } from 'a4bd-meta';
import { Button, Checkbox, Col, Form, Input, Radio, Row, Space } from 'antd';
import cn from 'classnames';
import { compose, dec, equals, inc, not, prop, propEq } from 'ramda';
import React, { FC, useState } from 'react';

import { Visible } from '~components';
import { DropType } from '~constants';
import { useIsMobile, usePost } from '~hooks';
import { BlockContent, BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

export const TestQuestionBlock: FC<BlockProps<BlockType.TestQuestion>> = ({
  block,
  blockIndex,
  canDelete,
}) => {
  const { content, files, id } = block;
  const { blocks } = usePost();

  const isMobile = useIsMobile();

  const [lastAdded, setLastAdded] = useState(-1);

  const questionIndex = blocks
    .filter(propEq('type', BlockType.TestQuestion))
    .findIndex(propEq('id', id));

  const [form] = Form.useForm();
  const answerType =
    Form.useWatch<BlockContent<BlockType.TestQuestion>['answerType']>('answerType', form) ??
    content.answerType;
  const answerOptions =
    Form.useWatch<BlockContent<BlockType.TestQuestion>['answerOptions']>('answerOptions', form) ??
    content.answerOptions;

  const formatValues: FormBlockProps<BlockType.TestQuestion>['formatValues'] = (content) => ({
    content,
    fileIds: [content.fileId, ...content.answerOptions.map(prop('fileId'))].filter(isNotNil),
  });

  const setRightAnswer = (rightIndex: number) => {
    answerOptions
      .filter(compose(not, equals(rightIndex), (_, index) => index))
      .forEach((_, index) => {
        form.setFieldValue(['answerOptions', index, 'isRightAnswer'], false);
      });
  };

  return (
    <FormBlock
      form={form}
      block={block}
      blockIndex={blockIndex}
      initialValues={content}
      canDelete={canDelete}
      formatValues={formatValues}
    >
      <Row gutter={[0, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col>{`Вопрос ${inc(questionIndex)}`}</Col>
            <Col>
              <Form.Item name="hideRightAnswer" valuePropName="checked" noStyle>
                <Checkbox>Не показывать правильный ответ</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col md={12} xs={24}>
              <Row gutter={[30, 30]}>
                <Col span={24}>
                  <Row className={styles.title}>
                    <Col>Картинка к вопросу</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="fileId"
                    getValueFromEvent={prop('id')}
                    getValueProps={() => ({
                      value: getFileById(content.fileId, files),
                    })}
                  >
                    <DropZone
                      blockType={block.type}
                      type={DropType.Image}
                      imageOptions={{
                        height: isMobile ? 177 : 240,
                        resize: ImageProxyResize.Fit,
                        width: isMobile ? 315 : 420,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="coverCaption" className={styles.inputRow}>
                    <TextArea
                      bordered={false}
                      placeholder="Подпись"
                      className={styles.input}
                      autoSize
                      rows={1}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row className={styles.title}>
                    <Col>Вопрос</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item name="question" className={styles.inputRow}>
                    <TextArea
                      autoSize
                      rows={1}
                      className={styles.input}
                      bordered={false}
                      placeholder="Добавьте вопрос"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row className={styles.title} justify="space-between">
                    <Col>Ответы</Col>
                    <Col>
                      <Form.Item name="answerType">
                        <Radio.Group>
                          <Radio.Button
                            className={cn(
                              styles.type,
                              answerType === TestAnswerType.Text && styles.selected,
                            )}
                            value={TestAnswerType.Text}
                          >
                            Текст
                          </Radio.Button>
                          <Radio.Button
                            className={cn(
                              styles.type,
                              answerType === TestAnswerType.Image && styles.selected,
                            )}
                            value={TestAnswerType.Image}
                          >
                            Изображение
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.List name="answerOptions">
                    {(fields, { add, move, remove }) => (
                      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        {fields.map(({ key, name }, index) => {
                          const isFirst = index === 0;
                          const isLast = index === fields.length - 1;
                          const isOnly = fields.length === 1;

                          const onKeyPress = (event: any) => {
                            if (event.charCode === 13) {
                              add(
                                {
                                  fileId: null,
                                  isRightAnswer: false,
                                  text: '',
                                },
                                index + 1,
                              );
                              setLastAdded(index + 1);
                              return true;
                            }
                            return true;
                          };

                          return (
                            <Space size={16} direction="vertical" style={{ display: 'flex' }}>
                              <Visible isVisible={answerType === TestAnswerType.Image}>
                                <Form.Item
                                  key={key}
                                  name={[name, 'fileId']}
                                  getValueFromEvent={prop('id')}
                                  getValueProps={() => ({
                                    value: getFileById(answerOptions[name]?.fileId, files),
                                  })}
                                >
                                  <DropZone
                                    blockType={block.type}
                                    type={DropType.Image}
                                    imageOptions={{
                                      height: isMobile ? 177 : 240,
                                      resize: ImageProxyResize.Fit,
                                      width: isMobile ? 315 : 420,
                                    }}
                                  />
                                </Form.Item>
                              </Visible>
                              <Row gutter={16} align="middle" wrap={false}>
                                <Col>
                                  <Space size={0}>
                                    <Button
                                      type="text"
                                      disabled={isFirst || isOnly}
                                      icon={<ArrowUpOutlined className={styles.icon} />}
                                      onClick={() => {
                                        move(index, dec(index));
                                      }}
                                    />
                                    <Button
                                      type="text"
                                      disabled={isLast || isOnly}
                                      icon={<ArrowDownOutlined className={styles.icon} />}
                                      onClick={() => {
                                        move(index, inc(index));
                                      }}
                                    />
                                  </Space>
                                </Col>
                                <Col flex="auto" className={styles.option}>
                                  <Row align="middle">
                                    <Col span={24}>
                                      <Form.Item name={[name, 'text']}>
                                        <Input
                                          autoFocus={index === lastAdded}
                                          className={styles.answer}
                                          prefix={
                                            <Form.Item
                                              name={[name, 'isRightAnswer']}
                                              valuePropName="checked"
                                              noStyle
                                            >
                                              <Radio
                                                onClick={() => {
                                                  setRightAnswer(name);
                                                }}
                                              />
                                            </Form.Item>
                                          }
                                          placeholder="Ответ"
                                          bordered={false}
                                          onKeyPress={onKeyPress}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Button
                                    type="text"
                                    icon={<DeleteOutlined className={styles.icon} />}
                                    onClick={() => {
                                      remove(name);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Space>
                          );
                        })}
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            add({
                              fileId: null,
                              isRightAnswer: false,
                              text: '',
                            });
                          }}
                        >
                          Добавить ответ
                        </Button>
                      </Space>
                    )}
                  </Form.List>
                </Col>
                <Col span={24}>
                  <Form.Item name="rightAnswerCaption" className={styles.inputRow}>
                    <TextArea
                      autoSize
                      rows={1}
                      className={styles.input}
                      bordered={false}
                      placeholder="Подпись к правильному ответу"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="wrongAnswerCaption" className={styles.inputRow}>
                    <TextArea
                      autoSize
                      rows={1}
                      className={styles.input}
                      bordered={false}
                      placeholder="Подпись к неправильному ответу"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};

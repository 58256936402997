import { createDraftSafeSelector } from '@reduxjs/toolkit';
import type { TagPreviewSecure } from 'a4bd-meta';
import { prop } from 'ramda';

import { tagsAdapter } from '~adapters';
import { isDefined } from '~utils';

import { stateSelector } from './state';

export const tagsEntitySelector = tagsAdapter.getSelectors<RootState>(prop('tags'));

export const tagsSelector = createDraftSafeSelector(
  stateSelector,
  prop<TagPreviewSecure[]>('tags'),
);

export const tagByIdSelector = (id?: number) => (state: RootState) =>
  isDefined(id) ? tagsEntitySelector.selectById(state, id) : undefined;

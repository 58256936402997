import { FC, useContext, useEffect } from 'react';

import { ConfigContext } from '~providers';

export const OAuthPage: FC = () => {
  const {
    sso: { clientId, url },
  } = useContext(ConfigContext);

  useEffect(() => {
    if (clientId && url) {
      window.location.href = `${url}/oauth/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURI(
        `${window.location.origin}/auth/callback`,
      )}`;
    }
  }, [clientId, url]);

  return null;
};

import { Col, Form, Input, Row } from 'antd';
import { EditorState } from 'draft-js';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import styles from '../../../../containers/Blocks/SeoTitleBlock/styles.module.scss';
import { getTooltip, getTooltipString } from '../../helpers/getTooltip';

const { TextArea } = Input;

type TooltipFormProps = {
  editorState: EditorState;
  onSubmit: Dispatch<SetStateAction<EditorState>>;
  setParentOpen: Dispatch<SetStateAction<boolean>>;
};

export const TooltipForm: FC<TooltipFormProps> = ({ editorState, onSubmit, setParentOpen }) => {
  const [form] = Form.useForm();
  const { getFieldsValue } = form;

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();
    if (formValues.body) {
      onSubmit(getTooltip(editorState, formValues.body));
      setParentOpen(false);
    }
  }, [getFieldsValue, editorState]);

  const initialValues = {
    body: getTooltipString(editorState),
  };

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') handleFinish();
  }, []);

  return (
    <Form
      name="tooltip"
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
      onKeyPress={handleKeyPress}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Form.Item name="body" className={styles.inputRow}>
            <TextArea autoSize rows={1} bordered={false} placeholder="Введите тултип" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

import { Switch as AntdSwitch, SwitchProps } from 'antd';
import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = SwitchProps & {
  className?: string;
  isChecked: boolean;
  prefix?: string;
  setIsChecked: () => void;
};

export const Switch = React.forwardRef<HTMLButtonElement, Props>((props) => {
  const { className, isChecked, prefix, setIsChecked } = props;

  return (
    <div className={cn(styles.switchWrapper, className)}>
      <span className={styles.prefix}>{prefix}</span>

      <AntdSwitch
        checked={isChecked}
        onChange={setIsChecked}
        className={cn(styles.switch, isChecked && styles.checked)}
      />
    </div>
  );
});

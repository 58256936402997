import { createApi } from '@reduxjs/toolkit/query/react';
import type { Employee, LoginBodySecure, TokensResponseSecure } from 'a4bd-meta';

import { ApiPath } from '~constants';
import { createQuery, createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export const usersApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getCurrentUser: build.query<Employee, void>({
      query: createQuery(ApiPath.Auth, ApiPath.Me),
    }),
    loginUser: build.mutation<TokensResponseSecure, LoginBodySecure>({
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Auth, ApiPath.Login),
      }),
    }),
    logoutUser: build.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: createUrl(ApiPath.Auth, ApiPath.Logout),
      }),
    }),
    oAuthCallback: build.mutation<{ accessToken: string }, { code: string; redirectUri: string }>({
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
          redirect_uri: params?.redirectUri,
        },
        url: createUrl(ApiPath.Auth, ApiPath.Sso, ApiPath.Callback),
      }),
    }),
  }),
  reducerPath: 'usersApi',
});

export const {
  useGetCurrentUserQuery,
  useLoginUserMutation,
  useLogoutUserMutation,
  useOAuthCallbackMutation,
} = usersApi;

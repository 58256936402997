import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Tabs } from '~components';
import { InfoPagesTypes, RoutesPath, TYPE_SETTINGS } from '~constants';
import { getSettingsTypeFilterTitle } from '~utils';

import styles from './styles.module.scss';

const settingsFilterItems = TYPE_SETTINGS.map((key) => ({
  name: getSettingsTypeFilterTitle(key),
  value: key,
}));

export const SettingsFilter: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [type, setType] = useState(searchParams.get('type') || InfoPagesTypes.InfoPages);

  useEffect(() => {
    navigate(`${RoutesPath.Settings}?type=${type}`);
  }, [navigate, type]);

  return (
    <Row gutter={[0, 32]}>
      <Col span={24} className={styles.tabsWrapper}>
        <Tabs items={settingsFilterItems} onSelect={setType} defaultValue={type} />
      </Col>
    </Row>
  );
};

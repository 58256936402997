import { PostType } from 'a4bd-meta';
import { ComponentType } from 'react';

import { RoutesPath } from '~constants';
import {
  EmployeesPage,
  GridSchemaPage,
  GridSchemasPage,
  GridsPage,
  OAuthCallback,
  OAuthPage,
  PostEditPage,
  PostsPage,
  SectionCreatePage,
  SectionEditPage,
  SectionsPage,
  SettingsPage,
} from '~pages';

type RouteType = {
  component: ComponentType;
  isPublic?: boolean;
  path: string;
  props?: Record<string, any>;
};

export const routes: Array<RouteType> = [
  {
    component: OAuthCallback,
    isPublic: true,
    path: RoutesPath.AuthCallback,
  },
  {
    component: OAuthPage,
    isPublic: true,
    path: RoutesPath.Login,
  },
  {
    component: PostsPage,
    path: RoutesPath.Posts,
  },
  {
    component: PostsPage,
    path: RoutesPath.Articles,
    props: {
      type: PostType.Article,
    },
  },
  {
    component: PostsPage,
    path: RoutesPath.Stories,
    props: {
      type: PostType.Story,
    },
  },
  {
    component: PostsPage,
    path: RoutesPath.Podcasts,
    props: {
      type: PostType.Podcast,
    },
  },
  {
    component: PostsPage,
    path: RoutesPath.Things,
    props: {
      type: PostType.Thing,
    },
  },
  {
    component: PostEditPage,
    path: RoutesPath.PostEdit,
  },
  {
    component: EmployeesPage,
    path: RoutesPath.Catalogs,
  },
  {
    component: EmployeesPage,
    path: RoutesPath.Employees,
  },
  {
    component: GridSchemaPage,
    path: RoutesPath.GridSchema,
  },
  {
    component: SettingsPage,
    path: RoutesPath.Settings,
  },
  {
    component: SectionCreatePage,
    path: RoutesPath.SectionCreate,
  },
  {
    component: SectionEditPage,
    path: RoutesPath.SectionEdit,
  },
  {
    component: SectionsPage,
    path: RoutesPath.Sections,
  },
  {
    component: GridSchemasPage,
    path: RoutesPath.GridSchemas,
  },
  {
    component: GridsPage,
    path: RoutesPath.Grids,
  },
  // {
  //   component: TagsPage,
  //   path: RoutesPath.Tags,
  // },
  // {
  //   component: EmployeeEditPage,
  //   path: RoutesPath.EmployeeEdit,
  // },
  // {
  //   component: AuthorsPage,
  //   path: RoutesPath.Authors,
  // },
];

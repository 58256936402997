import { AuthorSecure, getFileURL, ImageProxyResize } from 'a4bd-meta';
import { Button, Card, Col, Image, message, Modal, Row, Typography } from 'antd';
import React, { useCallback, useContext } from 'react';

import { Delete, Edit } from '~assets';
import { useHasPermission, useIsMe } from '~hooks';
import { ConfigContext } from '~providers';
import { useDeleteAuthorMutation } from '~services';

import styles from './styles.module.scss';

const { confirm } = Modal;

interface Props {
  item: AuthorSecure;
  onEdit?(id: number): void;
}

const bodyStyle: React.CSSProperties = {
  height: '100%',
  padding: 0,
};

export const AuthorCard: React.FC<Props> = (props) => {
  const { item, onEdit } = props;
  const { avatar, employee, id, name, postsCount, status } = item;
  const isBlocked = status === 'blocked';

  const hasPermission = useHasPermission();
  const isMe = useIsMe(employee?.id);

  const [deleteAuthor] = useDeleteAuthorMutation();
  const { imageProxyHost } = useContext(ConfigContext);

  const onEditClick = () => {
    if (!isBlocked && onEdit) {
      onEdit(id);
    }
  };

  const onDeleteClick = useCallback(async () => {
    if (!isBlocked) {
      confirm({
        bodyStyle: { textAlign: 'center' },
        cancelText: 'Отменить',
        centered: true,
        closable: true,
        icon: null,
        okText: 'Удалить',
        onOk: async () => {
          const data = await deleteAuthor(id);

          // @ts-ignore
          if (data.error) {
            message.error('Ошибка удаления');
          } else {
            message.success('Автор удален');
          }
        },
        title: <Typography.Text strong>Удалить автора?</Typography.Text>,
      });
    }
  }, [deleteAuthor, id, isBlocked]);

  return (
    <Col xs={24} md={12}>
      <Card hoverable={!isBlocked && hasPermission} className={styles.card} bodyStyle={bodyStyle}>
        <Row gutter={[15, 5]} justify="space-between">
          <Col>
            <Image
              src={
                avatar
                  ? getFileURL(avatar, {
                      domain: imageProxyHost,
                      height: 60,
                      resize: ImageProxyResize.Fill,
                      width: 60,
                    })
                  : undefined
              }
              preview={false}
              className={styles.imageWrapper}
            />
          </Col>
          <Col md={18} xs={14}>
            <Typography.Text className={styles.name}>{name}</Typography.Text>
            <Row gutter={[5, 5]}>
              <Col>
                <p className={styles.info}>Публикаций:</p>
              </Col>
              <Col>
                <p>{postsCount}</p>
              </Col>
            </Row>
            {employee && (
              <Row
                gutter={[
                  { md: 5, xs: 0 },
                  { md: 5, xs: 0 },
                ]}
              >
                <Col>
                  <p className={styles.info}>Пользователь:</p>
                </Col>
                <Col>
                  <p>{employee?.name}</p>
                </Col>
              </Row>
            )}
          </Col>
          {(hasPermission || isMe) && (
            <Col md={2} xs={3}>
              <Row>
                <Col>
                  <Button disabled={isBlocked} icon={<Edit onClick={onEditClick} />} type="text" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button icon={<Delete onClick={onDeleteClick} />} type="text" />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Card>
    </Col>
  );
};

import { FileImageVariant, FileImageVariantType } from 'a4bd-meta';

import { cropData, Desktop } from './constants';

export const getCropDataById = (requestedId: number) => {
  let data = Desktop[0];
  cropData.forEach(({ props }) => {
    props.forEach((propData) => {
      if (propData.id === requestedId) data = propData;
    });
  });
  return data;
};

export const calcDimensionsForServer = (cropper: {
  getCropBoxData: () => { height: number; left: number; top: number; width: number };
  getImageData: () => { height: number; naturalHeight: number; naturalWidth: number };
}) => {
  const { height, left, top, width } = cropper.getCropBoxData();
  const { height: imageHeight, naturalHeight } = cropper.getImageData();

  const proportions = naturalHeight / imageHeight;

  const scaledHeight = height * proportions;
  const scaledWidth = width * proportions;
  const scaledLeft = left * proportions;
  const scaledTop = top * proportions;

  return {
    height: scaledHeight,
    width: scaledWidth,
    x: scaledLeft,
    y: scaledTop,
  };
};

export const getUpdatedVariants = (
  variants: FileImageVariant[],
  data: { type: FileImageVariantType },
  currentVariant: FileImageVariant,
) => {
  let result = [...variants];

  const isVariantExist = variants.find((variant) => {
    return variant.type === data?.type;
  });

  if (isVariantExist) {
    result = variants.map((variant) => {
      if (variant.type === data?.type) return currentVariant;
      return variant;
    });
  } else {
    result.push(currentVariant);
  }

  return result;
};

export const calcDimensionsForCropper = (
  dimensions: { height: number; width: number; x: number; y: number },
  cropper: { getImageData: () => { height: number; naturalHeight: number } },
) => {
  const { height, width, x, y } = dimensions;

  const { height: imageHeight, naturalHeight } = cropper.getImageData();

  const proportions = naturalHeight / imageHeight;

  const scaledHeight = height / proportions;
  const scaledWidth = width / proportions;
  const scaledLeft = x / proportions;
  const scaledTop = y / proportions;
  return {
    height: scaledHeight,
    left: scaledLeft,
    top: scaledTop,
    width: scaledWidth,
  };
};

export const getDimensionsByType = (
  variants: FileImageVariant[],
  requiredType: FileImageVariantType,
) => {
  let result;
  variants.forEach(({ dimensions, type }) => {
    if (type === requiredType) result = dimensions;
  });
  return result;
};

import { PostPreviewSecure, PostType } from 'a4bd-meta';
import { Col, List, message, Row, Space, Typography } from 'antd';
import { stringify } from 'query-string';
import React, { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Edit, Eye, Social } from '~assets';
import { Badge, Button, Cover } from '~components';
import { BadgeColor, PublicRoutesPath, RoutesPath } from '~constants';
import { ConfigContext } from '~providers';
import { useCreatePostPreviewTokenMutation } from '~services';
import {
  deleteMarkup,
  formatDate,
  getStatusIcon,
  getStatusTitle,
  getTypeTitle,
  isArticle,
  isNews,
  isSuccessResult,
} from '~utils';

import styles from './styles.module.scss';

interface Props<T extends PostType = PostType> {
  post: PostPreviewSecure<T>;
}

const displayedFormatDate = 'dd MMMM, HH:mm';

export const PostCardInline: React.FC<Props> = ({ post }) => {
  const [createPostPreviewToken, { isLoading: isCreatingPostPreviewToken }] =
    useCreatePostPreviewTokenMutation();
  const { previewUrl } = useContext(ConfigContext);
  const navigate = useNavigate();

  const { createdAt, id, preview, publishedAt, title, type, updatedAt } = post;

  const onEdit = () => {
    navigate(generatePath(RoutesPath.PostEdit, { id }));
  };

  const handlePreview = async () => {
    const result = await createPostPreviewToken({ expiresIn: '1h', id });
    if (isSuccessResult(result)) {
      const { token } = result.data;
      window.open(
        `${previewUrl}${generatePath(PublicRoutesPath.PostPreview, { postId: id })}?${stringify({
          token,
        })}`,
        '_blank',
      );
    } else {
      message.error('Ошибка получения токена');
    }
  };

  const canPreview = isArticle(type);

  const item = {
    avatar: 'https://joeschmoe.io/api/v1/random',
    content:
      'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
    description:
      'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    href: 'https://ant.design',
    title: `ant design part`,
  };

  return (
    <List.Item
      key={item.title}
      className={styles.listItem}
      extra={
        !isNews(type) && (
          <Cover
            src={preview?.imageURLs?.desktopCard}
            width="245px"
            height="140px"
            objectFit="cover"
          />
        )
      }
    >
      <div className={styles.inlineCard}>
        <Col className={styles.leftPart}>
          <Col>
            <Row gutter={[0, 24]} className={styles.content}>
              <Col span={24}>
                <Col span={24}>
                  <Typography.Title level={5} className={styles.title}>
                    {deleteMarkup(title?.text)}
                  </Typography.Title>
                </Col>
              </Col>
            </Row>
          </Col>

          <Badge className={styles.type} color={BadgeColor.Blue}>
            {getTypeTitle(post.type)}
          </Badge>

          <Row className={styles.datesWrapper}>
            <Row justify="space-between">
              <Typography.Text className={styles.date}>
                {`Создано ${formatDate(createdAt, displayedFormatDate)}`}
              </Typography.Text>
            </Row>
            {publishedAt && (
              <Row justify="space-between">
                <Typography.Text className={styles.date}>
                  {`Опубликовано ${formatDate(publishedAt, displayedFormatDate)}`}
                </Typography.Text>
              </Row>
            )}
            <Row justify="space-between">
              <Typography.Text className={styles.date}>
                {`Изменено ${formatDate(updatedAt, displayedFormatDate)}`}
              </Typography.Text>
            </Row>
          </Row>
        </Col>

        <Col className={styles.rightPart}>
          <Col className={styles.editButtonWrapper}>
            <Space>
              <Button icon={<Edit />} onClick={onEdit} className={styles.squareButton} />
              <Button
                icon={<Eye />}
                disabled={!canPreview}
                iconFillType="stroke"
                loading={isCreatingPostPreviewToken}
                className={styles.squareButton}
                onClick={handlePreview}
              />
              <Button className={styles.squareButton} icon={<Social />} />
            </Space>
          </Col>

          <Badge className={styles.status} color={BadgeColor.BlueLight}>
            {getStatusIcon(post.status)}
            {getStatusTitle(post.status)}
          </Badge>
        </Col>
      </div>
    </List.Item>
  );
};

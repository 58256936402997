import { FileSecure, getFileURL, ImageProxyOptions } from 'a4bd-meta';
import { Typography } from 'antd';
import classNames from 'classnames';
import React, { useContext } from 'react';

import { DropType } from '~constants';
import { ConfigContext } from '~providers';

import styles from './styles.module.scss';

interface ItemProps {
  className?: string;
  file: FileSecure;
  imageOptions?: Partial<ImageProxyOptions>;

  onError?(): void;

  type: DropType;
}

export const MediaPreview: React.FC<ItemProps> = (props) => {
  const { className, file, imageOptions, onError, type } = props;

  const { imageProxyHost } = useContext(ConfigContext);
  const src = getFileURL(file, { ...imageOptions, domain: imageProxyHost });
  // Url приходит из Yandex облака
  // const src = file?.url;

  switch (type) {
    case DropType.Image:
    case DropType.Gif:
    case DropType.Photo:
      return (
        <img
          alt={src}
          src={src}
          className={classNames(styles.image, className)}
          width={imageOptions?.width}
          height={imageOptions?.height}
          onError={onError}
        />
      );
    case DropType.Video:
      return <iframe title={file.name} width="100%" height={236} src={file.url} />;
    case DropType.Audio:
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls src={file?.url} className={styles.audioWrapper} />
      );
    case DropType.Documents:
      return <Typography.Text strong>{file.name}</Typography.Text>;
    default:
      return null;
  }
};

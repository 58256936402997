import { ImageProxyOptions } from '../types/common/ImageProxyOptions';

export function getImageProxyURL({
  domain,
  gravity,
  height,
  quality,
  resize,
  width,
}: ImageProxyOptions): string {
  const result = `${[
    domain,
    'unsafe',
    resize ? `rs:${resize ?? ''}:${width ?? ''}:${height ?? ''}` : '',
    gravity ? `gravity:${gravity ?? ''}` : '',
    quality ? `q:${quality}` : '',
    'plain',
  ]
    .filter(Boolean)
    .join('/')}`;

  if (!result.startsWith('http')) {
    return `https://${result}`;
  }

  return result;
}

import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { CustomPage } from '../CustomPage';

export const SettingsTabInfoPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const postId = parseInt(searchParams.get('postId') ?? '', 10);
  return <CustomPage postId={postId} />;
};

import { PostStatus } from 'a4bd-meta';
import React from 'react';

import { StatusDraft, StatusPostponed, StatusPublished, StatusUnpublished } from '~assets';

export const getStatusIcon = (status: PostStatus) => {
  switch (status) {
    case PostStatus.Draft:
      return <StatusDraft />;
    case PostStatus.Postponed:
      return <StatusPostponed />;
    case PostStatus.Published:
      return <StatusPublished />;
    case PostStatus.Unpublished:
      return <StatusUnpublished />;
    case PostStatus.Archived:
    default:
      return null;
  }
};

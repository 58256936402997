import { GridSecure } from 'a4bd-meta';
import { Button, Col, Row } from 'antd';
import { not, pick } from 'ramda';
import React, { ComponentProps, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GridCard, TitlePage } from '~components';
import { GridModal, MobilePageHeader, ModelList, PageHeader } from '~containers';
import { useNotification } from '~hooks';
import { changesGridsEntitySelector, gridsEntitySelector } from '~selectors';
import { useCreateGridMutation, useGetGridsListQuery, useUpdateGridsMutation } from '~services';
import { updateManyGrids } from '~slices';

import styles from './styles.module.scss';

interface RightContentProps {
  onReset?(): void;
}

const RightContent: React.FC<RightContentProps> = ({ onReset }) => {
  const changes = useSelector(changesGridsEntitySelector);

  const grids = useSelector(gridsEntitySelector.selectAll);
  const [updateGrids, { isLoading }] = useUpdateGridsMutation();

  const hasChanges = changes.length > 0;

  const notification = useNotification();

  const publishHandler = async () => {
    const result = await updateGrids({
      items: grids
        .filter(({ id }) => changes.includes(id))
        .map(
          pick<keyof GridSecure>([
            'id',
            'title',
            'alias',
            'pagination',
            'isDefault',
            'gridSchemas',
          ]),
        ),
    });

    if ('data' in result) {
      notification({
        message: 'Изменения опубликованы',
        placement: 'top',
      });
    }
  };

  return (
    <Row gutter={[25, 0]}>
      <Col>
        <Button size="large" disabled={!hasChanges} onClick={onReset}>
          Отменить
        </Button>
      </Col>
      <Col>
        <Button
          size="large"
          type="primary"
          disabled={!hasChanges}
          loading={isLoading}
          onClick={publishHandler}
        >
          Опубликовать
        </Button>
      </Col>
    </Row>
  );
};

export const GridsPage: React.FC = () => {
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>();
  const { data, isFetching, refetch } = useGetGridsListQuery({
    offset,
  });
  const dispatch = useDispatch();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [grid, setGrid] = useState<GridSecure>();
  const items = useSelector(gridsEntitySelector.selectAll);

  useGetGridsListQuery({});
  const [createGrid] = useCreateGridMutation();

  const toggleModal = () => {
    setIsShowModal(not);
  };

  const handleCreate = () => {
    setGrid(undefined);
    toggleModal();
  };

  const handleEdit = (grid: GridSecure) => {
    setGrid(grid);
    toggleModal();
  };

  const handleSortEnd: ComponentProps<typeof ModelList>['onSortEnd'] = (changes) => {
    dispatch(updateManyGrids(changes));
  };

  const handleFetch = async () => {
    setOffset(items?.length);
  };

  const handleReset = async () => {
    setOffset(0);
    refetch();
  };

  const handleCloseModal = async (grid?: Partial<GridSecure>) => {
    if (grid) {
      const { id, ...data } = grid;

      if (id) {
        dispatch(updateManyGrids([{ changes: data, id }]));
      } else {
        await createGrid(data);
      }
    }

    toggleModal();
  };

  useEffect(() => {
    refetch();
  }, [refetch, searchTerm]);

  return (
    <Row gutter={[0, 24]}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader
          title="Управление сетками"
          onCreate={handleCreate}
          placeholderText="Поиск"
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24} className={styles.pageHeader}>
        <PageHeader
          placeholderText="Поиск"
          createButtonText="Создать сетку"
          onCreate={handleCreate}
          onSearch={setSearchTerm}
        />
      </Col>
      <Col span={24}>
        <TitlePage
          title="Управление сетками"
          rightContent={<RightContent onReset={handleReset} />}
        />
      </Col>
      <Col span={24}>
        <ModelList
          onEdit={handleEdit}
          items={items}
          count={data?.count}
          isFetching={isFetching}
          card={GridCard}
          onSortEnd={handleSortEnd}
          onFetch={handleFetch}
        />
        <GridModal isOpen={isShowModal} onClose={handleCloseModal} grid={grid} />
      </Col>
    </Row>
  );
};

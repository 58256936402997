import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AuthorSecure,
  CreateAuthorBodySecure,
  GetManyAuthorsQuerySecure,
  GetManyAuthorsResponseSecure,
  UpdateAuthorBodySecure,
} from 'a4bd-meta';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export const authorsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createAuthor: builder.mutation<AuthorSecure, CreateAuthorBodySecure>({
      invalidatesTags: [{ id: 'List', type: 'Authors' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Authors),
      }),
    }),
    deleteAuthor: builder.mutation<void, number>({
      invalidatesTags: [{ id: 'List', type: 'Authors' }],
      query: (id) => ({
        method: 'DELETE',
        url: createUrl(ApiPath.Authors, id),
      }),
    }),
    getAuthorById: builder.query<AuthorSecure, number>({
      query: (id) => createUrl(ApiPath.Authors, id),
    }),
    getAuthorsList: builder.query<GetManyAuthorsResponseSecure, Partial<GetManyAuthorsQuerySecure>>(
      {
        providesTags: () => [{ id: 'List', type: 'Authors' }],
        query: (params = {}) => ({
          method: 'GET',
          params: {
            limit: 30,
            order: '-createdAt',
            relations: ['employee'],
            ...params,
          },
          url: createUrl(ApiPath.Authors),
        }),
      },
    ),
    updateAuthor: builder.mutation<AuthorSecure, Partial<UpdateAuthorBodySecure> & { id: number }>({
      invalidatesTags: [{ id: 'List', type: 'Authors' }],
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Authors, id),
      }),
    }),
  }),
  reducerPath: 'authorsApi',
  tagTypes: ['Authors'],
});

export const {
  useCreateAuthorMutation,
  useDeleteAuthorMutation,
  useGetAuthorByIdQuery,
  useGetAuthorsListQuery,
  useUpdateAuthorMutation,
} = authorsApi;

import { Block, BlockType, FileSecure, PostType } from 'a4bd-meta';
import { ContentBlock } from 'draft-js';
import { __, includes, not, pipe } from 'ramda';

export const canBeFullscreen = pipe(includes(__, [PostType.Article, PostType.Card]), not);

export const isBlock =
  <T extends BlockType>(blockType: T) =>
  (block: Block<BlockType, string>): block is Block<T, string> =>
    block.type === blockType;

export const getFileById = (fileId: number, files: FileSecure[] = []): FileSecure | null =>
  files.find(({ id }) => fileId === id) ?? null;

export const blockIsListItem = (block: ContentBlock) =>
  block instanceof ContentBlock &&
  ['unordered-list-item', 'ordered-list-item'].includes(block.getType());

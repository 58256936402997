import { PostType } from 'a4bd-meta';
import { Col, Dropdown, Input, MenuProps, Row } from 'antd';
import cn from 'classnames';
import { isNil } from 'ramda';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { CardType, ListType, Plus } from '~assets';
import { Button, Visible } from '~components';
import { getIsPostTypeRoute, isDefined } from '~utils';

import styles from './styles.module.scss';

interface Props {
  createButtonText?: string;
  isCardType?: boolean;
  menuDropdown?: MenuProps;
  onCreate?(isOpen: boolean): void;
  onSearch?(value: string): void;
  placeholderText?: string;
  setIsCardType?: (isCardType: boolean) => void;
  term?: string;
}

const { Search } = Input;

export const PageHeader: React.FC<Props> = (props) => {
  const {
    createButtonText = 'Добавить',
    isCardType,
    menuDropdown,
    onCreate,
    onSearch,
    placeholderText = 'Найти',
    setIsCardType,
    term,
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>();

  const onClick = useCallback(() => {
    if (isDefined(onCreate)) {
      onCreate(true);
    }
  }, [onCreate]);

  const handleChangeSearchTerm: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handlePressEnter: KeyboardEventHandler<HTMLInputElement> = useCallback(() => {
    if (isDefined(searchTerm) && isDefined(onSearch)) {
      onSearch(searchTerm);
    }
  }, [searchTerm, onSearch]);

  useEffect(() => {
    if (isNil(term)) {
      setSearchTerm(undefined);
    }
  }, [term]);

  const handleCardTypeSelect = () => {
    if (setIsCardType) setIsCardType(true);
  };
  const handleListTypeSelect = () => {
    if (setIsCardType) setIsCardType(false);
  };

  const isPostTypeRoute = getIsPostTypeRoute(window.location.pathname);

  return (
    <Row gutter={[20, 16]} justify="space-between">
      <Col xl={16}>
        <Search
          className={styles.search}
          bordered={false}
          size="large"
          placeholder={placeholderText}
          onChange={handleChangeSearchTerm}
          onPressEnter={handlePressEnter}
          onSearch={onSearch}
          value={searchTerm}
          allowClear
          enterButton={<div className={styles.enterButton}>Найти</div>}
        />
      </Col>
      <Visible isVisible={isPostTypeRoute}>
        <Col>
          <div className={styles.buttonsWrapper}>
            <Button
              onClick={handleCardTypeSelect}
              className={cn(styles.cardTypeButton, isCardType && styles.active)}
            >
              <CardType />
            </Button>
            <Button
              onClick={handleListTypeSelect}
              className={cn(styles.cardTypeButton, !isCardType && styles.active)}
            >
              <ListType />
            </Button>
          </div>
        </Col>
      </Visible>
      <Col>
        {onCreate && (
          <Dropdown
            menu={{ items: menuDropdown?.items ?? [], onClick: menuDropdown?.onClick }}
            placement="bottomRight"
            trigger={menuDropdown ? ['click'] : []}
          >
            <Button onClick={menuDropdown ? undefined : onClick} className={styles.createButton}>
              <Plus />
              {createButtonText}
            </Button>
          </Dropdown>
        )}
      </Col>
    </Row>
  );
};

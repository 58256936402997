import { SectionAlias } from './SectionAlias';

export const SectionColors: {
  [key in SectionAlias]?: { darkColor: string; lightColor: string };
} = {
  [SectionAlias.People]: { darkColor: '#3A2D51', lightColor: '#C2C7FB' },
  [SectionAlias.Trends]: { darkColor: '#C88C00', lightColor: '#FBDA4D' },
  [SectionAlias.Context]: { darkColor: '#74123B', lightColor: '#F77D4D' },
  [SectionAlias.Default]: { darkColor: '#74123B', lightColor: '#F77D4D' },
  [SectionAlias.Stories]: { darkColor: '#731838', lightColor: '#DBCDFB' },
  [SectionAlias.Things]: { darkColor: '#18AC60', lightColor: '#DFEC92' },
  [SectionAlias.Tests]: { darkColor: '#1D5F73', lightColor: '#F7FBA8' },
  [SectionAlias.Podcasts]: { darkColor: '#941F46', lightColor: '#FEAED8' },
  [SectionAlias.Quizes]: { darkColor: '#1D5F73', lightColor: '#F7FBA8' },
  // [SectionAlias.Quizes]: { darkColor: '#551E58', lightColor: '#DBCDFB' },
};

import { ImageProxyOptions } from '../types/common/ImageProxyOptions';
import { File } from '../types/db/file/File';
import { StorageProviderType } from '../types/db/file/StorageProviderType';
import { getImageProxyURL } from './getImageProxyURL';
import { isFileSupportsImageProxy } from './isFileSupportsImageProxy';

export function getFileURL<D = Date>(file: File<D>, options: ImageProxyOptions): string {
  if (isFileSupportsImageProxy(file)) {
    const url = new URL(file.url);

    const parts = url.pathname.split(':');

    return [
      getImageProxyURL(options),
      file.provider === StorageProviderType.S3 ? 's3:' : '',
      parts[1] || parts[0],
    ]
      .filter(Boolean)
      .join('/');
  }

  return file.url;
}

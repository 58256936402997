import { skipToken } from '@reduxjs/toolkit/query';
import { CreateEmployeeBodySecure, EmployeeStatus, UpdateEmployeeBodySecure } from 'a4bd-meta';
import { message } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useHasPermission, useIsMe } from '~hooks';
import { ConfigContext } from '~providers';
import { employeeByIdSelector, employeesEntitySelector } from '~selectors';
import {
  useCreateEmployeeMutation,
  useGetEmployeeByIdQuery,
  useUpdateEmployeeMutation,
} from '~services';
import { noop } from '~utils';

import { EmployeeModalProps } from './types';

export const useEmployeeModal = ({ id, onClose = noop }: EmployeeModalProps) => {
  const [createEmployee] = useCreateEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { imageProxyHost } = useContext(ConfigContext);

  const employees = useSelector(employeesEntitySelector.selectAll) ?? [];
  const getEditableUserData = () => employees.find((employee) => employee.id === id);
  const userData = getEditableUserData();

  const { name, role, status } = userData || {};

  const isBlocked = status === 'blocked';

  const hasPermission = useHasPermission();
  const isMe = useIsMe(id);

  const modalTitle = id ? 'Редактирование сотрудника' : 'Добавление сотрудника';
  const submitButtonText = 'Сохранить и выйти';
  const blockText = isBlocked ? 'Разблокировать' : 'Заблокировать';
  const action = id
    ? (data: Partial<UpdateEmployeeBodySecure>) => updateEmployee({ ...data, id })
    : (data: CreateEmployeeBodySecure) => createEmployee(data);

  useGetEmployeeByIdQuery(id ? { id } : skipToken);
  const employee = useSelector(employeeByIdSelector(id));

  const onCancel = () => onClose(undefined);
  const handleModalClose = () => setIsModalOpen(false);
  const handleModalOpen = () => setIsModalOpen(true);

  const onFinish = async (values: any) => {
    const actionParams = {
      avatarId: values?.avatar?.id,
      login: values.login,
      name: `${values.name} ${values.surname}`,
      password: values.password?.toString(),
      role: values.role,
    };

    // @ts-ignore
    const { data: newEmployee } = await action(actionParams);

    onClose(newEmployee);
  };

  const initialValues = useMemo(() => {
    if (employee) {
      console.log(employee);
      const [surname, name] = employee.name.split(' ');
      return {
        ...employee,
        name,
        surname,
      };
    }

    return {
      role: 'editor',
    };
  }, [employee]);

  const onUnlockClick = async () => {
    handleModalClose();
    await updateEmployee({ id, name, role, status: EmployeeStatus.active });
    message.success('Пользователь разблокирован');
  };

  const onLockClick = async () => {
    handleModalClose();
    await updateEmployee({ id, name, role, status: EmployeeStatus.blocked });
    message.success('Пользователь заблокирован');
  };

  const confirmData = {
    cancelText: 'Отменить',
    content: isBlocked ? (
      <div>
        Пользователь <b>{name}</b> получит доступ к панели администратора.
      </div>
    ) : (
      <div>
        Пользователь <b> {name} </b> станет неактивным.
      </div>
    ),
    okText: isBlocked ? 'Разблокировать' : 'Заблокировать',
    onCancel: handleModalClose,
    onOk: isBlocked ? onUnlockClick : onLockClick,
    open: isModalOpen,
    title: isBlocked ? 'Разблокировать пользователя?' : 'Заблокировать пользователя?',
  };

  return {
    blockText,
    confirmData,
    handleModalOpen,
    hasPermission,
    imageProxyHost,
    initialValues,
    isBlocked,
    isMe,
    modalTitle,
    onCancel,
    onFinish,
    submitButtonText,
  };
};

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '~components';
import { PostProvider } from '~providers';
import { blocksEntitySelector } from '~selectors';
import { useGetPostBlocksQuery, useGetPostByIdQuery } from '~services';

import { PostBlocks } from '../../../PostBlocks';
import { SettingsControls } from '../../../SettingsControls';
import { SettingsInfo } from '../../../SettingsInfo';
import { SettingsTabWrapper } from '../Wrapper/Wrapper';

interface Props {
  postId: number;
}

export const CustomPage: FC<Props> = (props) => {
  const navigate = useNavigate();

  const { postId } = props;
  const {
    data: post,
    isError,
    isFetching,
  } = useGetPostByIdQuery(
    {
      id: postId,
      relations: ['blocks', 'sections'],
    },
    { refetchOnMountOrArgChange: true, skip: !postId },
  );

  useGetPostBlocksQuery(
    { postId, relations: ['blocks', 'files', 'blocks.files'] },
    { refetchOnMountOrArgChange: true, skip: !postId },
  );

  const blocks = useSelector(blocksEntitySelector.selectAll);
  const goBack = () => navigate(-1);

  return (
    <SettingsTabWrapper isError={isError} isFetching={isFetching} hasContent={!!post}>
      {post ? (
        <PostProvider post={post}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack}>
                    Выйти из редактирования
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <SettingsInfo />
            </Col>
            <SettingsControls />
            <Col span={24}>
              <PostBlocks blocks={blocks} type={post.type} sections={post.sections} />
            </Col>
          </Row>
        </PostProvider>
      ) : null}
    </SettingsTabWrapper>
  );
};

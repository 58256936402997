import { BlockType } from 'a4bd-meta';
import { Col, Divider, Row, Space, Tooltip } from 'antd';
import classNames from 'classnames';
import { __, always, cond, equals, inc, includes, not, T } from 'ramda';
import { FC, useEffect, useRef, useState } from 'react';

import {
  Columns,
  CTA,
  Embed,
  Expert,
  Gallery,
  Gif,
  H2,
  H3,
  Image,
  Incut,
  LineBlock,
  Link as LinkIcon,
  List,
  MoreOnTopic,
  Plus,
  Question,
  Subtitle,
  TestQuestion,
  TestResult,
  Text,
  Title,
  Video,
} from '~assets';
import { usePost } from '~hooks';
import { useCreateBlockMutation } from '~services';

import { Button } from '../../components/Button';
import styles from './styles.module.scss';

const getBlockIcon = cond([
  [equals(BlockType.Intro), always(<Title />)],
  [equals(BlockType.H2), always(<H2 />)],
  [equals(BlockType.H3), always(<H3 />)],
  [equals(BlockType.Text), always(<Text />)],
  [equals(BlockType.Incut), always(<Incut />)],
  [equals(BlockType.Image), always(<Image />)],
  [equals(BlockType.Gif), always(<Gif />)],
  [equals(BlockType.Gallery), always(<Gallery />)],
  [equals(BlockType.Video), always(<Video />)],
  [equals(BlockType.Widget), always(<Embed />)],
  [equals(BlockType.MoreOnTopic), always(<MoreOnTopic />)],
  [equals(BlockType.List), always(<List />)],
  [equals(BlockType.Line), always(<LineBlock />)],
  [equals(BlockType.Quiz), always(<Question />)],
  [equals(BlockType.Columns), always(<Columns />)],
  [equals(BlockType.TestQuestion), always(<TestQuestion />)],
  [equals(BlockType.TestResults), always(<TestResult />)],
  [equals(BlockType.Expert), always(<Expert />)],
  [equals(BlockType.Link), always(<LinkIcon />)],
  [equals(BlockType.CallToAction), always(<CTA />)],
  [equals(BlockType.Subtitle), always(<Subtitle />)],
]);

const getBlockTitle = cond([
  [equals(BlockType.Intro), always('Вводка')],
  [equals(BlockType.H2), always('Заголовок H2')],
  [equals(BlockType.H3), always('Заголовок H3')],
  [equals(BlockType.Text), always('Текст')],
  [equals(BlockType.Incut), always('Врезка')],
  [equals(BlockType.Image), always('Картинка')],
  [equals(BlockType.Gif), always('GIF')],
  [equals(BlockType.Gallery), always('Галерея')],
  [equals(BlockType.Video), always('Видео')],
  [equals(BlockType.Widget), always('Embed')],
  [equals(BlockType.MoreOnTopic), always('Еще по теме')],
  [equals(BlockType.List), always('Список')],
  [equals(BlockType.Line), always('Разделитель')],
  [equals(BlockType.Quiz), always('Опрос')],
  [equals(BlockType.Columns), always('Колонки')],
  [equals(BlockType.TestQuestion), always('Вопрос')],
  [equals(BlockType.TestResults), always('Результат')],
  [equals(BlockType.Expert), always('Эксперт')],
  [equals(BlockType.Podcast), always('Подкаст')],
  [equals(BlockType.CallToAction), always('Блок СТА')],
  [equals(BlockType.Link), always('Ссылка')],
  [equals(BlockType.Subtitle), always('Подзаголовок')],
]);

const getBlockAddTitle = cond([
  [equals(BlockType.Text), always('текст')],
  [equals(BlockType.Card), always('карточку')],
  [equals(BlockType.TestQuestion), always('вопрос')],
  [T, always('')],
]);

const getFillType = cond<[BlockType], 'fill' | 'stroke'>([
  [
    includes(__, [BlockType.Incut, BlockType.MoreOnTopic, BlockType.TestQuestion]),
    always('stroke'),
  ],
  [T, always('fill')],
]);

interface Props {
  availableBlocks: BlockType[];
  parentId?: number;
  sortOrder: number;
}

export const AddBlock: FC<Props> = ({ availableBlocks, parentId, sortOrder }) => {
  const [createBlock, { isLoading: isCreatingBlock }] = useCreateBlockMutation();
  const { id } = usePost();

  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    setActive(not);
  };

  const handleClickAdd = (type: BlockType) => () => {
    if (parentId) {
      createBlock({
        parentId,
        sortOrder: inc(sortOrder),
        type,
      });
    } else {
      createBlock({ postId: id, sortOrder: inc(sortOrder), type });
    }
    setActive(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  if (availableBlocks.length === 0) {
    return null;
  }

  // if (availableBlocks.length === 1) {
  //   const [type] = availableBlocks;
  //
  //   return (
  //     <Row justify="center">
  //       <Col>
  //         <Button type="primary" onClick={handleClickAdd(type)} loading={isCreatingBlock}>
  //           Добавить {getBlockAddTitle(type)}
  //         </Button>
  //       </Col>
  //     </Row>
  //   );
  // }

  return (
    <div className={styles.wrapper}>
      <Divider orientation="center" className={styles.divider} />
      <div className={styles.buttonWrapper}>
        <Button
          icon={<Plus />}
          onClick={handleClick}
          loading={isCreatingBlock}
          className={classNames(styles.button, active && styles.hidden)}
        />
      </div>
      {active && (
        <div ref={ref} className={styles.list}>
          <Space>
            {availableBlocks.map((blockType) => (
              <Tooltip key={blockType} title={getBlockTitle(blockType)}>
                <Button
                  type="text"
                  icon={getBlockIcon(blockType)}
                  onClick={handleClickAdd(blockType)}
                />
              </Tooltip>
            ))}
          </Space>
        </div>
      )}
    </div>
  );
};

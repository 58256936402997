import { TagPreviewSecure } from 'a4bd-meta';
import { Button, Col, Form, Input, Row } from 'antd';
import { always, ifElse, isNil } from 'ramda';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '~components';
import { tagByIdSelector } from '~selectors';
import { useCreateTagMutation, useUpdateTagMutation } from '~services';
import { isDefined } from '~utils';

type FormValues = Pick<TagPreviewSecure, 'name'>;

interface Props {
  id?: number;
  isOpen?: boolean;

  onClose?(): void;
}

const getModalTitle = ifElse(isNil, always('Добавление тега'), always('Редактирование тега'));
const getButtonTitle = ifElse(isNil, always('Добавить'), always('Применить'));

export const TagModal: FC<Props> = ({ id, isOpen = false, onClose }) => {
  const [createTag] = useCreateTagMutation();
  const [updateTag] = useUpdateTagMutation();

  const tag = useSelector(tagByIdSelector(id));

  const onCancel = () => {
    if (isDefined(onClose)) {
      onClose();
    }
  };

  const onFinish = async (values: FormValues) => {
    if (id) {
      await updateTag({ changes: values, id });
    } else {
      await createTag(values);
    }

    if (isDefined(onClose)) {
      onClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      title={getModalTitle(id)}
      destroyOnClose
      onCancel={onCancel}
      width={470}
      footer={null}
    >
      <Form
        layout="vertical"
        name="tag"
        validateTrigger="submit"
        onFinish={onFinish}
        initialValues={tag}
      >
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Form.Item name="name">
              <Input size="large" placeholder="Введите название тега" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={[15, 15]} justify="space-between">
              <Col span={12}>
                <Button type="text" size="large" onClick={onCancel} block>
                  Отменить
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" htmlType="submit" size="large" block>
                  {getButtonTitle(id)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

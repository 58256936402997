import { ArrowLeftOutlined } from '@ant-design/icons';
import { Http } from '@status/codes';
import { Col, Form, Row, Space, Spin, Typography } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AllPosts } from '~assets';
import { Button, Error, Visible } from '~components';
import { SECOND } from '~constants';
import { PostBlocks, PostControls, PostInfo, PostSettings } from '~containers';
import { PostProvider } from '~providers';
import { blocksEntitySelector } from '~selectors';
import { useGetPostBlocksQuery, useGetPostByIdQuery } from '~services';

import styles from './styles.module.scss';

export const PostEditPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const postId = parseInt(id, 10);
  const [form] = Form.useForm();

  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(true);

  const toggleSettings = () => setSettingsOpen(!isSettingsOpen);

  const {
    data: post,
    error: errorPost,
    isLoading: isPostLoading,
  } = useGetPostByIdQuery(
    { id: postId, relations: ['blocks', 'sections', 'tags', 'authors', 'feeds'] },
    // ToDo Поддержать SSE метод /:postId/updates
    { pollingInterval: 5 * SECOND, refetchOnMountOrArgChange: true, skip: !postId },
  );
  const { isLoading: isBlocksLoading } = useGetPostBlocksQuery(
    { postId, relations: ['blocks', 'files', 'blocks.files'] },
    { refetchOnMountOrArgChange: true, skip: !postId },
  );

  const blocks = useSelector(blocksEntitySelector.selectAll);

  const isLoading = isPostLoading || isBlocksLoading;

  const goBack = () => navigate(-1);

  // ToDo Сделать скелетоны
  if (isLoading) {
    return (
      <Row justify="center" align="middle" className={styles.loading}>
        <Col>
          <Space>
            <Spin size="large" />
            <Typography.Title level={3}>Загрузка публикации</Typography.Title>
          </Space>
        </Col>
      </Row>
    );
  }

  if (errorPost || !post) {
    const { status = Http.NotFound } = (errorPost as AxiosError) || {};

    return (
      <Row gutter={[0, 20]} className={styles.error}>
        <Col span={24}>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack}>
            Выйти из редактирования
          </Button>
        </Col>
        <Col span={24}>
          <Error status={status} />
        </Col>
      </Row>
    );
  }

  const { createdAt, sections, status, type, updatedAt } = post;

  const goToHistory = () => {
    // navigate(generatePath(RoutesPath.Changes));
  };

  return (
    <PostProvider post={post}>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack}>
                Выйти из редактирования
              </Button>
            </Col>
            <Visible isVisible={false}>
              <Col>
                <Button onClick={goToHistory} type="text" className={styles.journalButton}>
                  <AllPosts />
                  Журнал изменений
                </Button>
              </Col>
            </Visible>
          </Row>
        </Col>

        <Col span={24}>
          <PostInfo
            type={type}
            status={status}
            createdAt={createdAt}
            updatedAt={updatedAt}
            edit={id !== 'new'}
            isSettingsOpen={isSettingsOpen}
            toggleSettings={toggleSettings}
          />
        </Col>
        {isSettingsOpen && (
          <Col span={24}>
            <PostSettings form={form} />
          </Col>
        )}
        <PostControls form={form} />
        <Col span={24}>
          <PostBlocks blocks={blocks} type={type} sections={sections} />
        </Col>
      </Row>
    </PostProvider>
  );
};

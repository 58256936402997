import { always, cond, equals, T } from 'ramda';
import React, { ComponentType, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';

import { InfoPagesTypes } from '~constants';

import { SettingsTabInfoPage, SettingsTabInfoPages, SettingsTabSocials } from './Tabs';

const getTabComponent = cond<[InfoPagesTypes | string], ComponentType>([
  [equals(InfoPagesTypes.InfoPage), always(SettingsTabInfoPage)],
  [equals(InfoPagesTypes.InfoPages), always(SettingsTabInfoPages)],
  [equals(InfoPagesTypes.Socials), always(SettingsTabSocials)],
  [T, always(Fragment)],
]);

export const SettingsTabs: React.FC = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') || InfoPagesTypes.InfoPages;
  const TabComponent = getTabComponent(type);
  return <TabComponent />;
};

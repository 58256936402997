import { File, FileImageVariantType, FileType } from '../types';
import { ImageProxyOptions } from '../types/common/ImageProxyOptions';
import { Block } from '../types/db/block/Block';
import { BlockType } from '../types/db/block/BlockType';
import { getImageURL } from './getImageURL';

export function getFileCompiledImageURL<T extends BlockType, D extends Date = Date>(
  block: Block<T, D>,
  fileId: number,
  type: FileImageVariantType,
  options: ImageProxyOptions,
): string {
  const file = block.files?.find((item) => item.id === fileId);

  if (!file) {
    return '';
  }

  return getImageURL(file as File<D, FileType.Image>, type, options);
}

import { SectionPreviewSecure } from 'a4bd-meta';
import { Form } from 'antd';
import { generatePath, useNavigate } from 'react-router-dom';

import { RoutesPath } from '~constants';
import { useCreateSectionMutation } from '~services';

export const useCreatePage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [createSection] = useCreateSectionMutation();

  const goBack = () => navigate(-1);

  const handleCommit = () => {
    form.submit();
  };

  const handleFinish = async (values: Partial<SectionPreviewSecure>) => {
    const section = await createSection(values);
    if ('data' in section) {
      navigate(generatePath(RoutesPath.SectionEdit, { id: section.data.id }));
    }
    return null;
  };

  return {
    goBack,
    handleCommit,
    handleFinish,
  };
};

import { Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import classNames from 'classnames';
import React, { FC, Fragment, PropsWithChildren } from 'react';

import { FakeInput } from '~components';

import styles from './styles.module.scss';

const SocialHeader: FC<PropsWithChildren> = ({ children }) => (
  <Typography.Title level={5}>{children}</Typography.Title>
);

export const Social = () => (
  <Row gutter={[24, 24]}>
    <Form.List name="socialNetworkLinks">
      {(fields) =>
        fields.map((item, index) => (
          <Fragment key={index}>
            <Col span={12}>
              <Row gutter={[0, 12]} className={classNames(styles.wrapper)}>
                <Col span={24}>
                  <Form.Item name={[index, 'title']}>
                    <FakeInput component={SocialHeader} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name={[index, 'url']}>
                    <Input placeholder="Вставить ссылку" />
                  </Form.Item>
                </Col>
                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item name={[index, 'showInHeader']} valuePropName="checked" noStyle>
                    <Checkbox>Видимость в бургер меню</Checkbox>
                  </Form.Item>
                </Col>
                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item name={[index, 'showInFooter']} valuePropName="checked" noStyle>
                    <Checkbox>Видимость в футере</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Fragment>
        ))
      }
    </Form.List>
  </Row>
);

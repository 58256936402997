import { useEffect, useRef, useState } from 'react';

import { calcValue, calcWidth, formatAudioTime, stopAllAudio } from '~utils';

export const usePodcastAudio = (src: string) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [isPlaying, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progressInterval, setProgressInterval] = useState<NodeJS.Timeout>();

  const [isToggledPlay, setToggledPlay] = useState(false);

  const formattedCurrentTime = formatAudioTime(currentTime);
  const formattedDuration = formatAudioTime(duration);
  const progress = `${(currentTime * 100) / duration}%`;

  useEffect(() => {
    const audio = new Audio(src);

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      audio.autoplay = true;
    }
    audio.onloadeddata = () => {
      setDuration(audio.duration);
    };
  }, [src]);

  useEffect(() => {
    if (audioRef?.current?.paused) {
      setPlaying(false);
    }
  }, [audioRef?.current?.paused]);

  const onPlay = () => {
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current.pause();
      if (progressInterval) clearInterval(progressInterval);
    } else {
      stopAllAudio();
      audioRef.current.play();
      setProgressInterval(
        setInterval(() => {
          if (!audioRef.current) return;
          setCurrentTime(audioRef.current.currentTime);
        }, 500),
      );
    }
    setToggledPlay(!isToggledPlay);
    setPlaying(!isPlaying);
  };

  const onProgressClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!audioRef.current) return;

    const eventTarget = event.target as HTMLElement;

    const currentWidth = calcWidth(
      event.clientX,
      eventTarget.getBoundingClientRect().left,
      event.currentTarget.offsetWidth,
    );

    const value = calcValue(currentWidth, event.currentTarget.offsetWidth, audioRef);

    audioRef.current.currentTime = value;
    setCurrentTime(value);
  };

  const onMouseMove = (event: React.TouchEvent<HTMLElement>) => {
    if (!audioRef.current) return;

    const seekSlider = document.getElementById('seek-slider');
    const seekSliderWidth = seekSlider?.getBoundingClientRect().width || 0;
    const seekSliderLeft = seekSlider?.getBoundingClientRect().left || 0;

    const eventLeft = event.touches[0].clientX;

    const left = eventLeft - seekSliderLeft;
    let delta = calcValue(left, seekSliderWidth, audioRef);

    if (delta < 0) delta = 0;
    if (delta > audioRef.current.duration) delta = audioRef.current.duration;

    audioRef.current.currentTime = delta;
    setCurrentTime(delta);
  };

  const onMinusTenSeconds = () => {
    if (!audioRef.current) return;

    const sum = currentTime - 10;
    const result = sum > 0 ? sum : 0;
    audioRef.current.currentTime = result;
    setCurrentTime(result);
  };

  const onPlusTenSeconds = () => {
    if (!audioRef.current) return;

    const sum = currentTime + 10;
    const result = sum < duration ? sum : duration;
    audioRef.current.currentTime = result;
    setCurrentTime(result);
  };

  return {
    audioRef,
    currentTime,
    duration,
    formattedCurrentTime,
    formattedDuration,
    isPlaying,
    onMinusTenSeconds,
    onMouseMove,
    onPlay,
    onPlusTenSeconds,
    onProgressClick,
    progress,
  };
};

export enum BlockType {
  CallToAction = 'callToAction',
  /** @deprecated */
  Card = 'card',
  /** @deprecated */
  Columns = 'columns',
  Cover = 'cover',
  Expert = 'expert',
  Gallery = 'gallery',
  /** @deprecated */
  Gif = 'gif',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  Image = 'image',
  Incut = 'incut',
  /** @deprecated */
  Intro = 'intro',
  Lead = 'lead',
  /** @deprecated */
  Line = 'line',
  Link = 'link',
  List = 'list',
  /** @deprecated */
  MoreOnTopic = 'moreOnTopic',
  /** @deprecated */
  NewsSource = 'newsSource',
  Podcast = 'podcast',
  Preview = 'preview',
  /** @deprecated */
  Quiz = 'quiz',
  // Quote = 'quote',
  /** @deprecated */
  SeoTitle = 'seoTitle',
  Subtitle = 'subtitle',
  /** @deprecated */
  TestQuestion = 'testQuestion',
  /** @deprecated */
  TestResults = 'testResults',
  Text = 'text',
  Video = 'video',
  Widget = 'widget',
}

/* eslint-disable react/no-unstable-nested-components */
import * as Sentry from '@sentry/react';
import { Button, Result, Typography } from 'antd';
import React, { PropsWithChildren } from 'react';

import { RoutesPath } from '~constants';

export const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={({ componentStack, error }) => (
      <Result
        status="error"
        title="Что-то сломалось :("
        subTitle="Мы получили уведомление об это и в скором времени это исправим"
        extra={[
          <Button type="primary" href={RoutesPath.Posts}>
            К публикациям
          </Button>,
        ]}
      >
        <Typography.Paragraph>
          <Typography.Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            {error.message}
          </Typography.Text>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <pre>{componentStack}</pre>
        </Typography.Paragraph>
      </Result>
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
);

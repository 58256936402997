import { combineReducers } from '@reduxjs/toolkit';

import {
  authorsApi,
  blocksApi,
  configApi,
  employeesApi,
  feedsApi,
  filesApi,
  gridsApi,
  gridSchemasApi,
  postsApi,
  sectionsApi,
  settingsApi,
  tagsApi,
  usersApi,
} from '~services';
import {
  authorsSlice,
  blocksSlice,
  configSlice,
  employeesSlice,
  gridSchemasSlice,
  gridSlice,
  gridsSlice,
  postsSlice,
  sectionsSlice,
  tagsSlice,
  userSlice,
  validationSlice,
} from '~slices';

export default combineReducers({
  [authorsApi.reducerPath]: authorsApi.reducer,
  [authorsSlice.name]: authorsSlice.reducer,
  [blocksApi.reducerPath]: blocksApi.reducer,
  [blocksSlice.name]: blocksSlice.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [configSlice.name]: configSlice.reducer,
  [employeesApi.reducerPath]: employeesApi.reducer,
  [employeesSlice.name]: employeesSlice.reducer,
  [feedsApi.reducerPath]: feedsApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [gridSchemasApi.reducerPath]: gridSchemasApi.reducer,
  [gridSchemasSlice.name]: gridSchemasSlice.reducer,
  [gridSlice.name]: gridSlice.reducer,
  [gridsApi.reducerPath]: gridsApi.reducer,
  [gridsSlice.name]: gridsSlice.reducer,
  [postsApi.reducerPath]: postsApi.reducer,
  [postsSlice.name]: postsSlice.reducer,
  [sectionsSlice.name]: sectionsSlice.reducer,
  [sectionsApi.reducerPath]: sectionsApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [tagsSlice.name]: tagsSlice.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [userSlice.name]: userSlice.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [validationSlice.name]: validationSlice.reducer,
});

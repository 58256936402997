import { EmployeeRole } from 'a4bd-meta';
import { always, cond, equals, T } from 'ramda';

// TODO реализовать проверку роли
export const getUserRoleTitle = cond([
  [equals(EmployeeRole.chief), always('Главред')],
  [equals(EmployeeRole.editor), always('Редактор')],
  [equals(EmployeeRole.corrector), always('Корректор')],
  [equals(EmployeeRole.intern), always('Стажер')],
  [T, always('-')],
]);

export const getUserRoleTitleForValidation = cond([
  [equals(EmployeeRole.chief), always('Главред')],
  [T, always('-')],
]);

import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  CreateGridBodySecure,
  GetManyGridsResponseSecure,
  GridSchema,
  GridSecure,
  UpdateGridBodySecure,
} from 'a4bd-meta';

import { ApiPath } from '~constants';
import { createUrl } from '~utils';

// eslint-disable-next-line import/no-cycle
import { authGuardQuery } from './defaultApi';

export const gridsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createGrid: builder.mutation<GridSecure, Partial<CreateGridBodySecure>>({
      invalidatesTags: [{ id: 'List', type: 'Grids' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Grids),
      }),
    }),
    getGrid: builder.query<GridSecure, number>({
      query: (id) => ({
        method: 'GET',
        url: createUrl(ApiPath.Grids, id),
      }),
    }),
    getGridsList: builder.query<GetManyGridsResponseSecure, PaginationParams & SortingParams>({
      providesTags: () => [{ id: 'List', type: 'Grids' }],
      query: (params = {}) => ({
        method: 'GET',
        params,
        url: createUrl(ApiPath.Grids),
      }),
    }),
    updateGrid: builder.mutation<GridSecure, { changes: UpdateGridBodySecure; id: number }>({
      invalidatesTags: [{ id: 'List', type: 'Grids' }],
      query: (data) => ({
        data: data.changes,
        method: 'PATCH',
        url: createUrl(ApiPath.Grids, data.id),
      }),
    }),
    updateGrids: builder.mutation<
      GridSecure,
      { items: (Pick<GridSchema, 'id'> & UpdateGridBodySecure)[] }
    >({
      invalidatesTags: [{ id: 'List', type: 'Grids' }],
      query: (data) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Grids),
      }),
    }),
  }),
  reducerPath: 'gridsApi',
  tagTypes: ['Grids'],
});

export const {
  useCreateGridMutation,
  useGetGridQuery,
  useGetGridsListQuery,
  useUpdateGridMutation,
  useUpdateGridsMutation,
} = gridsApi;

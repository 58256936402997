import { ImageProxyOptions } from '../types/common/ImageProxyOptions';
import { Block } from '../types/db/block/Block';
import { BlockType } from '../types/db/block/BlockType';
import { getFileURL } from './getFileURL';

export function getFileCompiledURL<T extends BlockType, D = Date>(
  block: Block<T, D>,
  fileId: number,
  options: ImageProxyOptions,
): string {
  const file = block.files?.find((item) => item.id === fileId);

  if (!file) {
    return '';
  }

  return getFileURL(file, options);
}

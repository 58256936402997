import { EmployeeRole } from 'a4bd-meta';
import { useContext } from 'react';

import { UserContext } from '~providers';
import { isDefined } from '~utils';

export const useHasPermission = (): boolean => {
  const { user } = useContext(UserContext);

  return isDefined(user) && user.role === EmployeeRole.chief;
};

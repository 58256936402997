/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, MouseEventHandler } from 'react';

import { Pause, Play, Trash } from '~assets';

import { usePodcastAudio } from './model';
import styles from './styles.module.scss';

type AudioProps = {
  onDelete: MouseEventHandler<HTMLElement>;
  src: string;
};

export const Audio: FC<AudioProps> = ({ onDelete, src }) => {
  const {
    audioRef,
    formattedCurrentTime,
    formattedDuration,
    isPlaying,
    onMouseMove,
    onPlay,
    onProgressClick,
    progress,
  } = usePodcastAudio(src);

  return (
    <div className={styles.audioWrapper}>
      <audio ref={audioRef} src={src} preload="metadata" />

      <button type="button" className={styles.playButton} onClick={onPlay}>
        {isPlaying ? <Pause /> : <Play />}
      </button>

      <span id="current-time" className={styles.timeLeft}>
        {formattedCurrentTime}
      </span>
      <div
        id="seek-slider"
        className={styles.progressBar}
        onMouseUp={onProgressClick}
        style={
          {
            '--progress': `${progress}`,
          } as React.CSSProperties
        }
      >
        <div
          className={styles.controller}
          draggable
          onTouchStart={onMouseMove}
          onTouchMove={onMouseMove}
          style={
            {
              '--progress': `${progress}`,
            } as React.CSSProperties
          }
        />
      </div>
      <span id="duration" className={styles.timeRight}>
        {formattedDuration}
      </span>

      <button type="button" className={styles.deleteButton} onClick={onDelete}>
        <Trash />
      </button>
    </div>
  );
};

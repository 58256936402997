import { GridSchemaSecure } from 'a4bd-meta';
import { Col, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { updateManyCurrentGridSchemas } from '~slices';
import { deleteMarkup } from '~utils';

import { searchPostsModal } from '../../../SearchPostModal';
import { EmptyBlock, PostBlock } from '../components';
import styles from './styles.module.scss';

type Props = GridSchemaSecure & {
  onChange?(gridSchema: Partial<GridSchemaSecure>): Partial<GridSchemaSecure>;
};

export const MainTop: React.FC<Props> = (props) => {
  const { filterByPostTypes, filterBySectionId, grid, id, onChange, posts } = props;
  const [post] = posts || [];
  const { preview, title, type } = post || {};
  const dispatch = useDispatch();

  const handleChangePost = async () => {
    const newPosts = await searchPostsModal({
      maxPostCount: 1,
      modalTitle: 'Замена публикации',
      sections: [filterBySectionId, ...(grid ? [grid.id] : [])],
      types: filterByPostTypes,
    });

    const data = { posts: newPosts };
    const changes = onChange ? onChange(data) : data;
    dispatch(updateManyCurrentGridSchemas([{ changes, id }]));
  };

  return (
    <Col flex="245px">
      <Row className={styles.mainTop}>
        <Col className={styles.sliderWrapper}>
          {title && type && preview && (
            <PostBlock
              title={deleteMarkup(title?.text)}
              slideName="Топ"
              type={type}
              preview={preview.imageURLs.desktopCard}
              onChangePost={handleChangePost}
            />
          )}
          {!title && <EmptyBlock slideName="Топ" onAddPost={handleChangePost} />}
        </Col>
      </Row>
    </Col>
  );
};

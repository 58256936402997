import { LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOAuthCallbackMutation } from '~services';
import { isDefined, isSuccessResult } from '~utils';

export const OAuthCallback: FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [oAuthCallback] = useOAuthCallbackMutation();

  useEffect(() => {
    const { code } = queryString.parse(window.location.search);

    oAuthCallback({
      code: isDefined(code) ? code.toString() : '',
      redirectUri: `${window.location.origin}/auth/callback`,
    })
      .then((result: any) => {
        if (!isSuccessResult(result)) {
          setError(result.error);
        } else {
          navigate('/');
        }
      })
      .catch((e) => {
        setError(e);
      });
  }, []);

  if (error) {
    return (
      <Result status="error" title="Ошибка авторизации">
        {error}
      </Result>
    );
  }

  return <Result status="info" icon={<LoadingOutlined />} title="Идет авторизация через SSO..." />;
};

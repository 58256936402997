import { __, always, anyPass, concat, identity, join, lensIndex, pipe, unapply, view } from 'ramda';

import { RoutesPath } from '~constants';

export const createUrl = (...args: Array<string | number>) =>
  pipe(unapply(identity), view(lensIndex(0)), join('/'), concat(__, '/'))(args);

export const createQuery = (...args: Array<string | number>) => always(createUrl(...args));

export const getIsArticles = (url: string) => url.indexOf(RoutesPath.Articles) > -1;
export const getIsPosts = (url: string) => url.indexOf(RoutesPath.Posts) > -1;
export const getIsStories = (url: string) => url.indexOf(RoutesPath.Stories) > -1;
export const getIsThings = (url: string) => url.indexOf(RoutesPath.Things) > -1;
export const getIsPodcasts = (url: string) => url.indexOf(RoutesPath.Podcasts) > -1;

export const getIsPostTypeRoute = anyPass([
  getIsArticles,
  getIsPosts,
  getIsStories,
  getIsThings,
  getIsPodcasts,
]);

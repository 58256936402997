import { BlockType, ImageProxyResize } from 'a4bd-meta';
import { Col, Form, Input, Row } from 'antd';
import { always, cond, equals, prop, T } from 'ramda';
import React, { FC, useMemo } from 'react';

import { DropType } from '~constants';
import { BlockProps } from '~types';
import { getFileById, isNotNil } from '~utils';

import { DropZone } from '../../DropZone';
import { ExpertBlocks } from '../../ExpertBlocks';
import { FormBlock, FormBlockProps } from '../Wrappers';
import styles from './styles.module.scss';

export const ExpertBlock: FC<BlockProps<BlockType.Expert>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();

  const { blocks, content, files, id, type } = block;

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [],
  );

  const formatValues: FormBlockProps<BlockType.Expert>['formatValues'] = (content) => ({
    content,
    fileIds: [content.avatarFileId].filter(isNotNil),
  });

  const getBlockDropType = cond([
    [equals(BlockType.Image), always(DropType.Image)],
    [equals(BlockType.Gif), always(DropType.Gif)],
    [T, always(DropType.Image)],
  ]);

  return (
    <FormBlock
      form={form}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      initialValues={initialValues}
      bordered
      formatValues={formatValues}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>Цитата</div>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Form.Item name="background" hidden />
              <Form.Item name="textColor" hidden />
              <ExpertBlocks blocks={blocks} parentId={id} />
            </Col>
          </Row>
        </Col>

        <Row justify="center" gutter={[30, 30]} style={{ width: '100%' }}>
          <Col span={8}>
            <Form.Item
              name="avatarFileId"
              getValueFromEvent={prop('id')}
              getValueProps={() => ({
                value: getFileById(content.avatarFileId, files),
              })}
            >
              <DropZone
                blockType={block.type}
                small
                type={getBlockDropType(type)}
                imageOptions={{
                  height: 200,
                  resize: ImageProxyResize.Fill,
                  width: 200,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <div className={styles.captionWrapper}>
              <div className={styles.subtitle}>ФИО автора</div>
              <div className={styles.caption}>
                <Form.Item name="name" className={styles.captionItem}>
                  <Input bordered={false} className={styles.input} placeholder="Добавьте ФИО" />
                </Form.Item>
              </div>
            </div>

            <div className={styles.captionWrapper} style={{ marginTop: '25px' }}>
              <div className={styles.subtitle}>Подпись к автору</div>
              <div className={styles.caption}>
                <Form.Item name="description" className={styles.captionItem}>
                  <Input bordered={false} className={styles.input} placeholder="Добавьте подпись" />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
      </Row>
    </FormBlock>
  );
};

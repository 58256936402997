import { PostType } from 'a4bd-meta';
import { Row } from 'antd';
import { always, cond, equals, T } from 'ramda';
import React from 'react';

import { Revert, Trash } from '~assets';
import { Button } from '~components';

import styles from './styles.module.scss';

type EmptyBlockProps = {
  isDeletable?: boolean;
  onChangePost: () => void;
  onDeletePost?: () => void;
  preview: string;
  slideName: string;
  title: string;
  type: PostType;
};

// Todo: Получать с бэка секции
const getSection = cond([
  [equals(PostType.Article), always('Cтатья')],
  [equals(PostType.Story), always('История')],
  [equals(PostType.Thing), always('Штука')],
  [equals(PostType.Podcast), always('Подкаст')],
  [T, always('Публикация')],
]);

export const PostBlock: React.FC<EmptyBlockProps> = ({
  isDeletable,
  onChangePost,
  onDeletePost,
  preview,
  slideName,
  title,
  type,
}) => {
  return (
    <Row className={styles.postBlock}>
      <div className={styles.cover}>
        <img
          src={preview}
          alt=""
          style={{
            height: '100%',
            objectFit: 'cover',
            width: '100%',
          }}
        />
      </div>
      <div className={styles.topNodeWrapper}>
        <div className={styles.text}>{getSection(type)}</div>
        <div className={styles.text}>{slideName}</div>
      </div>

      <div className={styles.middleNodeWrapper}>
        <div className={styles.title}>{title}</div>
      </div>

      <div className={styles.bottomNodeWrapper}>
        <Button className={styles.buttonChange} onClick={onChangePost}>
          <Revert />
          <div className={styles.buttonText}>Заменить</div>
        </Button>
        {isDeletable && (
          <Button icon={<Trash />} className={styles.buttonDelete} onClick={onDeletePost} />
        )}
      </div>
    </Row>
  );
};

import { Col, Row, Space, Typography } from 'antd';
import React from 'react';

import { usePost } from '~hooks';
import { formatDate, isNotMain } from '~utils';

import styles from './styles.module.scss';

export const SettingsInfo: React.FC = () => {
  const post = usePost();
  const { createdAt, sections, updatedAt } = post;
  console.log('SettingsInfo', { post });
  const [section] = sections.filter(isNotMain);

  return (
    <Col>
      <Col className={styles.wrapper}>
        <Row gutter={[20, 0]}>
          <Col className={styles.wrapper}>
            <Row gutter={[20, 0]}>
              <Col>
                <Typography.Text className={styles.title}>
                  {`Редактирование инфостраницы "${section.title}"`}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className={styles.bottomBar}>
        <Row gutter={[5, 5]} align="middle" justify="start" className={styles.textsWrapper}>
          <Col>
            <Row justify={{ md: 'end', xs: 'start' }}>
              <Space size="small">
                <Typography.Text className={styles.label}>Создан:</Typography.Text>
                <Typography.Text className={styles.date}>
                  {formatDate(createdAt, 'dd MMMM yyyy, HH:mm')}
                </Typography.Text>
              </Space>
            </Row>
          </Col>
          <Col md={1}>
            <Row justify="center">
              <div className={styles.line} />
            </Row>
          </Col>
          <Col>
            <Space size="small">
              <Typography.Text className={styles.label}>Изменен:</Typography.Text>
              <Typography.Text className={styles.date}>
                {formatDate(updatedAt, 'dd MMMM yyyy, HH:mm')}
              </Typography.Text>
            </Space>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

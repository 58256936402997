import { UniqueIdentifier } from '@dnd-kit/core';
import { GridSchemaSecure } from 'a4bd-meta';
import { Button, Card, Col, Input, Row, Space, Switch, Typography } from 'antd';
import classNames from 'classnames';
import { dec, inc, multiply, pipe } from 'ramda';
import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { Arrow, Change } from '~assets';
import { GridContext } from '~providers';
import { updateManyCurrentGridSchemas } from '~slices';
import { isDefined } from '~utils';

import { searchPostsModal } from '../../../SearchPostModal';
import styles from './styles.module.scss';

type Props = GridSchemaSecure & {
  canChangeTitle?: boolean;
  canSortDown?: boolean;
  canSortUp?: boolean;
  onChange?(gridSchema: Partial<GridSchemaSecure>): Partial<GridSchemaSecure>;
  onChangeOrder?(event: { active: { id: UniqueIdentifier }; over: { id: UniqueIdentifier } }): void;
  title: string;
  type?: 'news' | 'blackScience' | 'naSporte';
};

const getWidthInput = pipe(inc, multiply(14));

export const BlockWrapper: FC<PropsWithChildren<Props>> = ({
  canChangeOrder = false,
  canChangePosts = false,
  canChangeTitle = false,
  canHide = false,
  canSortDown = true,
  canSortUp = true,
  children,
  filterByPostTypes,
  filterBySectionId,
  id,
  isHidden = false,
  maxPostCount = 1,
  onChange,
  onChangeOrder,
  sortOrder,
  title: defaultTitle,
  type,
}) => {
  const dispatch = useDispatch();
  const [switched, setSwitched] = useState(!isHidden);
  const [title, setTitle] = useState(defaultTitle);
  const ref = useRef<HTMLDivElement>(null);
  const { grid } = useContext(GridContext);

  const toggleSwitch = (checked: boolean) => {
    setSwitched(checked);
    dispatch(updateManyCurrentGridSchemas([{ changes: { isHidden: !checked }, id }]));
  };

  const handleChangeTitle: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.currentTarget;

    setTitle(value);
    const data = { title: value };
    const changes = onChange ? onChange(data) : data;

    dispatch(updateManyCurrentGridSchemas([{ changes, id }]));
  };

  const handleChangePost = async () => {
    const newPosts = await searchPostsModal({
      maxPostCount,
      sections: [filterBySectionId, ...(grid ? [grid.id] : [])],
      types: filterByPostTypes,
    });

    const data = { posts: newPosts };
    const changes = onChange ? onChange(data) : data;

    dispatch(updateManyCurrentGridSchemas([{ changes, id }]));
  };

  const handleChangeSort: MouseEventHandler<HTMLButtonElement> = (event) => {
    const { id } = event.currentTarget;

    const over = { id: id === 'up' ? dec(sortOrder) : inc(sortOrder) };
    const active = { id: sortOrder };

    if (isDefined(onChangeOrder)) {
      onChangeOrder({ active, over });
    }
    ref?.current?.scrollIntoView({ behavior: 'auto', block: 'center' });
  };

  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  return (
    <Row className={classNames(styles.block, !switched && styles.hidden)} ref={ref}>
      <Col span={24}>
        <Card
          className={classNames(styles.card, type && styles[type], canHide && styles.canHidden)}
          bodyStyle={{ padding: '0' }}
        >
          <Row>
            <Col span={24} className={styles.header}>
              <Row justify="space-between" align="middle" gutter={[20, 20]}>
                <Col span={24} className={styles.title}>
                  {canChangeTitle ? (
                    <Input
                      value={title}
                      className={styles.inputTitle}
                      bordered={false}
                      placeholder="Заголовок рубрики"
                      onChange={handleChangeTitle}
                      style={{ minWidth: '60px', width: `${getWidthInput(title?.length || 20)}px` }}
                    />
                  ) : (
                    <Typography.Text className={styles.title}>{title}</Typography.Text>
                  )}
                </Col>
                {canChangeOrder && (
                  <Col className={styles.sort}>
                    <Row gutter={[5, 0]}>
                      <Col>
                        <Button
                          className={classNames(styles.buttonOrder, styles.buttonUp)}
                          onClick={handleChangeSort}
                          icon={<Arrow />}
                          id="up"
                          disabled={!canSortUp}
                        />
                      </Col>
                      <Col>
                        <Button
                          className={styles.buttonOrder}
                          icon={<Arrow />}
                          onClick={handleChangeSort}
                          id="down"
                          disabled={!canSortDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
                {(canChangePosts || canHide) && (
                  <Col className={styles.actions}>
                    <Space>
                      {canChangePosts && (
                        <Button
                          icon={<Change />}
                          className={styles.replace}
                          type="text"
                          onClick={handleChangePost}
                        >
                          Заменить
                        </Button>
                      )}
                      {canHide && (
                        <Switch
                          className={classNames(styles.switch, switched && styles.checked)}
                          checked={switched}
                          onChange={toggleSwitch}
                        />
                      )}
                    </Space>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>{children}</Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

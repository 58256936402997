import { BlockType } from 'a4bd-meta';
import { Col, Form, Input, Row } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { BlockProps } from '~types';
import { getText } from '~utils';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

export const LinkBlock: FC<BlockProps<BlockType.Link>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();

  const { content } = block;
  const { url } = content;

  const [count, setCount] = useState(getText(url).length);

  const onValuesChange = useCallback(({ url: changedUrl }: { url: string }) => {
    setCount(getText(changedUrl).length);
  }, []);

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [],
  );

  return (
    <FormBlock
      form={form}
      count={count}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      maxCount={100}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <div className={styles.title}>Ссылка</div>
        </Col>
        <Col span={24}>
          <Form.Item name="url" className={styles.inputRow}>
            <TextArea
              autoSize
              rows={1}
              className={styles.input}
              bordered={false}
              placeholder="Вставьте ссылку"
            />
          </Form.Item>
        </Col>
      </Row>
    </FormBlock>
  );
};

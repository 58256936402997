export enum Color {
  Black = 'black',
  Blue = 'blue',
  Cherry = 'cherry',
  DarkBlue = 'dark-blue',
  DarkGray = 'gray-dark',
  DarkTangerine = 'dark-tangerine',
  Green = 'green',
  Grey = 'grey',
  Lavender = 'lavender',
  LightGray = 'gray-light',
  Lilac = 'lilac',
  Murena = 'murena',
  Mustard = 'mustard',
  Orange = 'orange',
  Orange1 = 'orange1',
  Orange2 = 'orange2',
  Pink = 'pink',
  Pink1 = 'pink1',
  Purple = 'purple',
  Red = 'red',
  Transparent = 'transparent',
  White = 'white',
  WhiteSmoke = 'white-smoke',
  Wine1 = 'wine1',
  Wine2 = 'wine2',
  Yellow = 'yellow',
  YellowGreen = 'yellow-green',
  YellowLight = 'yellow-ligh',
}

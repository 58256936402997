import { SectionPreviewSecure, SectionStatus } from 'a4bd-meta';
import { Col, Collapse, Row, Switch, Typography } from 'antd';
import React, { FC } from 'react';

import { SectionForm } from '../SectionForm';
import styles from './styles.module.scss';

type SubsectionFormProps = {
  onChange: (values: Partial<SectionPreviewSecure>, subsectionNum: number) => void;
  onDelete?: (subsectionNum: number) => void;
  section?: any;
  subsectionNum: number;
};

const Header = ({
  canChangeStatus,
  status,
  title,
  toggleStatus,
}: {
  canChangeStatus: boolean;
  status: SectionStatus;
  title: string;
  toggleStatus: () => void;
}) => {
  return (
    <Row gutter={15}>
      <Col>
        <Switch
          checked={status === 'published'}
          disabled={!canChangeStatus}
          onClick={toggleStatus}
        />
      </Col>
      <Col>
        <Typography.Text className={styles.title}>{title}</Typography.Text>
      </Col>
    </Row>
  );
};

export const SubsectionForm: FC<SubsectionFormProps> = ({
  onChange,
  onDelete,
  section,
  subsectionNum,
}) => {
  const { canChangeStatus, status, title } = section;

  const handleChange = (values: Partial<SectionPreviewSecure>) => {
    onChange(values, subsectionNum);
  };

  const toggleStatus = () => {
    const newStatus =
      status === SectionStatus.draft ? SectionStatus.published : SectionStatus.draft;
    handleChange({ ...section, status: newStatus });
  };

  const handleDelete = () => onDelete && onDelete(subsectionNum);

  return (
    <Collapse expandIconPosition="end" defaultActiveKey={['1']} collapsible="icon">
      <Collapse.Panel
        header={
          <Header
            title={title}
            status={status}
            toggleStatus={toggleStatus}
            canChangeStatus={canChangeStatus}
          />
        }
        key="1"
      >
        <SectionForm
          section={section}
          isSubsection
          onChange={handleChange}
          onDelete={handleDelete}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

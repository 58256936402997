import { ArrowLeftOutlined } from '@ant-design/icons';
import { Http } from '@status/codes';
import { Col, Row, Space, Spin, Typography } from 'antd';
import { AxiosError } from 'axios';
import React from 'react';

import { Button, Error, TitlePage } from '~components';
import { AddSubsection, SectionForm, SubsectionForm } from '~containers';
import { formatDate } from '~utils';

import styles from './styles.module.scss';
import { useEditPage } from './useEditPage';

const displayedFormatDate = 'dd MMMM yyyy, HH:mm';

export const SectionEditPage = () => {
  const {
    errorPost,
    form,
    goBack,
    handleCommit,
    handleDelete,
    handleFinish,
    handleSubsectionAdd,
    handleSubsectionChange,

    handleSubsectionDelete,

    isLoading,
    isSubsectionsEmpty,
    localSubsections,
    section,
  } = useEditPage();

  // ToDo Сделать скелетоны
  if (isLoading) {
    return (
      <Row justify="center" align="middle" className={styles.loading}>
        <Col>
          <Space>
            <Spin size="large" />
            <Typography.Title level={3}>Загрузка секции</Typography.Title>
          </Space>
        </Col>
      </Row>
    );
  }

  if (errorPost || !section) {
    const { status = Http.NotFound } = (errorPost as AxiosError) || {};

    return (
      <Row gutter={[0, 20]} className={styles.error}>
        <Col span={24}>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack}>
            Выйти из редактирования
          </Button>
        </Col>
        <Col span={24}>
          <Error status={status} />
        </Col>
      </Row>
    );
  }

  const { createdAt, updatedAt } = section;

  return (
    <>
      <Row gutter={[30, 10]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack}>
                Выйти из редактирования
              </Button>
            </Col>
            <Row gutter={[15, 0]}>
              <Col>
                <Button size="large" onClick={handleDelete}>
                  Удалить
                </Button>
              </Col>
              <Col>
                <Button size="large" type="primary" onClick={handleCommit}>
                  Сохранить
                </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>

      <Row gutter={[0, 15]}>
        <Col span={24}>
          <TitlePage title="Редактирование раздела" />
        </Col>

        <Col span={24}>
          <Row>
            <Col span={12}>
              <Row>
                <Col>
                  <Typography.Text className={styles.date}>Создан:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.date}>
                    {formatDate(createdAt, displayedFormatDate)}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col>
                  <Typography.Text className={styles.date}>Изменен:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.date}>
                    {formatDate(updatedAt, displayedFormatDate)}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={styles.mainWrapper}>
        <Row gutter={[0, 15]}>
          <Typography.Text className={styles.title}>Раздел</Typography.Text>
        </Row>
        <SectionForm onFinish={handleFinish} section={section} form={form} />

        {isSubsectionsEmpty ? (
          <div>
            <Row gutter={[0, 15]}>
              <Typography.Text className={styles.title}>Подрубрики</Typography.Text>
            </Row>
            <AddSubsection onAdd={handleSubsectionAdd} />
          </div>
        ) : (
          <div className={styles.mainWrapper}>
            <div>
              <Row gutter={[0, 15]}>
                <Typography.Text className={styles.title}>Подрубрики</Typography.Text>
              </Row>
            </div>
            <div>
              {localSubsections.map((subsection) => {
                return (
                  <SubsectionForm
                    key={subsection.id}
                    onDelete={handleSubsectionDelete}
                    section={subsection}
                    onChange={handleSubsectionChange}
                    subsectionNum={subsection.id}
                  />
                );
              })}
            </div>
            <div>
              <AddSubsection onAdd={handleSubsectionAdd} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

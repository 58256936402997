import { Button, Space } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import styles from './styles.module.scss';

type TabsItem = {
  name: string;
  value: string;
};

interface Props {
  defaultValue?: string;
  items: TabsItem[];
  onSelect: (value: string) => void;
}

export const Tabs: React.FC<Props> = (props) => {
  const { defaultValue, items, onSelect } = props;
  const [selected, setSelected] = useState(defaultValue || '');
  const handleChangeValue = useCallback(
    (value: string) => {
      setSelected(value);
      onSelect(value);
    },
    [onSelect],
  );

  return (
    <Space size={[0, 10]} wrap>
      {items.map((item) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <Button
          size="large"
          onClick={() => handleChangeValue(item.value)}
          className={classNames(styles.button, item.value === selected && styles.selected)}
          key={item.value}
        >
          {item.name}
        </Button>
      ))}
    </Space>
  );
};

import { Button } from 'antd';
import React, { FC, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { InfoPagesTypes } from '~constants';
import { SettingsContext } from '~providers';
import { useUpdateSettingsMutation } from '~services';

import style from './styles.module.scss';

export const SettingsSave: FC = () => {
  const [searchParams] = useSearchParams();
  const { forms, isReadyToSave } = useContext(SettingsContext);
  const [updateSettings, { isLoading: isUpdatingSettings }] = useUpdateSettingsMutation();

  const saveSettings = async () => {
    if (!isReadyToSave) return;

    const type = searchParams.get('type') as InfoPagesTypes;
    switch (type) {
      case InfoPagesTypes.Socials: {
        const socialsValues = forms.socials.form.getFieldsValue();
        await updateSettings(socialsValues);
        break;
      }
      default:
        break;
    }
  };

  return isReadyToSave ? (
    <Button
      className={style.button}
      block
      type="primary"
      size="large"
      loading={isUpdatingSettings}
      onClick={saveSettings}
    >
      Сохранить
    </Button>
  ) : null;
};

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import React from 'react';

import { Button, TitlePage } from '~components';
import { SectionForm } from '~containers';

import styles from './styles.module.scss';
import { useCreatePage } from './useCreatePage';

export const SectionCreatePage = () => {
  const { goBack, handleCommit, handleFinish } = useCreatePage();

  return (
    <>
      <Row gutter={[30, 10]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack}>
                Выйти из редактирования
              </Button>
            </Col>
            <Row gutter={[15, 0]}>
              <Col>
                <Button size="large" type="primary" onClick={handleCommit}>
                  Сохранить
                </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>

      <Row gutter={[0, 15]}>
        <Col span={24}>
          <TitlePage title="Редактирование раздела" />
        </Col>
      </Row>

      <div className={styles.mainWrapper}>
        <Row gutter={[0, 15]}>
          <Typography.Text className={styles.title}>Раздел</Typography.Text>
        </Row>
        <SectionForm onFinish={handleFinish} />
      </div>
    </>
  );
};

import { createDraftSafeSelector, EntityId } from '@reduxjs/toolkit';
import type { AuthorSecure } from 'a4bd-meta';
import { prop } from 'ramda';

import { authorsAdapter } from '~adapters';
import { isDefined } from '~utils';

import { stateSelector } from './state';

export const authorsEntitySelector = authorsAdapter.getSelectors<RootState>(prop('authors'));

export const authorsSelector = createDraftSafeSelector(
  stateSelector,
  prop<AuthorSecure[]>('authors'),
);

export const countAuthorsSelector = createDraftSafeSelector(
  authorsSelector,
  prop<EntityId[]>('count'),
);

export const authorByIdSelector = (id?: number) => (state: RootState) =>
  isDefined(id) ? authorsEntitySelector.selectById(state, id) : undefined;

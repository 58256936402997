import { Block, BlockType, blockTypeChildMap } from 'a4bd-meta';
import { Col, Row } from 'antd';
import { always, ascend, cond, equals, prop, sort, T } from 'ramda';
import React, { Fragment } from 'react';

import { Visible } from '~components';

import { AddBlock } from '../AddBlock';
import { EmptyBlock } from '../Blocks/EmptyBlock';
import { H3Block } from '../Blocks/H3Block';
import { ListBlock } from '../Blocks/ListBlock';
import { TextBlock } from '../Blocks/TextBlock';

interface Props {
  blocks: Block<BlockType, string>[];
  parentId: number;
}

const getBlockComponent = cond([
  [equals(BlockType.Text), always(TextBlock)],
  [equals(BlockType.H3), always(H3Block)],
  [equals(BlockType.List), always(ListBlock)],
  [T, always(EmptyBlock)],
]);

const [fixedDefaultBlocks, , availableToAddBlocks] = blockTypeChildMap.expert;

const sortBlocks = sort<Block<BlockType, string>>(ascend(prop('sortOrder')));

export const ExpertBlocks: React.FunctionComponent<Props> = React.memo(
  ({ blocks, parentId }) => (
    <Row gutter={[0, 20]}>
      {sortBlocks(blocks)?.map((block, blockIndex) => {
        const BlockComponent = getBlockComponent(block.type);

        const { id, sortOrder, type } = block;

        const blockIsFixed = fixedDefaultBlocks.find((value) => type === value);
        const isTheOnlyBlock = blocks.length === 1;

        return BlockComponent ? (
          <Fragment key={id}>
            <Col span={24}>
              <BlockComponent
                block={block}
                blockIndex={blockIndex}
                canDelete={!isTheOnlyBlock && !blockIsFixed}
              />
            </Col>
            <Visible isVisible={sortOrder >= -1}>
              <Col span={24}>
                <AddBlock
                  availableBlocks={availableToAddBlocks}
                  sortOrder={sortOrder}
                  parentId={parentId}
                />
              </Col>
            </Visible>
          </Fragment>
        ) : null;
      })}
    </Row>
  ),
  equals,
);

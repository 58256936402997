import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { gridsAdapter } from '~adapters';

import { stateSelector } from './state';

export const gridsEntitySelector = gridsAdapter.getSelectors<RootState>(({ grids }) => grids);

export const gridsSelector = createDraftSafeSelector(stateSelector, ({ grids }) => grids);

export const changesGridsEntitySelector = createDraftSafeSelector(
  gridsSelector,
  ({ changes }) => changes,
);

export const gridByIdSelector = (id: number) => (state: RootState) =>
  gridsEntitySelector.selectById(state, id);
